import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { baseurl } from '../../../../utils/index';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function ResendEmailModal(props) {
  const { openModal, setOpenModal } = props;
  const [email, setEmail] = useState('');
  const [sentMessage, setSentMessage] = useState(false);

  const sendEmail = async () => {
    if (
      email.length > 3 &&
      email.includes('@') &&
      email.includes('.') &&
      email.indexOf('.') !== email.length - 1
    ) {
      await axios
        .post(`${baseurl}/auth/send-email-confirmation`, {
          email: email,
        })
        .then(setSentMessage(true))
        .catch(err => err);
    } else alert('Please check the email you entered.');
  };

  return openModal ? (
    <Box>
      <ModalWrapper>
        <DeleteModal>
          <Grid container>
            <Grid container direction="row" justifyContent="flex-end">
              <CloseIcon
                className="close"
                onClick={() => setOpenModal(false)}
              />
            </Grid>
          </Grid>
          <ConfirmBlock>
            <Typography variant={'subtitle2'}>Enter your email</Typography>
            <TextField
              label="Email *"
              variant="outlined"
              type={'email'}
              fullWidth
              onChange={e => setEmail(e.target.value)}
            />

            <Grid
              container
              direction="row"
              justifyContent="center"
              paddingBottom="10px"
            >
              {!sentMessage ? (
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={() => sendEmail()}
                >
                  Resend Email
                </Button>
              ) : (
                <Button variant="outlined" endIcon={<CheckIcon />}>
                  Email Sent
                </Button>
              )}
            </Grid>
          </ConfirmBlock>
        </DeleteModal>
      </ModalWrapper>
    </Box>
  ) : null;
}

export default ResendEmailModal;

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  z-index: 100;
`;

const DeleteModal = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
  padding-bottom: 2.5px;
  background-color: white;

  // 아이패드 (모바일 버전)
  @media (min-width: 691px) and (max-width: 890px) {
    width: 60%;
    aspect-ratio: 1/0.5;
  }
  // 모니터
  @media (min-width: 891px) {
    width: 40%;
    aspect-ratio: 1/0.5;
  }

  .close {
    cursor: pointer;
    padding-right: 2px;
    padding-top: 2px;
    font-size: 30px;
    opacity: 50%;
    aspect-ratio: 1/1;
    transition: all ease 0.3s;
    color: black;
    :hover {
      opacity: 80%;
      transform: scale(1.2);
    }
  }
`;

const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 90%;
`;
