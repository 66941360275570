/** @format */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { baseurl } from "../../../utils";
import axios from "axios";
import * as Auth from "../../../components/AuthComponent";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import * as locales from "@mui/material/locale";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { getCookie } from "../../../utils/cookies";

function SignUpEndModal(props) {
  const { openModal, setOpenModal, userInfo, setIsLogged } = props;
  const [onHover, setOnHover] = useState(false);

  const sendEmail = async () => {
    await axios
      .post(`${baseurl}/auth/send-email-confirmation`, {
        email: userInfo.email,
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const closeAndSendBack = () => {
    setOpenModal(false);
    window.history.back();
  };

  const loginRightAway = async () => {
    await Auth.authenticateUser(userInfo.email, userInfo.password);
    setTimeout(function () {
      setIsLogged(!!getCookie("@usertoken"));
    }, 1000);
  };

  return openModal ? (
    <Box>
      <ModalWrapper>
        <DeleteModal>
          <Grid container>
            <Grid
              container
              xs={11}
              direction="row"
              justifyContent="flex-end"
              align-items="center"
            >
              {onHover ? (
                <Typography paddingTop="2px" color="#ff0000">
                  <FormattedMessage id="SIGNUPMODAL_WARNING"></FormattedMessage>
                </Typography>
              ) : null}
            </Grid>
            <Grid container xs={1} direction="row" justifyContent="flex-end">
              <CloseIcon
                className="close"
                onClick={() => closeAndSendBack()}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
              />
            </Grid>
          </Grid>
          <ConfirmBlock>
            <Typography color="black">
              <FormattedMessage id="SIGNUPMODAL_VERIFICATIONEMAIL_SENTENCE"></FormattedMessage>
            </Typography>
          </ConfirmBlock>
          <Grid container>
            <Grid container justifyContent="center" xs={8}>
              <Button
                variant="contained"
                justifyContent="center"
                endIcon={<CheckIcon />}
                onClick={() => loginRightAway()}
              >
                Email Confirmed
              </Button>
            </Grid>
            <Grid container justifyContent="center" xs={4}>
              <Button
                variant="outlined"
                endIcon={<SendIcon />}
                onClick={() => sendEmail()}
              >
                Resend Email
              </Button>
            </Grid>
          </Grid>
        </DeleteModal>
      </ModalWrapper>
    </Box>
  ) : null;
}

export default SignUpEndModal;

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  z-index: 100;
`;

const DeleteModal = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
  padding-bottom: 2.5px;
  background-color: white;
  .MuiButton-root {
    width: 98%;
    box-shadow: none;
    @media (max-width: 690px) {
    }
    // 아이패드 (모바일 버전)
    @media (min-width: 691px) and (max-width: 890px) {
    }
    // 모니터
    @media (min-width: 891px) {
    }
    // 스마트폰
    @media (max-width: 690px) {
    }
  }
  // 아이패드 (모바일 버전)
  @media (min-width: 691px) and (max-width: 890px) {
    width: 60%;
    aspect-ratio: 1/0.5;
  }
  // 모니터
  @media (min-width: 891px) {
    width: 40%;
    aspect-ratio: 1/0.5;
  }

  .close {
    cursor: pointer;
    padding-right: 2px;
    padding-top: 2px;
    font-size: 30px;
    opacity: 50%;
    aspect-ratio: 1/1;
    transition: all ease 0.3s;
    color: black;
    :hover {
      opacity: 80%;
      transform: scale(1.2);
    }
  }
`;

const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;
