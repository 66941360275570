/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import img2 from "../../../../assets/images/pages/img2.jpeg";

const CaseStudy2 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={
        theme.palette.mode === "light" ? colors.red[50] : colors.red[900]
      }
      data-aos={"fade-up"}
    >
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item xs={12} md={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            height={1}
          >
            <Box>
              <Typography
                variant={"h4"}
                color={"red"}
                fontWeight={700}
                gutterBottom
              >
                ARTWORKS
              </Typography>
              <Typography
                color={
                  theme.palette.mode === "light"
                    ? "text.secondary"
                    : "text.primary"
                }
              >
                <FormattedMessage id="MAINCASESTUDY2_SECTION1_SENTENCE" />
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 0 }}
              boxShadow={0}
              borderRadius={2}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={"/assets/images/logo/logo.svg"}
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(0.7)"
                        : "none",
                  }}
                />
                <Typography component={"p"}>
                  <FormattedMessage id="MAINCASESTUDY2_SECTION2_SENTENCE" />
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={"subtitle1"} sx={{ fontWeight: 700 }}>
                    <FormattedMessage id="COMMON_ARTISTNAMIKIMNAME_SENTENCE" />
                  </Typography>
                  <Typography color="text.secondary">
                    <FormattedMessage id="COMMON_JOBTITLE_WORD" />
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
              sx={{
                "& .lazy-load-image-background.lazy-load-image-loaded": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src="https://artkissf7658c62d55742edac15fb2c0601f993112016-master.s3.ap-northeast-2.amazonaws.com/img2_de1e2034e7.jpeg"
                alt="..."
                effect="blur"
                borderRadius={2}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: "cover",
                }}
              />
              <Typography
                variant="caption"
                display="block"
                textAlign={"right"}
                color={
                  theme.palette.mode === "light"
                    ? "text.secondary"
                    : "text.primary"
                }
              >
                Beyond the Red Oil on Canvas 45.5x53 2017 김나미
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  width: "40%",
                  height: "5%",
                }}
              />
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Our Services
              </Typography>
              <Typography component={"p"}>
                <FormattedMessage id="MAINCASESTUDY_SECTION2_SENTENCE" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  width: "40%",
                  height: "30%",
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                href="/artwork-search"
                sx={{
                  width: "70%",
                  height: 60,
                }}
              >
                <FormattedMessage id="COMMON_LEARNMORE_PHRASE" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy2;
