import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import Main from "../../layouts/Main";
import Container from "../../components/Container";
import { useParams } from "react-router-dom";
import { baseurl } from "../../utils";
import { loadData } from "../../components/hooks/useFetch";
import {
  Content,
  FooterNewsletter,
  Hero,
  SidebarArticles,
  SidebarCatalogArticles,
  SidebarProductArticles,
  SidebarContractsArticles,
  SidebarNewsletter,
  SimilarStories,
  Result,
} from "./components";

const ArtistArticle = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const [url, setURL] = useState(`${baseurl}/artists/${id}`);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [start, setStart] = useState(0);

  useEffect(() => {
    setURL(`${baseurl}/artists/${id}`);
    setTimeout(async () => {
      const reqdata = await loadData(url);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
  }, [id, start, url]);

  return (
    data && (
      <Main colorInvert={true}>
        <Box>
          <Hero data={data} />
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Content id={id} data={data} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box marginBottom={4}>
                  <SidebarArticles id={id} data={data} />
                  <Box height={10} />
                  <SidebarCatalogArticles id={id} data={data} />
                  <Box height={10} />
                  <SidebarProductArticles id={id} data={data} />
                  <Box height={10} />
                  <SidebarContractsArticles id={id} data={data} />
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              marginBottom: -1,
              width: 1,
            }}
          >
            <path
              fill={theme.palette.alternate.main}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Box bgcolor={"alternate.main"}>
          <Container>
            <SimilarStories />
          </Container>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              marginBottom: -1,
              width: 1,
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
      </Main>
    )
  );
};

export default ArtistArticle;
