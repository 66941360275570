import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import axios from "axios";
import { baseurl } from "../../../../utils/index";
// import { Button, StyledEngineProvider } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { StyledEngineProvider } from '@mui/material/styles'; //'@mui/styles';
import { TiPencil } from "react-icons/ti";
import { GrClose } from "react-icons/gr";
import { getCookie } from "../../../../utils/cookies";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    width: "500px",
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "4px",
    transform: "translate(-50%, -50%)",
    paddingTop: "20px",
  },
};

const EventArtworkModal = ({
  closeArtWorkModal,
  isOpenModal,
  artwork,
  removeForm,
  id,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const imageId = localStorage.getItem("@artworkImageId");

  useEffect(() => {
    imageId &&
      axios({
        method: "get",
        url: `${baseurl}/upload/files/${imageId}`,
        headers: {
          Authorization: `Bearer ${getCookie("@usertoken")}`,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setImageUrl(res.data.url);
      });
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={closeArtWorkModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Header>
        <Title>{artwork.data.title}</Title>
        <IconsWrapper>
          <PencilIcon />
          <XmarkIcon onClick={closeArtWorkModal} />
        </IconsWrapper>
      </Header>
      <ArtImage src={imageUrl} />
      <ArtInfo>
        <ArtInfoTitle>year </ArtInfoTitle>: {artwork?.data.year}
      </ArtInfo>
      <ArtInfo>
        <ArtInfoTitle> size </ArtInfoTitle>: {artwork?.data.size}
      </ArtInfo>
      <ArtInfo>
        <ArtInfoTitle> material </ArtInfoTitle>: {artwork?.data.material}
      </ArtInfo>
      <BtnWrapper>
        <StyledEngineProvider injectFirst>
          <DeleteBtn
            variant="outlined"
            onClick={() => {
              removeForm(id);
            }}
          >
            Delete
          </DeleteBtn>
        </StyledEngineProvider>
      </BtnWrapper>
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const XmarkIcon = styled(GrClose)`
  font-size: 1.5rem;
  cursor: pointer;
`;

const PencilIcon = styled(TiPencil)`
  margin-right: 10px;
  font-size: 1.3rem;
  cursor: pointer;
`;

const ArtImage = styled.img`
  width: 100%;
  height: 300px;
  margin: 1rem 0;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ArtInfo = styled.div`
  font-size: 1.3rem;
  line-height: 1.4;
`;

const ArtInfoTitle = styled.div`
  display: inline-block;
  width: 90px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: right;
`;
const DeleteBtn = styled(Button)`
  margin-top: 20px;
`;

export default EventArtworkModal;
