import { store } from "../Store";

const SET_NAV_ARTWORK_ID = "SET_NAV_ARTWORK_ID";
const SET_NAV_ARTIST_ID = "SET_NAV_ARTIST_ID";
const SET_NAV_ART_ALL_ID = "SET_NAV_ART_ALL_ID";
const DELETE_NAV_IDS = "DELETE_NAV_IDS";

export const setNavParamArtworkid = (artworkid) => {
  store.dispatch({
    type: SET_NAV_ARTWORK_ID,
    payload: {
      artworkid,
    },
  });
};

export const setNavParamArtistid = (artistid) => {
  store.dispatch({
    type: SET_NAV_ARTIST_ID,
    payload: {
      //artworkid
      artistid,
    },
  });
};

export const setNavParamArtAllid = (artistid, artworkid) => {
  store.dispatch({
    type: SET_NAV_ART_ALL_ID,
    payload: {
      artistid,
      artworkid,
    },
  });
};

export const deleteNavParam = () => {
  store.dispatch({
    type: DELETE_NAV_IDS,
  });
};
