/** @format */

import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { baseurl } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import useFetch, { loadData } from "../../../../components/hooks/useFetch";
import CircularStatic from "../../../../components/CircularProgressWithLabel";

const ArtistCard = (props) => {
  const item = props.props.owner;
  const theme = useTheme();
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);

  return (
    <Box marginY={1} width="100%">
      {!item ? (
        <Box display="flex" justifyContent={"center"}>
          <CircularStatic />
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              component={"a"}
              href={""}
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={"flex"}
                flexDirection={"column"}
              >
                <CardMedia
                  image={item.profileimg ? item.profileimg.url : ""}
                  title={item.localname}
                  sx={{
                    backgroundSize: "contain",
                    height: { xs: 100, md: 120 },
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.7)"
                        : "none",
                  }}
                />
                <Box component={CardContent} height={{ xs: "40", sm: "50" }}>
                  <Typography variant={"h6"} fontWeight={700} gutterBottom>
                    {`${item.localname} (${item.englishname})`}
                  </Typography>
                  <Typography variant={"body2"} color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={"flex-start"}>
                  <Button
                    size="large"
                    href={`/artist-details/${item.id}`}
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    <FormattedMessage id="ARTISTPORTFOLIOMAIN_LEARNMORE_PHRASE" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ArtistCard;
