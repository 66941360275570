import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FormattedMessage, useIntl } from "react-intl";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ReactMarkdown from "react-markdown";
import Result from "../Result";

const Content = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const intl = useIntl();
  const data = props.data;

  const onClickFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://v2-master.amongarts.com${window.location.pathname}`
    );
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const imageUrl = data.mainimage?.[0]?.url || '';

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant={"subtitle1"}>
          <ReactMarkdown>{data.detailinfotext}</ReactMarkdown>
        </Typography>
        <Box width={1} height={1} marginY={4}>
          {imageUrl && (
            <LazyLoadImage
              height={"200%"}
              width={"100%"}
              src={imageUrl}
              alt={data.title}
              effect="blur"
              style={{
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                objectFit: "contain",
                borderRadius: 8,
                width: "100%",
                height: "100%",
                maxHeight: 600,
              }}
            />
          )}
        </Box>
        {data.highlightedtext && (
          <Typography
            variant={"h4"}
            color={"primary"}
            align={"center"}
            fontWeight={"normal"}
          >
            <FormatQuoteIcon />
            {data.highlightedtext}
            <FormatQuoteIcon />
          </Typography>
        )}

        <Box marginY={4}>
          <Typography variant={"h5"} gutterBottom>
            <FormattedMessage id="EXHIBITIONARTICLECONTENT_ARTWORKSINEXHIBITION_PHRASE" />
          </Typography>
          <Typography>전시 작품 리스트입니다.</Typography>
        </Box>
      </Box>
      <Box marginY={4}>
        <Result preloaddata={data} />
      </Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }} paddingY={4}></Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography color={"text.secondary"}>Share:</Typography>
          <Box marginLeft={0.5}>
            <IconButton aria-label="Facebook" onClick={onClickFacebook}>
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
