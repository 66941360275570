import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Coart from "../../../../assets/images/partners/coart.svg";
import FreedaGallery from "../../../../assets/images/partners/freedaartgallery.svg";

const Story = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography fontWeight={700} variant={"h5"}>
            <FormattedMessage id="ABOUTUS_STORYTITLE_SENTENCE" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component={"p"} color={"text.secondary"} fontWeight={400}>
            <FormattedMessage id="ABOUTUS_STORYBODY_SENTENCE" />
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
            {[
              { image: Coart, title: "코아트" },
              { image: FreedaGallery, title: "프리다아트 갤러리" },
            ].map((item, i) => (
              <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(0.7)"
                        : "none",
                  }}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
