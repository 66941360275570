/** @format */

import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { baseurl } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { loadData } from "../../../../components/hooks/useFetch";
import CircularStatic from "../../../../components/CircularProgressWithLabel";

const Stories = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const intl = useIntl();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [url, setURL] = useState(`${baseurl}/galleries?available_ind=true`);

  // const onLoadArtworkData =
  useEffect(() => {
    setURL(`${baseurl}/galleries?available_ind=true`);

    setTimeout(async () => {
      const reqdata = await loadData(url);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
    // console.log("UPDATE:data", data);
  }, [start, limit]);

  return (
    data && (
      <Box>
        {!data && (
          <Box display="flex" justifyContent={"center"}>
            <CircularStatic />
          </Box>
        )}
        <Box marginBottom={4}>
          {/* <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary"}
          align={"center"}
        >
          Success stories
        </Typography> */}
          <Box
            component={Typography}
            fontWeight={700}
            variant={"h5"}
            align={"center"}
          >
            <FormattedMessage id="GALLERIES_STORY_TITLE_" />
          </Box>
        </Box>
        <Grid container spacing={4}>
          {data.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                component={"a"}
                href={""}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  borderRadius={2}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <CardMedia
                    image={item.image[0].url}
                    title={item.name}
                    sx={{
                      height: 240,
                    }}
                  />
                  <Box component={CardContent}>
                    {/* <Box maxWidth={100} marginY={2}> */}
                    {/* <Box
                      component="img"
                      height={1}
                      width={1}
                      src={item.companyLogo}
                      alt="..."
                      sx={{
                        filter: mode === "dark" ? "contrast(0)" : "none",
                      }}
                    /> */}
                    <Box
                      sx={{
                        filter: mode === "dark" ? "contrast(0)" : "none",
                      }}
                    >
                      <Typography
                        variant={"h5"}
                        fontWeight={700}
                        gutterBottom
                        color={
                          theme.palette.mode === "light"
                            ? "text.secondary"
                            : "text.primary"
                        }
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    {/* </Box> */}

                    <Typography
                      align={"left"}
                      variant={"body2"}
                      color={
                        theme.palette.mode === "light"
                          ? "text.secondary"
                          : "text.primary"
                      }
                    >
                      {item.description}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box component={CardActions} justifyContent={"flex-start"}>
                    <Button
                      size="large"
                      href={item.homepage}
                      target="_blank"
                      endIcon={
                        <svg
                          width={16}
                          height={16}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      }
                    >
                      <FormattedMessage id="COMMON_GOTOGALLERY_PHRASE" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default Stories;
