/** @format */

// @flow

import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// TODO:Add GalleryCardList component to avoid insert extra className
// TODO:Update Page.Header to additional components


const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function DisplayGenerateCaptionData(props) {
  const [data, setData] = useState(props.data);
  const [id, setId] = useState(props.data.id);

  id === !props.data.id ?? setData(props.data.id);

  useEffect(() => {
    id === !props.data.id ?? setData(props.data);
    id === !props.data.id ?? setData(props.data.id);
  }, [id, props.data]);

  const artist = data && data.artists[0];
  return (
    <Grid container spacing={2} direction="row">
      {data === undefined
        ? null
        : data.art_works.map((artwork) =>
            artwork.is_exposed ? (
              <Grid item xs={12} md={6} key={artwork.id}>
                <Paper
                  sx={{
                    p: 2,
                    margin: "auto",
                    maxWidth: 450,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
                  }}
                >
                  <Grid item key={artwork.id}>
                    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                      <Grid item xs={2} sm={4} md={4}>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                          <Img
                            alt={artwork.title}
                            src={
                              artwork && artwork.image ? artwork.image.url : ""
                            }
                          />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="div"
                            >
                              {artwork
                                ? `${artwork.title ? artwork.title : ""}`
                                : `Unknown`}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {artwork
                                ? `${artwork.material ? artwork.material : ""}`
                                : `Unknown`}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {artwork
                                ? `${artwork.size ? artwork.size : ""}`
                                : `Unknown`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              ID:{" "}
                              {artwork
                                ? `${artwork.id ? artwork.id : ""}`
                                : `Unknown`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" component="div">
                            {artist.localname}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Box
                  sx={{
                    // width: 300,
                    height: 10,
                  }}
                />
              </Grid>
            ) : null
          )}
    </Grid>
  );
}

const GenerateCaptionData = (props) => {
  const [id, setId] = useState(props.id);

  const data = props.data;

  const artist = data && data.artists[0];

  const headers = [
    {
      key: "title",
      label: "title",
    },
    {
      key: "material",
      label: "material",
    },
    {
      key: "size",
      label: "size",
    },
    {
      key: "year",
      label: "year",
    },
    {
      key: "artist",
      label: "artist",
    },
    {
      key: "image",
      label: "image",
    },
  ];

  const rows =
    data &&
    data.art_works.map((artwork) => {
      // console.log("artwork>>>", artwork);
      // const artworkimage = getBase64Image("logo.png");
      return {
        title: artwork.title,
        material: artwork.material,
        size: artwork.size,
        year: artwork.year,
        artist: artist.localname,
        image: artwork.image.url,
      };
    });

  return data === undefined ? (
    "No Data"
  ) : (
    <Box>
      <Box>
        <DisplayGenerateCaptionData key={props.key} data={data} />
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <CSVLink data={rows} headers={headers}>
          <Button variant="contained">DOWNLOAD</Button>
        </CSVLink>
      </Box>
    </Box>
  );
};

export default GenerateCaptionData;
