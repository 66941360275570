import { store } from '../../store/Store';
import axios from 'axios';
import { saveArtist, deleteArtist } from '../../store/actions/ArtistAction';

const url = `${process.env.REACT_APP_baseurl}/artists`;
const authurl = `${process.env.REACT_APP_baseurl}/auth/local`;

export const save = async artist => {
  
  var jwt;
  try {
    
    const res = await axios
      .post(authurl, {
        identifier: 'restapi',
        password: '6HuNPwrvGWbqKvA',
      })
      .then(res => {
        jwt = res.data.jwt;
      });

    //console.log(':::::: trying to get JWT :::::::', jwt);
  } catch (e) {
    //console.log(e.message);
  }
  //console.log('JWT onSaving Artist in Artist Controller ::::::::::::', jwt);

  const requestBody = JSON.stringify({
    localname: artist.localname,
    englishname: artist.englishname,
    usercountryname: artist.usercountryname,
    edulevel: artist.edulevel,
    ex_career: artist.ex_career,
    awards: artist.awards,
    email: artist.email,
    facebook: artist.facebook,
    instagram: artist.instagram,
    blog: artist.blog,
    homepage: artist.homepage,
    profileimg: artist.profileimg,
    profileimgurl: artist.profileimgurl,
    mobile: artist.mobile,
  });
  //console.log('requestBody', requestBody);

  const requestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  };

  try {
    const response = await fetch(url, requestConfig);
    const json = await response.json();
    //console.log('ArtistController json._id:::::', json._id);

    if (json.error) {
      return null;
    }

    saveArtist(artist, jwt);
    return json._id;
  } catch (e) {
    //console.log(e.message);
  }
};

export const load = async artist => {
  //console.log(
  //   ':::::::::Getting JWT onLoading Artist in Artist Controller ::::::::::::'
  // );
  // var jwt;
  // try {
  //     //console.log('::::::::: axios post :::::::::::');
  //     // const res = await axios
  //     //     .post(authurl, {
  //     //         identifier: 'restapi',
  //     //         password: '6HuNPwrvGWbqKvA',
  //     //     })
  //     //     .then((res) => {
  //     //         jwt = res.data.jwt;
  //     //     });

  //     //console.log(':::::: trying to get JWT :::::::', jwt);
  // } catch (e) {
  //     //console.log(e.message);
  // }
  // //console.log('JWT onLoad Artist in Artist Controller ::::::::::::', artist.usertoken);
  //console.log('Loaded Artist in Artist Controller ::::::::::::', artist);

  const requestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${artist.usertoken}`,
      'Content-Type': 'application/json',
    },
    // body: requestBody,
  };

  try {
    //console.log('ArtistController artist.id:::::', artist.id);
    //console.log('ArtistController url:::::', `${url}/${artist.id}`);
    const response = await fetch(`${url}/${artist.id}`, requestConfig);
    const json = await response.json();
    //console.log('ArtistController json:::::', json);

    return json;
  } catch (e) {
    //console.log(e.message);
  }
};

export const edit = async artist => {
  //console.log(
    // ':::::::::Getting JWT onSaving Artist in Artist Controller ::::::::::::'
  // );
  var jwt;
  try {
    //console.log('::::::::: axios post :::::::::::');
    const res = await axios
      .post(authurl, {
        identifier: 'restapi',
        password: '6HuNPwrvGWbqKvA',
      })
      .then(res => {
        jwt = res.data.jwt;
      });

    //console.log(':::::: trying to get JWT :::::::', jwt);
  } catch (e) {
    //console.log(e.message);
  }
  //console.log('JWT onSaving Artist in Artist Controller ::::::::::::', jwt);

  const requestBody = JSON.stringify({
    id: artist.id,
    localname: artist.localname,
    englishname: artist.englishname,
    usercountryname: artist.usercountryname,
    edulevel: artist.edulevel,
    ex_career: artist.ex_career,
    ex_group_career: artist.ex_group_career,
    awards: artist.awards,
    mobile: artist.mobile,
    profileimgurl: artist.profileimgurl,
    email: artist.email,
    facebook: artist.facebook,
    instagram: artist.instagram,
    blog: artist.blog,
    homepage: artist.homepage,
    // profileimg: artist.profileimg,
    // profileimgurl: artist.profileimgurl,
    // exhibitions: artist.exhibitions,
  });

  //console.log('ARTIST EDIT REQ Body', requestBody);
  const requestConfig = {
    method: 'PUT',
    headers: {
      // Authorization: `Bearer ${store.getState().artist.jwt}`,
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  };
  //console.log('ArtistController_Fetch!', `${url}/${artist.id}`);
  const json = await fetch(`${url}/${artist.id}`, requestConfig).then(
    (
      res //res.json()
    ) => {
      //console.log('ArtistController_fetch_res.json', json);
      return res.json();
    }
  );

  //console.log('json.error', json.error);
  if (json.error) {
    // return null;
    return false;
  }

  saveArtist(artist, store.getState().artist.jwt);
  return json._id;
};

export const dismiss = async artist => {
  deleteArtist();
};
