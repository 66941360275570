/**
 * /* eslint-disable react/no-unescaped-entities
 *
 * @format
 */

import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { baseurl } from '../../../../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadData } from '../../../../components/hooks/useFetch';

const SidebarArticles = (props) => {
  const id = props.id;
  const intl = useIntl();
  const theme = useTheme();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [textfield, setTextField] = useState("");
  const [url, setURL] = useState(
    `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}`
  );
  // console.log("SidebarArticle Props>>>>", props);
  useEffect(() => {
    setURL(
      `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}`
    );
    // console.log("UPDATE:url", url);

    setTimeout(async () => {
      const reqdata = await loadData(url);
      // console.log("useEffect > reqdata >>>", reqdata);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
  }, []);
  // console.log("SidebarArticle Data>>>>", data);

  const exId = props.id;

  return (
    data && (
      <Box component={Card} variant={"outlined"} padding={2}>
        <Typography
          variant="h6"
          data-aos={"fade-up"}
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          <FormattedMessage id="EXHIBITIONARTICLECONTENT_OTHEREXHIBITION_PHRASE" />
        </Typography>
        {/* <Grid container spacing={2}> */}
        {data.map(
          (item) =>
            item.artists.length > 0 &&
            item.artists[0].id === props.data.artists[0].id && (
              <Grid container spacing={2}>
                <Grid key={item.i} item xs={12}>
                  <Box
                    component={Card}
                    width={1}
                    height={1}
                    boxShadow={0}
                    borderRadius={0}
                    display={"flex"}
                    flexDirection={{ xs: "column", md: "row" }}
                    sx={{ backgroundImage: "none", bgcolor: "transparent" }}
                  >
                    <Box
                      sx={{
                        width: { xs: 1, md: "50%" },
                        "& .lazy-load-image-loaded": {
                          height: 1,
                          display: "flex !important",
                        },
                      }}
                    >
                      <Box
                        component={LazyLoadImage}
                        height={1}
                        width={1}
                        src={
                          item.mainimage[0] && item.mainimage[0].formats
                            ? item.mainimage[0].url
                            : ""
                        }
                        alt="..."
                        effect="blur"
                        sx={{
                          objectFit: "cover",
                          maxHeight: 120,
                          borderRadius: 2,
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.7)"
                              : "none",
                        }}
                      />
                    </Box>
                    <CardContent
                      sx={{
                        padding: 1,
                        "&:last-child": { paddingBottom: 1 },
                        "& button": { m: 1 },
                      }}
                    >
                      <Typography fontWeight={700}>{item.title}</Typography>
                      <Box marginY={1 / 4}>
                        <Typography
                          variant={"caption"}
                          color={"text.secondary"}
                          component={"i"}
                        >
                          {item.artists[0].localname} <p /> {item.period}
                        </Typography>
                      </Box>
                      {/* <Link
                    href={`/exhibition-article/${item.id}`}
                    color={"text.secondary"}
                    sx={{ textDecoration: "none" }}
                    size={"small"}
                  >
                    View More
                  </Link> */}
                      {item.id === exId ? (
                        <Button
                          disabled
                          component={"a"}
                          // href={`/exhibition-article/${item.id}`}
                          color="secondary"
                          sx={{ textDecoration: "none" }}
                          size="small"
                        >
                          Current Exhibition
                        </Button>
                      ) : (
                        <Button
                          component={"a"}
                          href={`/exhibition-article/${item.id}`}
                          color="primary"
                          sx={{ textDecoration: "none" }}
                          size="small"
                        >
                          View More
                        </Button>
                      )}
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            )
        )}
      </Box>
    )
  );
};

export default SidebarArticles;
