/**
 * /* eslint-disable react/no-unescaped-entities
 *
 * @format
 */

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { FormattedMessage, useIntl } from "react-intl";

const SidebarCatalogArticles = (props) => {
  const theme = useTheme();
  const data = props.data;

  // console.log("SidebarCatalogArticles data>>>", data);

  return (
    data && (
      <Box component={Card} variant={"outlined"} padding={2}>
        <Typography
          variant="h6"
          data-aos={"fade-up"}
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          <FormattedMessage id="ARTWORKCONTENT_CATALOG_PHRASE" />
        </Typography>
        <Grid container spacing={2}>
          {data.artbrochures.map((item, i) => (
            <Grid key={i} item xs={12}>
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={0}
                borderRadius={0}
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                sx={{ backgroundImage: "none", bgcolor: "transparent" }}
              >
                <Box
                  sx={{
                    width: { xs: 1, md: "50%" },
                    "& .lazy-load-image-loaded": {
                      height: 1,
                      display: "flex !important",
                    },
                  }}
                >
                  {data.art_works &&
                    data.art_works.find((e) => e.id === item.main_image) !==
                      undefined && (
                      <Box
                        component={LazyLoadImage}
                        height={1}
                        width={1}
                        src={
                          data.art_works.find((e) => e.id === item.main_image)
                            .image.formats.thumbnail.url
                        }
                        alt={
                          data.art_works.find((e) => e.id === item.main_image)
                            .title
                        }
                        effect="blur"
                        sx={{
                          objectFit: "cover",
                          maxHeight: 120,
                          borderRadius: 2,
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.7)"
                              : "none",
                        }}
                      />
                    )}
                </Box>
                <CardContent
                  sx={{ padding: 1, "&:last-child": { paddingBottom: 1 } }}
                >
                  <Typography fontWeight={700}>{item.main_title}</Typography>
                  {/* <Box marginY={1 / 4}>
                    <Typography
                      variant={"caption"}
                      color={"text.secondary"}
                      component={"i"}
                    >
                      {data.artworks.find((e) => e.id === item.main_image).size}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant={"caption"}
                      color={"text.secondary"}
                      component={"i"}
                    >
                      {
                        data.artworks.find((e) => e.id === item.main_image)
                          .material
                      }
                    </Typography>
                  </Box> */}
                  <Button
                    component={"a"}
                    href={item.url}
                    target="_blank"
                    color="primary"
                    sx={{ textDecoration: "none" }}
                    size="small"
                  >
                    <FormattedMessage id="COMMON_GOTOCATALOG_PHRASE" />
                  </Button>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default SidebarCatalogArticles;
