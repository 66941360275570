/** @format */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import { baseurl } from '../../../../utils';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadData } from '../../../../components/hooks/useFetch';
import CircularStatic from '../../../../components/CircularProgressWithLabel';

const Result = (props) => {
  const intl = useIntl();
  const theme = useTheme();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [textfield, setTextField] = useState("");
  const [url, setURL] = useState(
    `${baseurl}/art-works?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${searchtext}`
  );

  const preloaddata = props.preloaddata.art_works;

  // console.log('preloadData>>>>', preloaddata);

  return (
    <Box>
      {/* dynamic data */}
      {!preloaddata && (
        <Box display="flex" justifyContent={"center"}>
          <CircularStatic />
        </Box>
      )}
      <Grid container spacing={4}>
        {preloaddata &&
          preloaddata.map((item, i) => (
            // <Grid item xs={12} sm={6} md={4} key={i}>
            <Grid item xs={12} md={6} key={i}>
              <Box
                component={"a"}
                href={`/artwork-details/${item.id}`}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  boxShadow={4}
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{ backgroundImage: "none" }}
                >
                  <CardMedia
                    image={
                      item.image && item.image.formats ? item.image.url : ""
                    }
                    title={item.title}
                    // title={console.log(item)}
                    sx={{
                      height: { xs: 300, md: 360 },
                      position: "relative",
                    }}
                  >
                    <Box
                      component={"svg"}
                      viewBox="0 0 2880 480"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        color: theme.palette.background.paper,
                        transform: "scale(2)",
                        height: "auto",
                        width: 1,
                        transformOrigin: "top center",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                        fill="currentColor"
                      />
                    </Box>
                  </CardMedia>
                  <Box component={CardContent} position={"relative"}>
                    <Typography variant={"h6"} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {`${item.size} ${item.material}`}
                    </Typography>
                    <Typography color="text.secondary">{item.year}</Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box padding={2} display={"flex"} flexDirection={"column"}>
                    <Box marginBottom={2}>
                      <Divider />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography color={"text.secondary"}>
                        {item.date}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}

        {/* <Grid item container justifyContent={"center"} xs={12}>
          <Button
            fullWidth
            variant={"outlined"}
            size={"large"}
            sx={{ height: 54, maxWidth: 400, justifyContent: "space-between" }}
            endIcon={
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
            onClick={HandleClick}
          >
            <FormattedMessage id="CONTENTPAGE_LOADMORE_PHRASE" />
          </Button>
        </Grid> */}
      </Grid>
      {/* End of dynamic data */}
    </Box>
  );
};

Result.defaultProps = {
  id: "",
};

export default Result;
