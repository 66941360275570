import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const ContactCard = () => {
  const theme = useTheme();

  return (
    <Box
      component={Card}
      boxShadow={0}
      border={`1px solid ${theme.palette.divider}`}
    >
      <Box padding={{ xs: 2, sm: 3 }}>
        <Typography
          sx={{
            fontWeight: "700",
          }}
          gutterBottom
        >
          궁금한 점이 있으신가요?
        </Typography>
        <Typography
          variant={"body2"}
          color={"text.secondary"}
          sx={{
            marginBottom: 2,
          }}
        >
          개인 정보 보호 정책에 대해 질문이나 우려 사항이 있는 경우 여기로 문의하시기 바랍니다.
        </Typography>
        <Typography variant={"subtitle2"}>
          services@artnartech.com
          <br />
          용담로 97 (청학동), 3층
          <br />
          인천광역시 연수구
          <br />
          대한민국, 21926
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactCard;
