import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from '../../layouts/Main';
import { Details, Image, ArtistCard } from './components';
import Container from '../../components/Container';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../utils';
import { loadData } from '../../components/hooks/useFetch';
import { FormattedMessage, useIntl } from "react-intl";

const ArtworkDetails = () => {
  const { id } = useParams();

  const [url, setURL] = useState(`${baseurl}/art-works/${id}`);
  const [data, setData] = useState(null);
  // console.log(data);

  useEffect(() => {
    setTimeout(async () => {
      const reqdata = await loadData(url);
      setData(reqdata);
    }, 1000);
  }, []);
  return (
    data && (
      <Main>
        <Container>
          <Box>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={7}>
                <Image data={data} />
              </Grid>
              <Grid item xs={12} md={5}>
                <Details data={data} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Main>
    )
  );
};

export default ArtworkDetails;
