import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

import CircularStatic from '../../../components/CircularProgressWithLabel';
import { baseurl } from '../../../utils';
import { loadData } from '../../../components/hooks/useFetch';

import Page from '../components/Page';
import Main from '../../../layouts/Main';
import { getCookie } from '../../../utils/cookies';

const Gallery = () => {
  const theme = useTheme();
  const offset = 6;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(500);
  const [data, setData] = useState([]);
  const [userLikes, setUserLikes] = useState([]);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState('');
  const [countLikeGallery, setCountLikeGallery] = useState(6);
  const [url, setURL] = useState(
    `${baseurl}/art-works?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${searchtext}`
  );
  const token = getCookie('@usertoken');

  useEffect(() => {
    fetch(`${baseurl}/users/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setUserLikes(response.artwork_likes);
      });
  }, []);

  const gelleryLikes = data.filter((artwork) => {
    return userLikes.includes(artwork.id);
  });

  // const onLoadArtworkData =
  useEffect(() => {
    // setURL(
    //   `${baseurl}/art-works?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${searchtext}`
    // );
    // console.log("UPDATE:url", url);

    setTimeout(async () => {
      const reqdata = await loadData(url);
//console.log('useEffect > reqdata >>>', reqdata);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
  }, [start, url]);

  const HandleClick = () => {
    let startloadmore = countLikeGallery + offset;
    setCountLikeGallery(startloadmore);
  };

  return (
    <Main>
      <Page>
        <Box>
          <Typography variant='h6' fontWeight={700}>
            Like Gallery
          </Typography>
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Box>
          {/* dynamic data */}
          {!data && (
            <Box display='flex' justifyContent={'center'}>
              <CircularStatic />
            </Box>
          )}
          <Grid container spacing={4}>
            {gelleryLikes &&
              gelleryLikes.slice(0, countLikeGallery).map((item, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                  <Box
                    component={'a'}
                    href={`/artwork-details/${item.id}`}
                    display={'block'}
                    width={1}
                    height={1}
                    sx={{
                      textDecoration: 'none',
                      transition: 'all .2s ease-in-out',
                      '&:hover': {
                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                      },
                    }}
                  >
                    <Box
                      component={Card}
                      width={1}
                      height={1}
                      boxShadow={4}
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{ backgroundImage: 'none' }}
                    >
                      <CardMedia
                        image={
                          item.image && item.image.formats ? item.image.url : ''
                        }
                        title={item.title}
                        sx={{
                          height: { xs: 170, md: 220 },
                          position: 'relative',
                        }}
                      >
                        <Box
                          component={'svg'}
                          viewBox='0 0 2880 480'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            color: theme.palette.background.paper,
                            transform: 'scale(2)',
                            height: 'auto',
                            width: 1,
                            transformOrigin: 'top center',
                          }}
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z'
                            fill='currentColor'
                          />
                        </Box>
                      </CardMedia>
                      <Box component={CardContent} position={'relative'}>
                        <Typography variant={'h6'} gutterBottom>
                          {item.title}
                        </Typography>
                        <Typography color='text.secondary'>
                          {`${item.size} ${item.material}`}
                        </Typography>
                        <Typography color='text.secondary'>
                          {item.year}
                        </Typography>
                      </Box>
                      <Box flexGrow={1} />
                      <Box
                        padding={2}
                        display={'flex'}
                        flexDirection={'column'}
                      >
                        <Box marginBottom={2}>
                          <Divider />
                        </Box>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            <Avatar
                              src={
                                item.owner && item.owner.profileimg
                                  ? item.owner.profileimg.url
                                  : ''
                              }
                              sx={{ marginRight: 1 }}
                            />
                            {/* <Typography color={'text.secondary'} sx={{ textDecoration: "none" }} > */}
                            {/* <a href={`${baseurl}/account-general`}> */}
                            <Link
                              href='./'
                              color={'text.secondary'}
                              sx={{ textDecoration: 'none' }}
                            >
                              {item.owner
                                ? item.owner.localname
                                : 'Artist not registered'}
                            </Link>
                            {/* </a> */}
                            {/* </Typography> */}
                          </Box>
                          <Typography color={'text.secondary'}>
                            {item.date}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}

            <Grid item container justifyContent={'center'} xs={12}>
              <Button
                fullWidth
                variant={'outlined'}
                size={'large'}
                sx={{
                  height: 54,
                  maxWidth: 400,
                  justifyContent: 'space-between',
                }}
                endIcon={
                  <Box
                    component={'svg'}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M17 8l4 4m0 0l-4 4m4-4H3'
                    />
                  </Box>
                }
                onClick={HandleClick}
              >
                <FormattedMessage id='CONTENTPAGE_LOADMORE_PHRASE' />
              </Button>
            </Grid>
          </Grid>
          {/* End of dynamic data */}
        </Box>
      </Page>
    </Main>
  );
};

export default Gallery;
