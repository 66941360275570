import axios from "axios";
import configuration from '../config/config';
import { useState } from "react";
import { getDecryptedCookie, setEncryptedCookie } from './cookieUtils'; // 쿠키 유틸리티 함수들을 가져옴

const baseurl = configuration.baseurl;
const basehost = configuration.basehost; // basehost를 config에서 가져옴
const authurl = `${baseurl}/auth/local`;

export { baseurl, basehost, authurl };

export default async function GetToken() {
  console.info("GetToken Process begins...");
  
  let token = getDecryptedCookie("@usertoken");
//console.log("GetToken.token", token);

  const tokenValidate = async () => {
    if (token) {
      try {
        const config = {
          method: "post",
          url: `${baseurl}/users/me`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios(config);
        if (response.status >= 400) {
          // 서버에서 400 이상의 응답을 받았을 때 처리
          return false;
        }
        return response.status < 300; // 200 ~ 299 사이의 응답 확인
      } catch (error) {
        console.error("Token validation error:", error);
        return false;
      }
    } else {
      return false;
    }
  };

  if (!token) {
    const tokenVal = await tokenValidate();
    if (!tokenVal) {
      console.info("No valid token found or token is too old");

      try {
        const response = await axios.post(authurl, {
          identifier: configuration.identifier,
          password: configuration.password,
        });

        const newToken = response.data.jwt;
        setEncryptedCookie("@usertoken", newToken, 7);
//console.log("Encrypted cookie set successfully");

        return newToken;
      } catch (error) {
        console.error("Authentication error:", error);
        throw error; // 예외 처리 필요
      }
    }
    return token;
  } else {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(`${baseurl}/users/me`, config);
      if (response.status === 200) {
        return token;
      } else {
        console.error("Invalid token, re-authenticating...");
        throw new Error("Invalid token");
      }
    } catch (error) {
      console.error("Token validation error:", error);
      throw error; // 예외 처리 필요
    }
  }
}

export async function CheckSignIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const token = getDecryptedCookie("@usertoken");

  if (!token) {
//console.log("No token found, user not signed in");
    return false;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(`${baseurl}/users/me`, config);
    if(response.data.username !== configuration.identifier ){
      setIsSignedIn(true); // 서버 응답이 성공하면 로그인 상태로 설정
    } else setIsSignedIn(false); 
  } catch (error) {
    console.error("Error checking sign-in status:", error);
    setIsSignedIn(false); // 에러 발생 시 로그인 상태 아님
  }

  return isSignedIn;
}