import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from "react-intl";

const SidebarProductArticles = (props) => {
  const theme = useTheme();
  const data = props.data;

  return (
    data && (
      <Box component={Card} variant={"outlined"} padding={2}>
        <Typography
          variant="h6"
          data-aos={"fade-up"}
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          <FormattedMessage id="ARTWORKCONTENT_PRODUCTS_PHRASE" />
        </Typography>
        <Grid container spacing={2}>
          {data.shopitemregs.map((item, i) => (
            <Grid key={i} item xs={12}>
              {data.artworks.find((e) => e.id === item.art_work) && ( // Check if artwork exists
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  boxShadow={0}
                  borderRadius={0}
                  display={"flex"}
                  flexDirection={{ xs: "column", md: "row" }}
                  sx={{ backgroundImage: "none", bgcolor: "transparent" }}
                >
                  <Box
                    sx={{
                      width: { xs: 1, md: "50%" },
                      "& .lazy-load-image-loaded": {
                        height: 1,
                        display: "flex !important",
                      },
                    }}
                  >
                    <LazyLoadImage
                      component="img"
                      height={1}
                      width={1}
                      src={
                        data.artworks.find((e) => e.id === item.art_work)?.image?.formats?.thumbnail?.url // Optional chaining to safely access nested properties
                      }
                      alt={
                        data.artworks.find((e) => e.id === item.art_work)?.title // Optional chaining to safely access nested properties
                      }
                      effect="blur"
                      sx={{
                        objectFit: "cover",
                        maxHeight: 120,
                        borderRadius: 2,
                        filter:
                          theme.palette.mode === "dark"
                            ? "brightness(0.7)"
                            : "none",
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{ padding: 1, "&:last-child": { paddingBottom: 1 } }}
                  >
                    <Typography fontWeight={700}>
                      {
                        data.artworks.find((e) => e.id === item.art_work)?.title // Optional chaining to safely access nested properties
                      }
                    </Typography>
                    <Box marginY={1 / 4}>
                      <Typography
                        variant={"caption"}
                        color={"text.secondary"}
                        component={"i"}
                      >
                        {
                          data.artworks.find((e) => e.id === item.art_work)?.size // Optional chaining to safely access nested properties
                        }
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant={"caption"}
                        color={"text.secondary"}
                        component={"i"}
                      >
                        {
                          data.artworks.find((e) => e.id === item.art_work)?.material // Optional chaining to safely access nested properties
                        }
                      </Typography>
                    </Box>
                    <Button
                      component={"a"}
                      href={item.itemurl}
                      target="_blank"
                      color="primary"
                      sx={{ textDecoration: "none" }}
                      size="small"
                    >
                      <FormattedMessage id="COMMON_BUYTHISARTWORK_PHRASE" />
                    </Button>
                  </CardContent>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default SidebarProductArticles;
