/** @format */

import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import { FormattedMessage, useIntl } from "react-intl";

const Team = () => {
  const theme = useTheme();
  const intl = useIntl();
  const mock = [
    {
      name: "Soomyoung Yi",
      title: "CEO/Founder",
      avatar: "https://www.neme.life/static/media/CEO.e5ffb7583b3bc1de4af1.png",
      about: `${intl.formatMessage({
        id: "ARTISTPORTFOLIOHERO_CEOPROVIDESERVICE_PHRASE",
      })}`,
      facebook: "https://www.facebook.com/amongarts",
      git: "",
      twitter: "",
    },
    {
      name: "Sanghyo Lee",
      title: "CFO / Financial & HR",
      avatar: "https://www.neme.life/static/media/CFO.4f00decd53de51d922a9.png",
      about: `${intl.formatMessage({
        id: "ARTISTPORTFOLIOHERO_CFOPROVIDESERVICE_PHRASE",
      })}`,
      facebook: "https://www.facebook.com/amongarts",
      git: "",
      twitter: "",
    },
    {
      name: "Sean",
      title: "COO / Project Manager",
      avatar: "https://www.neme.life/static/media/COO.73678c94e4031b664cf6.png",
      about: `${intl.formatMessage({
        id: "ARTISTPORTFOLIOHERO_COOPROVIDESERVICE_PHRASE",
      })}`,
      facebook: "https://www.facebook.com/amongarts",
      git: "",
      twitter: "",
    },
    {
      name: "Minjoon Kim",
      title: "Web Developer",
      avatar: `${require("../../../../assets/images/people/mj.kim.png")}`,
      // avatar: "/assets/images/logo/mj.kim.png",
      about: `${intl.formatMessage({
        id: "ARTISTPORTFOLIOHERO_DEVELOPERPROVIDESERVICE_PHRASE",
      })}`,
      facebook: "https://www.facebook.com/amongarts",
      git: "",
      twitter: "",
    },
  ];

  // console.log("mock>>>", mock);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
          align={"center"}
        >
          Our team
        </Typography>
        <Typography fontWeight={700} variant={"h4"} align={"center"}>
          Trust the professionals
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Box
              component={Card}
              boxShadow={2}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <CardContent>
                <Box
                  component={Avatar}
                  src={item.avatar}
                  height={80}
                  width={80}
                />
                <Box marginTop={4}>
                  <ListItemText primary={item.name} secondary={item.title} />
                  <Typography variant={"subtitle2"} color={"text.secondary"}>
                    {item.about}
                  </Typography>
                  <Box marginTop={4}>
                    <IconButton size={"small"} color={"primary"}>
                      <FacebookIcon />
                    </IconButton>
                    <IconButton size={"small"} color={"primary"}>
                      <GitHubIcon />
                    </IconButton>
                    <IconButton size={"small"} color={"primary"}>
                      <TwitterIcon />
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
