/** @format */

import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as locales from "@mui/material/locale";
import TextField from "@mui/material/TextField";
import ThemeModeToggler from "../components/ThemeModeToggler";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";
import * as Auth from "../components/AuthComponent";
import { CheckSignIn } from "../utils/token";

const TopNav = ({ colorInvert = false }) => {
  const [signIn, setSignIn] = useState(false);
  // console.log("locale>>>", localStorage.getItem("locale") )
  const storedlocale = localStorage.getItem("locale") ?? "ko-KR";
  // const userToken = localStorage.getItem("@usertoken");
  const [locale, setLocale] = useState(storedlocale); //(localStorage.getItem("locale"));
  const theme = useTheme();
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  useEffect(() => {
    if (locale !== localStorage.getItem("locale")) {
      localStorage.setItem("locale", locale);
      document.location.reload();
    }
  }, [locale]);

  const HandleSignout = () => {
    // alert("HandleSignout");
    Auth.unauthenticateUser();
    if (!signIn) {
      return null;
      // alert("Failed to SignOut")
    } else {
      <Navigate to="/" replace={true} />;
    }
  };

  CheckSignIn().then(async (result) => {
    await setSignIn(result);
//console.log("result>>>", result);
//console.log("signIn>>>", signIn);
  });

  return (
    <Box sx={{ width: "100%" }}>
      <ThemeProvider theme={themeWithLocale}>
        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
          {process.env.REACT_APP_SERVICE_TYPE !== "P" && (
            <Box marginRight={{ xs: 1, sm: 2 }}>
              <Link
                underline="none"
                component="a"
                href="/demos"
                color={colorInvert ? "common.white" : "text.primary"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                Demos
                <Box
                  padding={0.5}
                  display={"inline-flex"}
                  borderRadius={1}
                  bgcolor={"primary.main"}
                  marginLeft={1}
                >
                  <Typography
                    variant={"caption"}
                    sx={{ color: "common.white", lineHeight: 1 }}
                  >
                    new
                  </Typography>
                </Box>
              </Link>
            </Box>
          )}
          {process.env.REACT_APP_SERVICE_TYPE !== "P" && (
            <Box marginRight={{ xs: 1, sm: 2 }}>
              <Link
                underline="none"
                component="a"
                href="/blocks"
                color={colorInvert ? "common.white" : "text.primary"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                Components
              </Link>
            </Box>
          )}
          {process.env.REACT_APP_SERVICE_TYPE !== "P" && (
            <Box marginRight={{ xs: 1, sm: 2 }}>
              <Link
                underline="none"
                component="a"
                href="/docs/introduction"
                color={colorInvert ? "common.white" : "text.primary"}
              >
                Docs
              </Link>
            </Box>
          )}
          <Box marginRight={{ xs: 1, sm: 2 }}>
            <Link
              underline="none"
              component="a"
              href="/signin-cover"
              color={colorInvert ? "common.white" : "text.primary"}
              onClick={HandleSignout}
            >
              {signIn ? (
                <FormattedMessage id="TOPNAV_SIGNOUT_PHRASE" />
              ) : (
                <FormattedMessage id="TOPNAV_SIGNIN_PHRASE" />
              )}
            </Link>
          </Box>
          <Box>
            <ThemeModeToggler />
          </Box>
          <Box marginLeft={{ xs: 1, sm: 2 }}>
            <Autocomplete
              options={Object.keys(locales)}
              getOptionLabel={(key) =>
                `${key.substring(0, 2)}-${key.substring(2, 4)}`
              }
              style={{ width: 200 }}
              value={locale.replace(/-/g, "")}
              disableClearable
              onChange={(event, newValue) => {
                let selectedLocale = `${newValue.slice(0, 2)}-${newValue.slice(
                  2
                )}`;
                setLocale(selectedLocale);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <FormattedMessage id="TOPNAV_SELECTLANGUAGE_SENTENCE" />
                  }
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

TopNav.propTypes = {
  colorInvert: PropTypes.bool,
};

export default TopNav;