/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import NavItem from "./components/NavItem";

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const [signIn, setSignIn] = useState(false);

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
    artwork: artworkPages,
    artist: artistPages,
    exhibition: exhibitionPages,
    gallery: galleryPages,
    admin: adminPages
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="amongArts"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={
              mode === "light"
                ? "../../../../../assets/images/logo/logo.svg"
                : "../../../../../assets/images/logo/logo-negative.svg"
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_EXHIBITIONS_WORD" />}
            items={exhibitionPages}
          />
        </Box>
        <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_ARTWORKS_WORD" />}
            items={artworkPages}
          />
        </Box>
        <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_ARTISTS_WORD" />}
            items={artistPages}
          />
        </Box>
        <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_GALLERY_WORD" />}
            items={galleryPages}
          />
        </Box>
        {/* <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_SUPPORT_WORD" />}
            items={secondaryPages}
          />
        </Box> */}
        <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_SERVICE_WORD" />}
            items={companyPages}
          />
        </Box>
        {/* <Box>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_BLOG_WORD" />}
            items={blogPages}
          />
        </Box> */}
        {signIn && (
          <Box>
            <NavItem
              title={<FormattedMessage id="TOPBAR_MAINMENU_SERVICE_WORD" />}
              items={companyPages}
            />
          </Box>
        )}
        {localStorage.getItem("@role") === "Administrator" && (
          <Box marginLeft={4}>
            <NavItem
              title={<FormattedMessage id="TOPBAR_MAINMENU_ADMIN_WORD" />}
              items={adminPages}
            />
          </Box>
        )}
        {/* <Box marginTop={2}>
          <Button
            size={"large"}
            variant="outlined"
            fullWidth
            component="a"
            href="/docs/introduction"
          >
            Documentation
          </Button>
        </Box>
        <Box marginTop={1}>
          <Button
            size={"large"}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="https://mui.com/store/items/the-front-landing-page/"
          >
            Purchase now
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
