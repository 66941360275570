import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import { loadData } from "../../components/hooks/useFetch";
import { useParams, useNavigate } from "react-router-dom";
import {
  Content,
  FooterNewsletter,
  Hero,
  SidebarArticles,
  SidebarCatalogArticles,
  SidebarNewsletter,
  SimilarStories,
} from "./components";
import { baseurl } from "../../utils";

const ExhibitionArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reqdata = await loadData(`${baseurl}/exhibitions/${id}`);
        setData(reqdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero data={data} />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Content id={id} data={data} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box marginBottom={4}>
                <SidebarArticles id={id} data={data} />
              </Box>
              <Box marginBottom={4}>
                <SidebarCatalogArticles id={id} data={data} />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>

      <Box bgcolor={"alternate.main"}>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default ExhibitionArticle;
