const DEFAULT_STATE = {
  artist: null,
  jwt: null,
};

const ARTIST_SAVE = "ARTIST_SAVE";
const ARTIST_LOAD = "ARTIST_LOAD";
const ARTIST_DELETE = "ARTIST_DELETE";

const ArtistReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ARTIST_SAVE:
      return {
        ...state,
        ...{ artist: action.payload.artist, jwt: action.payload.jwt },
      };

    case ARTIST_LOAD:
      return {
        ...state,
        ...{ artist: action.payload.artist, jwt: action.payload.jwt },
      };

    case ARTIST_DELETE: {
      const next = { ...state };
      delete next[action.id];
      return next;
    }

    default:
      return state;
  }
};

export default ArtistReducer;
