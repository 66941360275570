/** @format */

import { useState, useEffect } from "react";
import axios from "axios";
import GetToken from "../../utils/index";

const useFetch = (url) => {
  // console.log("Enter useFetch");
  const [data, setData] = useState([]);
  useEffect(() => {
    GetToken().then(() => loadData());
  }, []);
  //retrieve token

  const loadData = async (props) => {
    const token = await GetToken();
    // console.log("GetToken()'s token>>>", token);
    // .then((token)=>{

    const config = {
      method: "get",
      // eslint-disable-next-line camelcase
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    // console.log("useFetch>>>", config);
    await axios(config).then((res) => {
      setData(res.data);
    });

    // })
  };
  return data;
};

export const loadData = async (url) => {
  // console.log("Enter loadData");
  const token = await GetToken();
  // console.log("GetToken()'s token>>>", token);

  const config = {
    method: "get",
    // eslint-disable-next-line camelcase
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  // console.log("loadData>>>", config);
  const response = await axios(config);
  //  console.log("loadData response>>>", response);
  return response.data;
};

export default useFetch;
