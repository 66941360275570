/**
 * /* eslint-disable react/no-unescaped-entities
 *
 * @format
 */

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { FormattedMessage, useIntl } from "react-intl";

const SidebarContractsArticles = (props) => {
  const theme = useTheme();
  const data = props.data;

  // console.log("SidebarContractsArticles data>>>", data);

  return (
    data && (
      <Box component={Card} variant={"outlined"} padding={2}>
        <Typography
          variant="h6"
          data-aos={"fade-up"}
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          <FormattedMessage id="ARTISTDETAIL_CONTRACT_PHRASE" />
        </Typography>
        <Grid container spacing={2}>
          {data.contracts.map((item, i) => (
            <Grid key={i} item xs={12}>
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={0}
                borderRadius={0}
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                sx={{ backgroundImage: "none", bgcolor: "transparent" }}
              >
                <CardContent
                  sx={{ padding: 1, "&:last-child": { paddingBottom: 1 } }}
                >
                  <Typography fontWeight={700}>{item.Title}</Typography>
                  <Box>
                    <Typography
                      variant={"caption"}
                      color={"text.secondary"}
                      component={"i"}
                    >
                      {`${item.startdate} ~ ${item.enddate}`}
                    </Typography>
                  </Box>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default SidebarContractsArticles;
