import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import * as yup from "yup";
import FileUploader from "./FileUploader";
import { useFormik } from "formik";
import { baseurl } from "../../../utils/index";
// import { TextField, Button, StyledEngineProvider } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { StyledEngineProvider } from '@mui/material/styles'; //'@mui/styles';
import TextField from '@mui/material/TextField';
import { GrClose } from "react-icons/gr";
import { getCookie } from "../../../utils/cookies";

function ArtworkForm({
  removeForm,
  id,
  setIsAddBtnOpen,
  artworkArr,
  setArtworks,
}) {
  const [file, setFile] = useState("");
  const [sizes, setSizes] = useState({ width: 0, height: 0, depth: 0 });
  const [imgUrl, setImgUrl] = useState("");
  const [isSaveBtnOpen, setIsSaveBtnOpen] = useState(true);

  const $artistId = localStorage.getItem("@artist");
  const depth = sizes["depth"] > 0 ? " x " + sizes["depth"] + "cm" : "";
  const size =
    sizes["width"] > 0 && sizes["height"] > 0
      ? sizes["width"] + "cm x " + sizes["height"] + "cm" + depth
      : "";

  const handleSizes = (e) => {
    const { name, value } = e.target;
    setSizes({ ...sizes, [name]: value });
  };

  window.addEventListener("unload", () => {
    $artistId && localStorage.setItem("artworkData", JSON.stringify(formik));
  });

  const validationSchema = yup.object().shape({
    title: yup.string().required("*title is Required"),
    size: yup.string().required("*size is Required"),
    material: yup.string().required("*material is Required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      size: "",
      material: "",
      year: null,
      owner: "",
      exhibitions: [],
      shopitemreg: "",
      is_exposed: true,
      imageurl: "",
      row_status: 0,
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      // console.log('submit: ', formik.values);
    },
  });

  useEffect(() => {
    axios({
      method: "get",
      url: `${baseurl}/artists/${$artistId}`,
      headers: {
        Authorization: `Bearer ${getCookie("@usertoken")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      // console.log('success!!', res);
    });
  }, []);

  // 작품이미지 post handler
  const handleImageData = (e) => {
    const formData = new FormData();

    if (!file) {
      alert("An image file must be attached!");
      e.preventDefault();
    }

    formData.append("files", file[0]);

    axios({
      method: "post",
      url: `${baseurl}/upload`,
      headers: {
        Authorization: `Bearer ${getCookie("@usertoken")}`,
        "Content-Type": "application/json",
      },
      data: formData,
    }).then((res) => {
      postArtworkInputs(res);
    });
  };

  const postArtworkInputs = (res) => {
    localStorage.removeItem("@artworkImageId");
    localStorage.setItem("@artworkImageId", res.data[0].id);

    //작품 정보 프론트 상의 임시저장
    if (
      formik.values.title === "" ||
      formik.values.size === "" ||
      formik.values.material === ""
    )
      return alert("작품 정보를 입력해 주세요!");

    artworkArr.push({ id: id, data: formik.values });
    setArtworks(artworkArr);
    setImgUrl(res.data[0].url);

    alert("작품이 저장되었습니다!");
    setIsAddBtnOpen(true);
    setIsSaveBtnOpen(false);
  };

  const onDrop = (acceptedFile) => {
    setFile(
      acceptedFile.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  return (
    <Container>
      <StyledForm id={id} onSubmit={formik.handleSubmit}>
        <StyledGrClose
          onClick={() => {
            removeForm(id);
          }}
        />
        <Wrapper>
          <ProfilePhoto>
            <div>
              <FileUploader
                maxFiles={1}
                fileUrl={file && file[0]?.preview}
                onDrop={onDrop}
              />
            </div>
          </ProfilePhoto>

          <Info>
            <TextField
              fullWidth
              id="title"
              label="Title"
              variant="standard"
              {...formik.getFieldProps("title")}
            />
            {formik.touched.title && formik.errors.title ? (
              <ErrorMassage>{formik.errors.title}</ErrorMassage>
            ) : null}
            <InputTitle>size</InputTitle>
            <SizeBox name="size" value={(formik.values.size = size) || ""}>
              <SizeWrapper>
                Width:
                <SizeInput
                  step="0.01"
                  name="width"
                  id="width"
                  value={sizes["width"]}
                  onChange={handleSizes}
                />
                cm
              </SizeWrapper>
              <SizeWrapper>
                Height:
                <SizeInput
                  step="0.01"
                  name="height"
                  id="height"
                  value={sizes["height"]}
                  onChange={handleSizes}
                />
                cm
              </SizeWrapper>
              <SizeWrapper>
                Depth:
                <SizeInput
                  step="0.01"
                  name="depth"
                  id="depth"
                  value={sizes["depth"]}
                  onChange={handleSizes}
                />
                cm
              </SizeWrapper>
            </SizeBox>
            {formik.touched.size && formik.errors.size ? (
              <ErrorMassage>{formik.errors.size}</ErrorMassage>
            ) : null}

            <TextField
              fullWidth
              id="material"
              label="Material"
              variant="standard"
              {...formik.getFieldProps("material")}
            />
            {formik.touched.material && formik.errors.material ? (
              <ErrorMassage>{formik.errors.material}</ErrorMassage>
            ) : null}

            <InputTitle>Year</InputTitle>
            <TextField
              fullWidth
              id="Year"
              type="date"
              variant="standard"
              {...formik.getFieldProps("year")}
            />

            <input
              type="hidden"
              name="owner"
              id="owner"
              value={(formik.values.owner = $artistId) || ""}
              onChange={formik.handleChange || ""}
            />
            <input
              type="hidden"
              name="imageurl"
              id="imageurl"
              value={(formik.values.imageurl = imgUrl) || ""}
              onChange={formik.handleChange || ""}
            />
          </Info>
        </Wrapper>
        <StyledEngineProvider injectFirst>
          {isSaveBtnOpen && (
            <SaveBtn
              variant="outlined"
              color="primary"
              type="submit"
              form={id}
              onClick={(e) => {
                handleImageData(e);
              }}
            >
              작품 저장 (save)
            </SaveBtn>
          )}
        </StyledEngineProvider>
      </StyledForm>
    </Container>
  );
}
export default ArtworkForm;

const Container = styled.div`
  margin: 20px 0;
  @media (min-width: 480px) {
    padding: 30px 0;
    border-bottom: 1px solid lightgray;
  }
  @media (min-width: 768px) {
    padding: 100px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: 3px 2px 3px lightgray;
  }
  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    margin: 10px 50px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: 3px 2px 3px lightgray;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1280px) {
    width: 90%;
  }
`;

const Wrapper = styled.section`
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
  @media (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ProfilePhoto = styled.div`
  margin: 20px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const Info = styled.section`
  padding: 0 20px;
  @media (min-width: 1280px) {
    padding-left: 60px;
  }
`;

const InputTitle = styled.div`
  padding-top: 45px;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  color: #757575;
`;

const StyledGrClose = styled(GrClose)`
  position: sticky;
  cursor: pointer;
  @media (min-width: 480px) {
    left: 90vw;
  }
  @media (min-width: 768px) {
    left: 85vw;
  }
  @media (min-width: 1024px) {
    left: 80vw;
  }
  @media (min-width: 1280px) {
    left: 100vw;
  }
`;

const ErrorMassage = styled.div`
  padding-top: 7px;
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const SaveBtn = styled(Button)`
  display: flex;
  margin: 10rem;
  padding: 1rem;
  border-radius: 35px;
  box-shadow: 2px 3px 3px lightgray;
`;

const SizeBox = styled.div`
  padding: 10px 0 20px 5px;
  font-size: 0.9rem;
`;

const SizeInput = styled.input.attrs({ type: "number" })`
  display: flex;
  margin: 0 6px 10px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  font-size: 0.9rem;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const SizeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
