import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom'; 
import * as Auth from '../../../../components/AuthComponent';
import ResendEmailModal from './ResendEmailModal';
import styled from 'styled-components';
import { getDecryptedCookie } from '../../../../utils/cookieUtils';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
});

const Form = () => {
  const Navigate = useNavigate(); 
  const [isLogged, setIsLogged] = useState(!!getDecryptedCookie('@usertoken'));
  const [openModal, setOpenModal] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async values => {
    try {
      const auth = await Auth.authenticateUser(values.email, values.password);
      if (auth) {
        Navigate('/account-general'); // navigate를 사용하여 페이지를 리다이렉션합니다
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    setIsLogged(!!getDecryptedCookie('@usertoken'));
  }, []);

  return !isLogged ? (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Login
        </Typography>
        <Typography
          variant='h4'
          sx={{
            fontWeight: 700,
          }}
        >
          Welcome back
        </Typography>
        <Typography color='text.secondary'>
          Login to manage your account.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label='Email *'
              variant='outlined'
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              display='flex'
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Enter your password
                </Typography>
              </Box>
              <Typography variant={'subtitle2'}>
                <Link
                  component={'a'}
                  color={'primary'}
                  href={'/password-reset-cover'}
                  underline={'none'}
                >
                  Forgot your password?
                </Link>
              </Typography>
            </Box>
            <TextField
              label='Password *'
              variant='outlined'
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display='flex'
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <LinkWrapper>
                  <Typography variant={'subtitle2'}>
                    Don't have an account yet?{' '}
                    <Link
                      component={'a'}
                      color={'primary'}
                      href={'/signup-cover'}
                      underline={'none'}
                    >
                      Sign up here.
                    </Link>
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    Didn't get verification mail?{' '}
                    <Typography
                      className={'clickHere'}
                      component={'a'}
                      color={'#377dff'}
                      onClick={() => setOpenModal(true)}
                      underline={'none'}
                    >
                      Click here.
                    </Typography>
                  </Typography>
                </LinkWrapper>
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      {openModal ? (
        <ResendEmailModal openModal={openModal} setOpenModal={setOpenModal} />
      ) : null}
    </Box>
  ) : (
    <Navigate to='/account-general' />
  );
};

export default Form;

const LinkWrapper = styled.div`
  .clickHere {
    cursor: pointer;
    font-size: 14px;
  }
`;