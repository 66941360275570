/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from "react-intl";

const Headline = () => {

  return (
    <Box>

        <Typography variant="h3" gutterBottom>
          
          <FormattedMessage id="GALLERY_HEADLINE_SENTENCES1"></FormattedMessage>
        </Typography>
        <Typography variant="h3" color={"primary"} fontWeight={700}>
          
          <FormattedMessage id="GALLERY_HEADLINE_SENTENCES2"></FormattedMessage>
        </Typography>

    </Box>
  );
};

export default Headline;
