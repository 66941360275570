import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import ArtworkForm from "./components/ArtworkForm";
import EventArtworks from "./components/EventArtworks/EventArtworks";
import SubmitButton from "./components/SubmitButton";
import AddButton from "./components/AddButton";
import { baseurl } from "../../utils/index";
import { getCookie } from "../../utils/cookies";

function RegisteredArtistForm() {
  const [isAddBtnOpen, setIsAddBtnOpen] = useState(true);

  // 작가 프로필 get State
  const [artist, setArtist] = useState([
    {
      localname: "",
      englishname: "",
      usercountryname: "",
      edulevel: "",
      ex_career: "",
      awards: "",
      email: "",
      facebook: "",
      instagram: "",
      blog: "",
      hompage: "",
    },
  ]);
  const [profileImgUrl, setProfileImgUrl] = useState("");

  // form 추가&삭제 State
  const [count, setCount] = useState(0);
  const [formList, setFormList] = useState([]);
  let formArr = [...formList];

  // 작품등록 폼의 작품정보 임시저장  State
  const [artworks, setArtworks] = useState([]);
  let artworkArr = [...artworks];

  const $artistId = localStorage.getItem("@artist");

  useEffect(() => {
    $artistId &&
      axios({
        method: "get",
        url: `${baseurl}/artists/${$artistId}`,
        headers: {
          Authorization: `Bearer ${getCookie("@usertoken")}`,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setArtist(res.data);
        setProfileImgUrl(res.data.profileimg.url);
      });
  }, [isAddBtnOpen]);

  window.onbeforeunload = (e) => {
    return (e = e || window.event);
  };

  const addForm = () => {
    setCount((prev) => prev + 1);
    formArr.push({ id: count });
    setFormList(formArr);
    setIsAddBtnOpen(false);
  };

  const removeForm = (id) => {
    setFormList(formList.filter((form) => form.id !== id));
    setArtworks(artworks.filter((artwork) => artwork.id !== id));
    setIsAddBtnOpen(true);

    // status 403 에러 뜸 !!!
    // axios({
    //   method: 'delete',
    //   url: `${baseurl}/upload/files/${$artworkImageId}`,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('@usertoken')}`,
    //     'Content-Type': 'application/json',
    //   },
    // }).then(res => {
    //   console.log('Delete successfully !! ', res);
    // });
  };

  return (
    <Box>
      <RegisterWrapper>
        <form>
          <Tittle>
            <p>My profile</p>
          </Tittle>
          <ProfileWrapper>
            <div>
              <img src={profileImgUrl} width={200} />
            </div>
            <Info>
              <List>
                <InfoTittle>Name</InfoTittle>
                {artist.localname}
              </List>
              <List>
                <InfoTittle>EnglishName</InfoTittle>
                {artist.englishname}
              </List>
              <List>
                <InfoTittle>Email</InfoTittle>
                {artist.email}
              </List>
              <List>
                <InfoTittle>Country</InfoTittle>
                {artist.usercountryname}
              </List>
              <List>
                <InfoTittle>Eudcation</InfoTittle>
                {artist.edulevel}
              </List>
              <List>
                <InfoTittle>Homepage</InfoTittle>
                {artist.homepage}
              </List>
              <List>
                <InfoTittle>Instagram</InfoTittle>
                {artist.instagram}
              </List>
              <List>
                <InfoTittle>Blog</InfoTittle>
                {artist.blog}
              </List>
              <List>
                <InfoTittle>Facebook</InfoTittle>
                {artist.facebook}
              </List>
              <List>
                <InfoTittle>Private Exhibition</InfoTittle>
                {artist.ex_career}
              </List>
              <List>
                <InfoTittle>Group Exhibition</InfoTittle>
                {artist.ex_group_carrer}
              </List>
              <List>
                <InfoTittle>Awards</InfoTittle>
                {artist.awards}
              </List>
            </Info>
          </ProfileWrapper>
        </form>

        <section>
          <TittleWrapper>
            <SubTittle>작품등록(Registration of artwork)</SubTittle>
          </TittleWrapper>
          {formList.map((list) => (
            <ArtworkForm
              key={list.id}
              removeForm={removeForm}
              id={list.id}
              setIsAddBtnOpen={setIsAddBtnOpen}
              artworkArr={artworkArr}
              setArtworks={setArtworks}
            />
          ))}
          {isAddBtnOpen && <AddButton addForm={addForm} />}
          <SubTittle>Saved</SubTittle>
          <EventArtworks artworks={artworks} removeForm={removeForm} />
          {formList.length > 0 && (
            <SubmitButton artworkArr={artworkArr} artworks={artworks} />
          )}
        </section>
      </RegisterWrapper>
    </Box>
  );
}
export default RegisteredArtistForm;

const RegisterWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  @media (min-width: 480px) {
    padding: 30px 0;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 0;
  }
  @media (min-width: 1620px) {
    width: 80%;
  }
  @media (min-width: 1920px) {
    width: 70%;
  }
`;

const Tittle = styled.header`
  padding: 40px;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid lightgray;
`;

const TittleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTittle = styled.header`
  padding: 40px 10px;
  font-size: 30px;
  font-weight: 600;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
`;

const ProfileWrapper = styled.section`
  border-bottom: 1px solid lightgray;
  @media (min-width: 480px) {
    text-align: center;
    padding: 20px 0;
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Info = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  border: 1px solid #ededed;
  border-radius: 20px;

  @media (min-width: 480px) {
    margin: 30px 0;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    width: 100%;
  }
`;

const InfoTittle = styled.label`
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
`;

const List = styled.div`
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
  @media (min-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
