/** @format */

import useFormatMessage from "../components/useFormatMessage";
import { useIntl, FormattedMessage } from "react-intl";

function NavPages() {
  return {
    landings: [
      // {
      //   title: 'Mobile App',
      //   href: '/mobile-app',
      // },
      // {
      //   title: 'Desktop App',
      //   href: '/desktop-app',
      // },
      // {
      //   title: 'Startup',
      //   href: '/startup',
      // },
      // {
      //   title: 'Expo',
      //   href: '/expo',
      // },
      // {
      //   title: 'Marketing',
      //   href: '/',
      // },
      // {
      //   title: 'Service',
      //   href: '/service',
      // },
      // {
      //   title: 'Enterprise',
      //   href: '/enterprise',
      // },
      // {
      //   title: 'Cloud Hosting',
      //   href: '/cloud-hosting',
      // },
      {
        title: "Design Company",
        href: "/design-company",
      },
      // {
      //   title: 'Web Basic',
      //   href: '/web-basic',
      // },
      // {
      //   title: 'Overview',
      //   href: '/home',
      // },
      // {
      //   title: 'Agency',
      //   href: '/agency',
      // },
      // {
      //   title: 'E-Learning',
      //   href: '/e-learning',
      // },
      // {
      //   title: 'Coworking',
      //   href: '/coworking',
      // },
      // {
      //   title: 'Rental',
      //   href: '/rental',
      // },
      // {
      //   title: 'Job Listing',
      //   href: '/job-listing',
      // },
      // {
      //   title: 'Logistics',
      //   href: '/logistics',
      // },
      // {
      //   title: 'E-commerce',
      //   href: '/e-commerce',
      // },
    ],
    company: [
      // {
      //   title: 'Career Lising',
      //   href: '/career-listing',
      // },
      // {
      //   title: 'Job Opening',
      //   href: '/career-opening',
      // },
      // {
      //   title: "Customer Stories",
      //   href: "/customers",
      // },
      // {
      //   title: "Hire Us",
      //   href: "/hire-us",
      // },
      {
        title: "About",
        href: "/about-side-cover",
      },
      // {
      //   title: 'About',
      //   href: '/about',
      // },
      // {
      //   title: "Pricing",
      //   href: "/pricing",
      // },
      // {
      //   title: "Terms",
      //   href: "/company-terms",
      // },
      // {
      //   title: 'Contact: Map',
      //   href: '/contact-sidebar-map',
      // },
      // {
      //   title: 'Contact: Minimal',
      //   href: '/contact-page-cover',
      // },
      {
        title: "Contact",
        href: "/contact-page",
      },
    ],
    account: [
      {
        title: "General Settings",
        href: "/account-general",
      },
      // {
      //   title: "Security Settings",
      //   href: "/account-security",
      // },
      // {
      //   title: "Notifications Options",
      //   href: "/account-notifications",
      // },
      // {
      //   title: "Billing Options",
      //   href: "/account-billing",
      // },
      // {
      //   title: 'Sign up: Simple',
      //   href: '/signup-simple',
      // },
      // {
      //   title: "Sign up",
      //   href: "/signup-cover",
      // },
      // {
      //   title: 'Sign in: Simple',
      //   href: '/signin-simple',
      // },
      // {
      //   title: "Sign in",
      //   href: "/signin-cover",
      // },
      // {
      //   title: 'Password: Simple',
      //   href: '/password-reset-simple',
      // },
      // {
      //   title: 'Password: Cover',
      //   href: '/password-reset-cover',
      // },
    ],
    secondary: [
      {
        title: "FAQ",
        href: "/faq",
      },
      // {
      //   title: "Help Center", //"Help Center: Overview",
      //   href: "/help-center",
      // },
      // {
      //   title: "Help Center: Article",
      //   href: "/help-center-article",
      // },
      // {
      //   title: 'Error 404: Simple',
      //   href: '/not-found',
      // },
      // {
      //   title: 'Error 404: Cover',
      //   href: '/not-found-cover',
      // },
      // {
      //   title: "Find New Artists",
      //   href: "/career-listing-minimal",
      // },
    ],
    blog: [
      {
        title: "Reach View",
        href: "/blog-reach-view",
      },
      {
        title: "Search",
        href: "/blog-search",
      },
      {
        title: "Newsroom",
        href: "/blog-newsroom",
      },
      {
        title: "Article",
        href: "/blog-article",
      },
    ],
    portfolio: [
      {
        title: "Grid View",
        href: "/portfolio-grid",
      },
      {
        title: "Masonry",
        href: "/portfolio-masonry",
      },
      {
        title: "Basic",
        href: "/portfolio-page",
      },
      {
        title: "Parallax",
        href: "/agency",
      },
    ],
    artwork: [
      {
        title: "Artwork Gallery",
        href: "/artwork-search",
      },
      // { /* 3인 단체전 포맷 */
      //   title: 'Artwork Portfolio View',
      //   href: '/artwork-porfolio-mansonry',
      // },
    ],
    exhibition: [
      {
        title: "Exhibition Gallery", //useFormatMessage("CONTENTPAGE_ARTWORKSLIST_WORD"),//<FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
        href: "/exhibition-search",
      },
      // {
      //   title: "VRGallery", //useFormatMessage("CONTENTPAGE_ARTWORKSLIST_WORD"),//<FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
      //   href: "/exhibition-vrgallery",
      // },
    ],
    vrexhibition: [
      {
        title: "VR Exhibition Gallery", //useFormatMessage("CONTENTPAGE_ARTWORKSLIST_WORD"),//<FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
        href: "/exhibition-vrgallery",
      },
      // {
      //   title: "VRGallery", //useFormatMessage("CONTENTPAGE_ARTWORKSLIST_WORD"),//<FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
      //   href: "/exhibition-vrgallery",
      // },
    ],
    artist: [
      {
        title: "Artists", //`${intl.FormatMessage({id:"CONTENTPAGE_ARTISTS_WORD"})}`, // <FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
        href: "/artist-portfolio-grid",
      },
      // {
      //   title: "ArtistDetail", //useFormatMessage("CONTENTPAGE_ARTWORKSLIST_WORD"),//<FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
      //   href: "/artist-detail/:id",
      // },
    ],
    gallery: [
      // {
      //   title: "Galleries", //useFormatMessage("CONTENTPAGE_ARTWORKSLIST_WORD"),//<FormattedMessage id="CONTENTPAGE_ARTWORKSLIST_WORD" />,//'Artwork Gallery',
      //   href: "/gallery-portfolio-grid",
      // },
      {
        title: "Galleries",
        href: "/galleries",
      },
    ],
    admin: [
      {
        title: "Caption Manager",
        href: "/caption-manager",
      },
    ],
  };
}

// export default injectIntl(NavPages);
export default NavPages;
