/** @format */
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import InputAdornment from "@mui/material/InputAdornment";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { FormattedMessage, useIntl } from "react-intl";
import { baseurl } from "../../../../utils";
import { loadData } from "../../../../components/hooks/useFetch";
import CircularStatic from "../../../../components/CircularProgressWithLabel";

const Result = () => {
  // console.log("baseurl>>>", baseurl);
  const intl = useIntl();
  const theme = useTheme();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [textfield, setTextField] = useState("");
  const [url, setURL] = useState(
    `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${searchtext}&_sort=createdAt:DESC`
  );

  // const onLoadArtworkData =
  useEffect(() => {
    setURL(
      `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${searchtext}&_sort=createdAt:DESC`
    );
    // console.log("UPDATE:url", url);

    setTimeout(async () => {
      const reqdata = await loadData(url);
//console.log("useEffect > reqdata >>>", reqdata);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
  }, [start, url]);

  const HandleClick = () => {
    // console.log("HandleClick>>", url);
    let startloadmore = limit + offset;
    // console.log("HandleClick startloadmore >>", startloadmore);
    setLimit(startloadmore);
    // console.log("HandleClick setLoadmore >>", startloadmore);
    setURL(
      `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${startloadmore}&title_contains=${searchtext}&_sort=createdAt:DESC`
    );
  };

  const HandleTextFieldChange = (e) => {
    // console.log("HandleTextFieldChangeClick>>", e.target.value);
    setTextField(e.target.value);
  };

  const HandleSearch = () => {
    // console.log("HandleSearchClick>>", textfield);

    setSearchText(textfield);
    setURL(
      `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${textfield}&_sort=createdAt:DESC`
    );
  };

  return (
    <Box>
      <Box
        padding={2}
        width={1}
        component={Card}
        boxShadow={4}
        marginBottom={4}
      >
        <form noValidate autoComplete="off">
          <Box display="flex" alignItems={"center"}>
            <Box width={1} marginRight={1}>
              <TextField
                sx={{
                  height: 54,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0 !important",
                  },
                }}
                variant="outlined"
                color="primary"
                size="medium"
                placeholder={intl.formatMessage({
                  id: "CONTENTPAGE_EXTITLESEARCH_PHRASE",
                })}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        component={"svg"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                        color={"primary.main"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                onChange={HandleTextFieldChange}
              />
            </Box>
            <Box display={{ xs: "none", sm: "block" }} marginRight={2}>
              <Typography
                color={"text.secondary"}
                variant={"subtitle2"}
                sx={{ whiteSpace: "nowrap" }}
              >
                {count} Results
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{ height: 54, minWidth: 100, whiteSpace: "nowrap" }}
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                onClick={HandleSearch}
              >
                <FormattedMessage id="CONTENTPAGE_SEARCH_WORD" />
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      {/* dynamic data */}
      {!data && (
        <Box display="flex" justifyContent={"center"}>
          <CircularStatic />
        </Box>
      )}
      <Grid container spacing={4}>
  {data &&
    data.map((item, i) => (
      <Grid item xs={12} sm={6} md={4} key={i}>
        <Box
          component={"a"}
          href={`/exhibition-article/${item.id}`}
          display={"block"}
          width={1}
          height={1}
          sx={{
            textDecoration: "none",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: `translateY(-${theme.spacing(1 / 2)})`,
            },
          }}
        >
          <Box
            component={Card}
            width={1}
            height={1}
            boxShadow={4}
            display={"flex"}
            flexDirection={"column"}
            sx={{ backgroundImage: "none" }}
          >
            <CardMedia
              image={
                item.mainimage[0] && item.mainimage[0].formats
                  ? item.mainimage[0].url
                  : ""
              }
              title={item.title}
              sx={{
                height: { xs: 300, md: 360 },
                position: "relative",
              }}
            >
              <Box
                component={"svg"}
                viewBox="0 0 2880 480"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  color: theme.palette.background.paper,
                  transform: "scale(2)",
                  height: "auto",
                  width: 1,
                  transformOrigin: "top center",
                }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                  fill="currentColor"
                />
              </Box>
            </CardMedia>
            <Box component={CardContent} position={"relative"}>
              <Typography variant={"h6"} gutterBottom>
                {item.title}
              </Typography>
              <Typography color="text.secondary">
                {item.galleries && item.galleries[0] ? item.galleries[0].name : 'No Gallery'}
              </Typography>
              <Typography color="text.secondary">
                {item.period}
              </Typography>
              <Typography color="text.secondary">{item.year}</Typography>
            </Box>
            <Box flexGrow={1} />
            <Box padding={2} display={"flex"} flexDirection={"column"}>
              <Box marginBottom={2}>
                <Divider />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Box display="flex" alignItems="center" sx={{ gap: 0.5 }}>
                    <Avatar
                      src={
                        item.artists && item.artists[0] && item.artists[0].profileimg
                          ? item.artists[0].profileimg.url
                          : ""
                      }
                      sx={{ marginRight: 1 }}
                    />
                    <Link
                      href={item.artists && item.artists.length > 0 ? `/artist-details/${item.artists[0]._id}` : "#"}
                      id={item.id}
                      color={"text.secondary"}
                      sx={{ textDecoration: "none" }}
                    >
                      {item.artists && item.artists.length > 0
                        ? `${item.artists[0].localname}(${item.artists[0].englishname})`
                        : intl.formatMessage({
                            id: "CONTENTPAGE_ARTISTNOTREGISTERED_SENTENCE",
                          })}
                    </Link>
                    <Box display="flex" alignItems="center" sx={{ marginLeft: 2 }}>
                      {/* <PhotoLibraryIcon sx={{ marginRight: 1 }} />
                      <Link
                        href={`/exhibition-vrgallery/${item.id}`}
                        id={item.id}
                        color={"text.secondary"}
                        sx={{ textDecoration: "none" }}
                        target="_blank" // 새 창에서 열기
                        rel="noopener noreferrer" // 보안 속성 추가
                      >
                        {"가상갤러리"}
                      </Link> */}
                    </Box>
                  </Box>
                </Box>
                <Typography color={"text.secondary"}>
                  {item.date}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    ))}
  <Grid item container justifyContent={"center"} xs={12}>
    <Button
      fullWidth
      variant={"outlined"}
      size={"large"}
      sx={{ height: 54, maxWidth: 400, justifyContent: "space-between" }}
      endIcon={
        <Box
          component={"svg"}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width={24}
          height={24}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </Box>
      }
      onClick={HandleClick}
    >
      <FormattedMessage id="CONTENTPAGE_LOADMORE_PHRASE" />
    </Button>
  </Grid>
</Grid>

      {/* End of dynamic data */}
    </Box>
  );
};

export default Result;
