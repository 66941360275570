/** @format */

import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { baseurl } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { loadData } from "../../../../components/hooks/useFetch";
import CircularStatic from "../../../../components/CircularProgressWithLabel";

const Main = () => {
  const intl = useIntl();
  const theme = useTheme();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [textfield, setTextField] = useState("");
  const [url, setURL] = useState(
    `${baseurl}/galleries`
  );

  // const onLoadArtworkData =
  useEffect(() => {
    setURL(
      `${baseurl}/galleries` 
    );
    // console.log("UPDATE:url", url);

    setTimeout(async () => {
      const reqdata = await loadData(url);
      // console.log("useEffect > reqdata >>>", reqdata);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
    // console.log("UPDATE:data", data);
  }, [start, limit, url, searchtext]);

  return (
    <Box>
      {!data && (
        <Box display="flex" justifyContent={"center"}>
          <CircularStatic />
        </Box>
      )}
      <Grid container spacing={4}>
        {data &&
          data.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                component={"a"}
                href={""}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={"flex"}
                  flexDirection={"column"}
                >
//console.log("item", item)} */}
                  <CardMedia
                    image={item.image[0] ? item.image[0].url : ""}
                    title={item.name}
                    sx={{
                      height: { xs: 340, md: 400 },
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  />
                  <Box component={CardContent}>
                    <Typography variant={"h6"} fontWeight={700} gutterBottom>
                      {/* {`${item.name} (${item.name})`} */}
                      {item.name}
                    </Typography>
                    <Typography variant={"body2"} color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box component={CardActions} justifyContent={"flex-start"}>
                    <Button
                      size="large"
                      endIcon={
                        <svg
                          width={16}
                          height={16}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      }
                    >
                      <FormattedMessage id="ARTISTPORTFOLIOMAIN_LEARNMORE_PHRASE" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Main;
