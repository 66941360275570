/** @format */

import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { baseurl } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import useFetch, { loadData } from "../../../../components/hooks/useFetch";
import CircularStatic from "../../../../components/CircularProgressWithLabel";

const Main = () => {
  // console.log("Main.js > baseurl >>>", baseurl);
  // console.log("process.env.baseurl>>>", process.env.REACT_APP_baseurl,);
  const intl = useIntl();
  const theme = useTheme();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(100);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [textfield, setTextField] = useState("");
  const [url, setURL] = useState(
    `${baseurl}/artists?is_exposed=true&_start=${start}&_limit=${limit}&_sort=createdAt:DESC`
  );

  useEffect(() => {
    const fetchData = async () => {
      setURL(
        `${baseurl}/artists?is_exposed=true&_start=${start}&_limit=${limit}&_sort=createdAt:DESC`
      );
      try {
        const reqdata = await loadData(url);
        // console.log("useEffect > reqdata >>>", reqdata);
        if (Array.isArray(reqdata)) {
          setData(reqdata);
          setCount(reqdata.length);
        } else {
          console.error("Fetched data is not an array");
          setData([]);
          setCount(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
        setCount(0);
      }
    };

    fetchData();
  }, [start, limit, url, searchtext]);

  useEffect(() => {
    // console.log("UPDATE:data", data);
  }, [data]);

  return (
    <Box>
      {!data.length && (
        <Box display="flex" justifyContent={"center"}>
          <CircularStatic />
        </Box>
      )}
      <Grid container spacing={4}>
        {data.map((item, i) => {
          // console.log('아이템: ', item); // 각 아이템을 출력
          return (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                component={"a"}
                href={`/artist-details/${item.id}`}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <CardMedia
                    image={item.profileimg ? item.profileimg.url : ""}
                    title={item.localname}
                    sx={{
                      height: { xs: 340, md: 400 },
                      filter:
                        theme.palette.mode === "dark"
                          ? "brightness(0.7)"
                          : "none",
                    }}
                  />
                  <Box component={CardContent}>
                    <Typography variant={"h6"} fontWeight={700} gutterBottom>
                      {`${item.localname} (${item.englishname})`}
                    </Typography>
                    <Typography variant={"body2"} color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box component={CardActions} justifyContent={"flex-start"}>
                    <Button
                      size="large"
                      href={`/artist-details/${item.id}`}
                      endIcon={
                        <svg
                          width={16}
                          height={16}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      }
                    >
                      <Box display={"flex"} alignItems={"right"}>
                        <FormattedMessage id="ARTISTPORTFOLIOMAIN_LEARNMORE_PHRASE" />
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Main;
