import React from "react";
import styled from "styled-components";
import Artwork from "./EventArtwork";

export default function EventArtworks({ artworks, removeForm }) {
  return (
    <Content>
      {artworks.map((artwork) => {
        return (
          <Artwork
            key={artwork?.id}
            artwork={artwork}
            removeForm={removeForm}
            id={artwork.id}
          />
        );
      })}
    </Content>
  );
}

const Content = styled.div`
  display: grid;
  padding-bottom: 15rem;
  grid-template-columns: repeat(8, 1fr);
  @media (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(8, 1fr);
  }
`;
