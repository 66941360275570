import React from "react";
import styled from "styled-components";
// import { Button, StyledEngineProvider } from "@mui/material/styles";
// import { StyledEngineProvider } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

function AddButton({ addForm }) {
  return (
    <BtnWrapper>
      <StyledEngineProvider injectFirst>
        <Btn fullWidth variant="contained" color="primary" onClick={addForm}>
          작품 추가하기 (Add form)
        </Btn>
      </StyledEngineProvider>
    </BtnWrapper>
  );
}

const BtnWrapper = styled.div`
  margin-top: 100px;

  @media (min-width: 768px) {
    padding: 1.5rem 8rem;
  }
  @media (min-width: 1024px) {
    padding: 1.5rem 8rem;
  }
  @media (min-width: 1620px) {
    padding: 1.5rem 0;
  }
`;

const Btn = styled(Button)`
  margin-bottom: 3rem;
  padding: 1.5rem;
  border-radius: 35px;
  box-shadow: 2px 3px 3px lightgray;
`;

export default AddButton;
