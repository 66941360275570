/** @format */

import React, { useState, useEffect } from "react";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { FormattedMessage, useIntl } from "react-intl";
import { ArtistCard, ExhibitionCard } from "..";
import { useNavigate } from "react-router";
import axios from "axios";
import { baseurl } from "../../../../utils";
import { getDecryptedCookie } from '../../../../utils/cookieUtils';

const Details = (data) => {
  const artworkData = data.data;
  const shopUrl = artworkData.shopitemreg;
  const [like, setLike] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUser = localStorage.getItem("@user");
        const userToken = getDecryptedCookie("@usertoken");
        setUser(storedUser);
        setToken(userToken);
        
        if (!userToken) {
          console.error('No user token found');
          return;
        }

        // console.log('getCookie("@usertoken")', userToken);

        const config = {
          method: "get",
          url: `${baseurl}/users/me`,
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios(config);
        const userInfo = response.data;
        setUserData(userInfo);

        if (userInfo && userInfo.artwork_likes.length > 0) {
          setLike(userInfo.artwork_likes.includes(artworkData.id));
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [artworkData.id]);

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#E1306C",
      },
    },
  });

  const onClickFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://v2-master.amongarts.com${window.location.pathname}`
    );
  };

  const clickLike = async () => {
    let config = {
      method: "get",
      url: `${baseurl}/users/me`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        const userInfo = res.data;
        setUserData(userInfo);
      })
      .catch((error) => {
        if (error.response) {
          alert(
            intl.formatMessage({
              id: "COMMON_NEEDLOGIN_PHRASE",
            })
          );
          navigate("/signin-cover");
        }
      });

    if (user) {
      if (!userData.artwork_likes.includes(artworkData.id)) {
        userData.artwork_likes.push(artworkData.id);
        const data = JSON.stringify({
          artwork_likes: userData.artwork_likes,
        });

        var configForPut = {
          method: "put",
          url: `${baseurl}/users/${user}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(configForPut)
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return error;
          });
        setLike(true);
      } else {
        userData.artwork_likes.splice(
          userData.artwork_likes.indexOf(artworkData.id),
          1
        );
        const data = JSON.stringify({
          artwork_likes: userData.artwork_likes,
        });

        var configForPut = {
          method: "put",
          url: `${baseurl}/users/${user}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(configForPut)
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return error;
          });
        setLike(false);
        return;
      }
    } else {
      navigate("/signin-cover");
    }
  };

  return (
    <Box>
      <Typography variant={"h4"} fontWeight={700}>
        {artworkData.title}
      </Typography>
      <Box marginY={3}>
        <Typography
          variant={"subtitle2"}
          color={"text.secondary"}
          sx={{ textDecoration: "line-through", marginLeft: 1 }}
        >
          {artworkData.image.caption}
        </Typography>
        <Typography fontWeight={500}>{artworkData.material}</Typography>
        <Typography fontWeight={500}>
          {artworkData.size} {artworkData.year}
        </Typography>
      </Box>
      <Typography variant={"subtitle2"}>{artworkData.artistnote}</Typography>
      <Stack marginTop={3} direction={{ xs: "column", sm: "row" }} spacing={2}>
        <ThemeProvider theme={theme}>
          <Button
            variant={"outlined"}
            color={like ? "secondary" : "primary"}
            size={"medium"}
            fullWidth
            startIcon={like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            onClick={() => clickLike()}
          >
            <FormattedMessage id="ARTWORK_DETAIL_LIKE"></FormattedMessage>
          </Button>
        </ThemeProvider>
        <Button
          disabled={shopUrl ? false : true}
          variant={"contained"}
          color={"primary"}
          size={"medium"}
          fullWidth
          startIcon={<ShoppingCartIcon />}
          href={shopUrl ? shopUrl.itemurl : null}
          target="_blank"
        >
          <FormattedMessage id="ARTWORK_DETAIL_FAVORITES"></FormattedMessage>
        </Button>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        marginY={2}
        borderTop="1px solid lightgrey"
        paddingTop={2}
      >
        <Stack
          direction={"column"}
          width={{ xs: "100%", sm: "100%" }}
          alignContent={{ xs: "center", sm: "flex-end" }}
        >
          <Typography width="100%">
            {`${artworkData.owner.localname} (${artworkData.owner.englishname})`}
          </Typography>
          <Typography width="100%">{artworkData.owner.edulevel}</Typography>
        </Stack>
        <ArtistCard props={artworkData} width={{ xs: "50%", sm: "100%" }} />
      </Stack>
      <Stack direction="column" alignItems="flex-end" marginY={2}>
        <Typography>
          <FormattedMessage id="ARTWORK_SHARE"></FormattedMessage>
        </Typography>
        <Stack direction={"row"} marginTop={0.5}>
          <IconButton color={"primary"}>
            <FacebookIcon onClick={onClickFacebook} />
          </IconButton>
          <IconButton color={"primary"}>
            <InstagramIcon />
          </IconButton>
          <IconButton color={"primary"}>
            <TwitterIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Typography marginY={2}>
        <FormattedMessage id="RELATED_EXHIBITIONS"></FormattedMessage>
      </Typography>
      <ExhibitionCard props={artworkData} />
    </Box>
  );
};

export default Details;
