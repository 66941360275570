/** @format */

// @flow
import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useFetch from "../../../components/hooks/useFetch";
import Main from "../../../layouts/Main";
import Container from "../../../components/Container";
import { baseurl } from "../../../utils/index";
import { Breadcrumb, GenerateCaptionData } from "./components";

// TODO:Add GalleryCardList component to avoid insert extra className
// TODO:Update Page.Header to additional components

export function CaptionDataManager() {

  const is_Login = localStorage.getItem("@roletype");
  const [exhibition, setExhibition] = useState("");
  const [data, setData] = useState("");
  const recv_data = useFetch(`${baseurl}/exhibitions?is_exposed=true`);
  useEffect(() => {
    setData(recv_data);
  }, [exhibition,recv_data]);

  const handleChange = (event) => {
    setExhibition(event.target.value);
    const newArray = recv_data;
    setData(newArray);
  };

  console.log("isLogin: " + is_Login);
  console.log("data: " + data);

  return data && !is_Login ? (
    <Routes>
      <Route path="/login" element={<Navigate replace to="/login" />} />
    </Routes>
  ) : (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container paddingY={2}>
          <Breadcrumb />
        </Container>
      </Box>
      <Container title="Caption Manager">
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Exhibition
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={exhibition}
              label="Select Exhibition"
              onChange={handleChange}
            >
              {recv_data &&
                recv_data.map((item, key) => (
                  <MenuItem value={item ? key : undefined} key={key}>
                    {item ? item.title : "No Title"}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ height:20 }} />
        <Box sx={{ minWidth: 120 }}>
          {recv_data[exhibition] && (
            <GenerateCaptionData
              key={recv_data[exhibition].id}
              id={recv_data[exhibition].id}
              data={recv_data[exhibition]}
            />
          )}
        </Box>
      </Container>
    </Main>
  );
}

export default CaptionDataManager;