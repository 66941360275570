import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import * as Yup from "yup";
import FileUploader from "./components/FileUploader";
import ArtworkForm from "./components/ArtworkForm";
import EventArtworks from "./components/EventArtworks/EventArtworks";
import SubmitButton from "./components/SubmitButton";
import AddButton from "./components/AddButton";
import Button from '@mui/material/Button';
// import { StyledEngineProvider } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles'; //'@mui/styles';
import TextField from '@mui/material/TextField';
import { useFormik } from "formik";
import { BsTrash } from "react-icons/bs";
import { baseurl } from "../../utils/index";
import { getCookie } from "../../utils/cookies";

function ArtistForm() {
  // 조건부 렌더링
  const [isAddBtnOpen, setIsAddBtnOpen] = useState(true);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(true);
  const [isArtistId, setIsArtistId] = useState(false);

  // 작가 등록 이미지 사진 관리
  const [file, setFile] = useState("");
  // 작품등록 폼의 작품정보 임시저장  State
  const [artworks, setArtworks] = useState([]);
  let artworkArr = [...artworks];

  // form 추가&삭제 State
  const [count, setCount] = useState(0);
  const [formList, setFormList] = useState([]);
  let formArr = [...formList];

  window.onbeforeunload = (e) => {
    return (e = e || window.event);
  };

  const validationSchema = Yup.ObjectSchema({
    localname: Yup.string().required("*name is required"),
    englishname: Yup.string().required("*english name is required"),
    mobile: Yup.string().required("*mobile is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("*email is required"),
  });

  const formik = useFormik({
    initialValues: {
      localname: "",
      englishname: "",
      email: "",
      mobile: "",
      edulevel: "",
      usercountryname: "",
      homepage: "",
      instagram: "",
      blog: "",
      facebook: "",
      ex_career: "",
      ex_group_career: "",
      awards: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      handleDataSubmit(values);

      // console.log('formik: ', formik.values);
    },
  });

  //작가등록 Post
  const handleDataSubmit = (values, e) => {
    const formData = new FormData();
    const data = {};

    if (!file) {
      alert("An image file must be attached!");
      e.preventDefault();
    }

    for (let key in values) {
      if (values[key].length > 0 || values[key] !== "") data[key] = values[key];
    }
    data.user = localStorage.getItem("@user");

    formData.append("files.profileimg", file[0], file[0].name);
    formData.append("data", JSON.stringify(data));

    for (let value of formData.values()) {
      // console.log('values:', value);
    }
    axios({
      method: "post",
      url: `${baseurl}/artists`,
      headers: {
        Authorization: `Bearer ${getCookie("@usertoken")}`,
        "content-type": "application/json",
      },
      data: formData,
    }).then((res) => {
      // console.log('success!! ', res);
      alert("작가 등록이 정상적으로 완료되었습니다 :)");
      setIsArtistId(true);
      setIsRegistrationOpen(false);
      window.scrollTo(0, 0);
    });
  };

  const onDrop = (acceptedFile) => {
    setFile(
      acceptedFile.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const addForm = () => {
    setCount((prev) => prev + 1);
    formArr.push({ id: count });
    setFormList(formArr);
    setIsAddBtnOpen(false);
  };

  const removeFile = () => {
    setFile("");
  };

  const removeForm = (id) => {
    setFormList(formList.filter((form) => form.id !== id));
    setArtworks(artworks.filter((artwork) => artwork.id !== id));
    setIsAddBtnOpen(true);

    // status 403 에러 뜸 !!!
    // axios({
    //   method: 'delete',
    //   url: `${baseurl}/upload/files/${$artworkImageId}`,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('@usertoken')}`,
    //     'Content-Type': 'application/json',
    //   },
    // }).then(res => {
    //   console.log('Delete successfully !! ', res);
    // });
  };

  return (
    <StyledBox>
      <RegisterWrapper>
        {isRegistrationOpen && (
          <form id="myform" onSubmit={formik.handleSubmit}>
            <Title>
              <p>작가등록 (Artist Registration)</p>
            </Title>
            <ProfileWrapper>
              <div>
                <FileUploader
                  maxFiles={1}
                  fileUrl={file && file[0]?.preview}
                  onDrop={onDrop}
                />
              </div>
              <RemoveBtn type="button" onClick={removeFile}>
                <BsTrash color="#ffffff" />
              </RemoveBtn>
            </ProfileWrapper>
            <InputWrapper>
              <Inputs>
                <Box>
                  <InputTitle>Name</InputTitle>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      required
                      id="localname"
                      label="Name"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("localname")}
                    />
                    {formik.touched.localname && formik.errors.localname ? (
                      <ErrorMassage>{formik.errors.localname}</ErrorMassage>
                    ) : null}
                    <TextField
                      fullWidth
                      required
                      id="englishname"
                      label="English Name"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("englishname")}
                    />
                    {formik.touched.englishname && formik.errors.englishname ? (
                      <ErrorMassage>{formik.errors.englishname}</ErrorMassage>
                    ) : null}
                  </TextFieldWrapper>
                </Box>
                <Box>
                  <InputTitle>Email</InputTitle>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <ErrorMassage>{formik.errors.email}</ErrorMassage>
                    ) : null}
                  </TextFieldWrapper>
                </Box>
                <Box>
                  <InputTitle>Mobile</InputTitle>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      required
                      id="mobile"
                      label="Mobile"
                      variant="outlined"
                      helperText="e.g.010-0000-0000"
                      margin="normal"
                      {...formik.getFieldProps("mobile")}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <ErrorMassage>{formik.errors.mobile}</ErrorMassage>
                    ) : null}
                  </TextFieldWrapper>
                </Box>
                <Box>
                  <InputTitle>Country</InputTitle>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      id="usercountryname"
                      label="Country"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("usercountryname")}
                    />
                  </TextFieldWrapper>
                </Box>
                <Box>
                  <InputTitle>Education</InputTitle>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      id="edulevel"
                      label="Education Level"
                      variant="outlined"
                      margin="normal"
                      helperText="e.g. 홍익대학교 회화과 졸업"
                      {...formik.getFieldProps("edulevel")}
                    />
                  </TextFieldWrapper>
                </Box>
                <Box>
                  <InputTitle>Links</InputTitle>
                  <TextFieldWrapper>
                    <TextField
                      fullWidth
                      id="homepage"
                      label="Homepage"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("homepage")}
                    />
                    <TextField
                      fullWidth
                      id="instagram"
                      label="Instagram"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("instagram")}
                    />
                    <TextField
                      fullWidth
                      id="blog"
                      label="Blog"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("blog")}
                    />
                    <TextField
                      fullWidth
                      id="facebook"
                      label="Facebook"
                      variant="outlined"
                      margin="normal"
                      {...formik.getFieldProps("facebook")}
                    />
                  </TextFieldWrapper>
                </Box>
                <TextAreaWrapper>
                  <InputTitle>Private Exhibitions</InputTitle>
                  <TextAreaInput
                    id="ex_career"
                    {...formik.getFieldProps("ex_career")}
                  />
                  <InputTitle>Group Exhibitions</InputTitle>
                  <TextAreaInput
                    id="ex_group_career"
                    {...formik.getFieldProps("ex_group_career")}
                  />
                  <InputTitle>Awards</InputTitle>
                  <TextAreaInput
                    id="awards"
                    {...formik.getFieldProps("awards")}
                  />
                </TextAreaWrapper>
              </Inputs>
            </InputWrapper>
            <SaveBtn>
              <StyledEngineProvider injectFirst>
                <StyledBtns
                  fullWidth
                  variant="outlined"
                  form="myform"
                  type="submit"
                  color="primary"
                >
                  save
                </StyledBtns>
              </StyledEngineProvider>
            </SaveBtn>
          </form>
        )}
        {isArtistId && (
          <Section>
            <TittleWrapper>
              <SubTittle>작품등록(Registration of artwork)</SubTittle>
            </TittleWrapper>
            {formList.map((list) => (
              <ArtworkForm
                key={list.id}
                removeForm={removeForm}
                id={list.id}
                setIsAddBtnOpen={setIsAddBtnOpen}
                artworkArr={artworkArr}
                setArtworks={setArtworks}
              />
            ))}
            {isAddBtnOpen && <AddButton addForm={addForm} />}
            <SubTittle>Saved</SubTittle>
            <EventArtworks artworks={artworks} removeForm={removeForm} />
            {formList.length > 0 && (
              <SubmitButton artworkArr={artworkArr} artworks={artworks} />
            )}
          </Section>
        )}
      </RegisterWrapper>
    </StyledBox>
  );
}

export default ArtistForm;

const RegisterWrapper = styled.section`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  @media (min-width: 480px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px;
  }
  @media (min-width: 1620px) {
    width: 80%;
  }
  @media (min-width: 1920px) {
    width: 70%;
  }
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.header`
  padding: 40px;
  font-size: 40px;
  font-weight: 500;
  border-bottom: 1px solid lightgray;
`;

const InputWrapper = styled.article`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

const StyledBtns = styled(Button)`
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 35px;
  box-shadow: 2px 3px 3px lightgray;
`;

const RemoveBtn = styled.button`
  margin-top: 10px;
  padding: 10px 30px;
  border: 1px solid #303f9f;
  border-radius: 10px;
  background-color: #303f9f;
  cursor: pointer;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const InputTitle = styled.label`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextFieldWrapper = styled.div`
  width: 70%;
`;

const TextAreaInput = styled.textarea`
  margin: 30px 0;
  padding: 20px 10px;
  width: 100%;
  height: 100px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  resize: none;
`;

const TextAreaWrapper = styled.div`
  padding-top: 50px;
`;

const ErrorMassage = styled.div`
  font-size: 12px;
  color: red;
  font-weight: 500;
`;

const SaveBtn = styled.div`
  padding: 100px;
`;

const TittleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubTittle = styled.header`
  padding: 40px 10px;
  font-size: 30px;
  font-weight: 600;
`;

const Section = styled.section`
  width: 100%;
`;
