// config.js
const config = {
    NODE_ENV: process.env.NODE_ENV,
    baseurl: process.env.REACT_APP_baseurl,
    basehost: process.env.REACT_APP_basehost,
    identifier: process.env.REACT_APP_IDENTIFIER,
    password: process.env.REACT_APP_PASSWORD,
    CHANNELIO_KEY: process.env.REACT_APP_CHANNELIO_KEY,
    SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  };
  
  export default config;
  
