import AWS from 'aws-sdk';
// import dotenv from 'dotenv';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { baseurl } from "../../utils";
import 'aframe-extras';
require('aframe-aabb-collider-component');



// dotenv.config();
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const VGalleryPage = () => {
  const { id } = useParams();

  const [exhibitionData, setExhibitionData] = useState([]);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [imageSources, setImageSources] = useState(exhibitionData.map((item) => item.url));
  const [imageReloads, setImageReloads] = useState(exhibitionData.map(() => 0));
  const [url, setURL] = useState(`${baseurl}/exhibitions/${id}`);

  // useEffect(() => {
  //   // Load aframe-water script dynamically
  //   const script = document.createElement('script');
  //   script.src = 'https://cdn.jsdelivr.net/npm/aframe-extras/dist/aframe-extras.min.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const fetchExhibitionData = async () => {
    try {
      const { data } = await axios.get(`${baseurl}/exhibitions/${id}`);
      const exhi = [{ url: await fetchImageUrl(data.mainimage[0].formats.medium.url) }];
      const artWorks = await Promise.all(
        data.art_works.filter((_, i) => i < 5).map(async (work) => ({
          ...work,
          image: {
            ...work.image,
            formats: {
              thumbnail: {
                url: await fetchImageUrl(work.image.formats.thumbnail.url),
              },
            },
          },
        }))
      );
      setExhibitionData([...exhi, ...artWorks]);
//console.log("fetchExhibitionData>setExhibitionData",setExhibitionData);
      setImageSources([...exhi.map((item) => item.url), ...artWorks.map((work) => work.image.formats.thumbnail.url)]);
    } catch (error) {
      console.error('Error fetching exhibition data:', error);
    }
  };

    // 클릭 시 작품 제목을 표시하는 함수
    const handleImageClick = (index) => {
      // 클릭한 작품의 인덱스가 이미 클릭된 작품과 같은 경우 제목을 숨김
      if (clickedIndex === index) {
        setClickedIndex(null);
      } else {
        // 클릭한 작품의 인덱스를 클릭 상태로 설정하여 제목을 표시
        setClickedIndex(index);
      }
      if (!exhibitionData[index] || !exhibitionData[index].url) {
        console.error(`No URL found for image at index ${index}`);
        fetchExhibitionData();
        renderAssets();
        renderImages();
        return;
      }
      setImageSources(prevSources => {
        const newSources = [...prevSources];
//console.log("prevSources",prevSources);
//console.log("newSources",newSources);
  
        const timestamp = new Date().getTime(); // 현재 타임스탬프를 추가
        newSources[index] = `${exhibitionData[index].url}?t=${timestamp}`;
        return newSources;
      });
      setImageReloads((prevReloads) => {
        const newReloads = [...prevReloads];
        newReloads[index] += 1;
        return newReloads;
      });
    };

  // useEffect(() => {
  //   setImageReloads(exhibitionData.map(() => 0));
  // }, [exhibitionData]);
  
  // const handleImageMouseEnter = (index) => {
  //   setImageReloads((prevReloads) => {
  //     const newReloads = [...prevReloads];
  //     newReloads[index] += 1;
  //     return newReloads;
  //   });
  // };

  // useEffect(() => {
  //   setImageReloads(exhibitionData.map(() => 0));
  // }, [exhibitionData]);


  const renderAssets = () => {
    return imageSources.map((src, index) => {
//console.log("renderAssets>imageSource",src, index);

            if (index === 0) {
              // 메인 이미지일 경우
              return (
                <img
                  key={index}
                  id={`image-${index}`}
                  src={`${src}`}
                  alt={`Art work ${index}`}
                  crossOrigin="anonymous"
                />
              );
            } else {
              // 나머지 아트워크 이미지일 경우
              return (
                <img
                  key={index}
                  id={`image-${index}`}
                  src={`${src}`}
                  alt={`Art work ${index}`}
                  crossOrigin="anonymous"
//console.log(`Image ${index} loaded.`)}
                  onError={(e) => console.error(`Error loading image ${index}`, e)}
                />
              );
            }

    });
  };

  const renderImages = () => {
    return exhibitionData.map((item, index) => {
//console.log("renderImages>exhibitionData[index]",exhibitionData[index]);
      if (index === 0) {
        // 메인 이미지일 경우
        return (
          <Entity
            id="partition_main"
            primitive='a-plane'
            geometry={{depth: 0.2, height: 2, width: 2 }}
            src={`${exhibitionData[index].url}?reload=${imageReloads[index]}`}
            material={{
              color: 'white',
              src: `#image-${index}`,
              repeat: '1 1',
              roughness: 0.5,
              normalTextureRepeat: '1 1',
              normalScale: '1 -1',
            }}
            position={{ x: 0.1, y: 2, z: -2.27 }}
            rotation={{ x: 0.0, y: -11.492, z: 0.0 }}
            scale={{ x: 1.478, y: 1.512, z: 1.0 }}
            crossOrigin="anonymous"
          />
        );
      } else {
        // 나머지 아트워크 이미지일 경우
        return (
            <Entity
            key={index}
            id={`image-${index}`}
            primitive="a-plane"
            src={`${exhibitionData[index].image.formats.thumbnail.url}?reload=${imageReloads[index]}`}
            // src={`#image-${index}`}
            geometry={{ height: 3, width: 3, depth: 10 }}
            material={{ color: "white", shader: "phong", side: "double", src: `#image-${index}`}}
            position={{ x: index*5-15, y: 2.48, z: -14.768 }}
            alt={`Art work ${index}`}
            crossOrigin="anonymous"
            events={{ click: () => handleImageClick(index) }}
            // events={{ mouseenter: () => handleImageMouseEnter(index) }}
          />
        );
      }
    });
  };

  useEffect(() => {
    fetchExhibitionData();
  }, []);

  const fetchImageUrl = async (key) => {
    const parts = key.split('/');
    const keyWithoutBucket = parts.slice(3).join('/');
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: keyWithoutBucket,
    };

    try {
      const url = await new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', params, (err, url) => {
          if (err) reject(err);
          else resolve(url);
        });
      });
      return url;
      // const response = await axios.get(`http://localhost:5005/api/image-url?key=${keyWithoutBucket}`);
      // return response.data.url;
      
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  };

  useEffect(() => {
    const sceneEl = document.querySelector('a-scene');
    const handleAssetsLoaded = () => {
      setAssetsLoaded(true);
    };

    sceneEl.addEventListener('loaded', handleAssetsLoaded);

    return () => {
      sceneEl.removeEventListener('loaded', handleAssetsLoaded);
    };
  }, [exhibitionData]);

  

  const renderFrames = () => {
    return exhibitionData.map((item, index) => {
      if (index === 0) {
        // 메인 이미지일 경우
        return (
          <Entity
            id={`frame-${index}`}
            geometry={{ primitive: 'box', depth: 0.5, height: 3.5, width: 3.5 }}
            // material={{ color: 'white' }}
            material={{ color: "white", shader: "phong", side: "double"}}
            position={{ x: 0.225, y: 1.523, z: -2.55 }}
            rotation={{ x: 0.0, y: -11.492, z: 0.0 }}
            scale={{ x: 1.478, y: 1.512, z: 1.0 }}
          ></Entity>  
        );
      } else {
        // 나머지 아트워크 이미지일 경우
        return (
          <Entity
            id={`frame-${index}`}
            geometry={{ primitive: 'box', depth: 0.2, height: 3, width: 3 }}
            // material={{ color: 'silver' }}
            material={{ color: "white", shader: "phong", side: "double"}}
            position={{ x: index*5-15, y: 2.48, z: -14.9 }}
            // scale={{ x: 1, y: 1, z: 0.5 }}
            scale={{ x: 1.1, y: 1.1, z: 0.5 }}
          ></Entity>  
        );
      }
    });
  };

  const renderLights = () => {
    return exhibitionData.map((item, index) => {
      if (index > 0) {
      return (
        <a-light 
          id={`light-${index}`}
          key={`light-${index}`}
          type="spot" 
          color="red" 
          light="color: #ffffff; distance: 3; intensity: 10" 
          position={`${index*5-15} 3.60062 -12.20341`}
          visible="" 
          rotation="-45 0 0"
        ></a-light>
        );
      }
    })
  };
  
  return (
    // <Scene stats debug>
    <Scene>
      <a-assets>
        <img
            id="skyTexture"
            src="../../vr/scene/scene-sky-overview.jpg" 
            crossOrigin="anonymous"
            alt=""
            key="skyTexture"
          />
        {renderAssets()}
      </a-assets>
      {assetsLoaded ? (
        <>
          <a-camera camera="" 
            position="-0.22066 1.59928 -0.07894" 
            rotation="-1.1430508012859923 -10.749834152244507 0.217151004354582" 
            look-controls="" 
            wasd-controls="" 
            data-aframe-inspector-original-camera="">
            <a-cursor 
              animation__click="property: scale; startEvents: click; from: 0.1 0.1 0.1; to: 1 1 1; dur: 150" 
              material="" 
              raycaster="" 
              cursor="" 
              geometry="">
            </a-cursor>
          </a-camera>
          {/* <Entity id="rig" position="0 1.6 0">
              <Entity id="camera" camera look-controls wasd-controls
                aabb-collider="objects: .box" // 카메라에 충돌 감지 추가
              />
          </Entity> */}
           {/* <a-box color="red" enabled="true" aabb-collider="objects: a-box" position="-2 1 -5" shadow
            event-set__hitstart="material.opacity: 0.5; material.color: orange"
            event-set__hitend="material.opacity: 1; material.color: red"
          ></a-box>
          <a-entity hand-controls="right" aabb-collider="objects: .box"></a-entity>
          <a-box class="box" color="blue" position="0 1 -5"></a-box> */}
          
          <a-sky src="#skyTexture"
            height="2048"
            radius="30"
            theta-length="90"
            materialtextureloaded="true"
            width="2048"></a-sky>

          <Entity
            primitive="a-plane"
            id="ceiling"
            src="../../vr/scene/bottom-view-ceiling-with-geometric-object.jpg"
            //src="#roofTexture"
            // material={{color: 'red'}}
            position="0.000 10.587 -0.010"
            rotation="90 0 0"
            width="30"
            height="30"
            repeat="1 1"
          />
          {/* <a-plane id="ceiling" src="../../vr/scene/scene-roof-tile.jpg" height="30" width="30" rotation="-90 0 0" position="0.000 10.587 -0.010"></a-plane> */}
          <Entity
            primitive="a-plane"
            id="ground"
            src="../../vr/scene/scene-2145-wooden-floor.jpg"//"#groundTexture"
            // material={{color: 'yellow'}}
            rotation="-90 0 0"
            width="30"
            height="30"
            repeat="1 5"
          />
          <Entity
            primitive="a-light"
            id="light1"
            type="ambient"
            color="#445451"
            light=""
          />
          <a-light id="light2" type="hemisphere" color="#445451" position="-7.54039 8.02261 -41.674" 
            light="color: #ffffff; distance: 10; intensity: 0.5; type: hemisphere" visible=""></a-light>
          <Entity
            primitive="a-light"
            id="light3"
            type="ambient"
            color="#445451"
            light=""
          />
          {renderLights()}
          <Entity
            id="wall2"
            geometry={{primitive: 'box', depth: 0.2, height: 11, width: 30 }}
            material={{
              metalness: 0, // set metalness to 0 for a non-metallic appearance
              color: 'white', 
              roughness: 0.5, 
              src: '../../vr/scene/scene-warm-plaster-wall-texture-background.jpg'
            }}
            position={{ x: -0.034, y: 5.285, z: -15.061 }}
            class="collidable"
            />

          <Entity
              id="wall3"
              geometry={{primitive: 'box', depth: 0.2, height: 11, width: 30 }}
              material={{color: 'white', roughness: 0.5,
              src: '../../vr/scene/scene-warm-plaster-wall-texture-background.jpg'
              }}
              position={{ x: -0.034, y: 5.285, z: 14.898 }}
              class="collidable"
              />

          <Entity
            id="wall4"
            geometry={{primitive: 'box', depth: 0.2, height: 11, width: 30 }}
            material={{
              color: 'white',
              src: '../../vr/scene/scene-warm-plaster-wall-texture-background.jpg',//'#walltexture',
              // repeat: '3 3',
              width: 1000,
              height: 100,
              roughness: 0.5,
              // normalMap: '#walltexture',
              normalTextureRepeat: '1 1',
              normalScale: '1 -1',
            }}
            position={{ x: 14.971, y: 5.251, z: 0.0 }}
            rotation={{ x: -0.265, y: 90, z: 0.0 }}
            class="collidable"
          ></Entity>

          <Entity
            id="wall5"
            geometry={{primitive: 'box', depth: 0.2, height: 11, width: 30 }}
            material={{
              color: 'white',
              src: '../../vr/scene/scene-warm-plaster-wall-texture-background.jpg',//'#walltexture',
              repeat: '3 3',
              width: 1000,
              height: 100,
              roughness: 0.5,
              // normalMap: '#walltexture',
              normalTextureRepeat: '1 1',
              normalScale: '1 -1',
            }}
            position={{ x: -15.117, y: 5.3, z: 0.0 }}
            rotation={{ x: 0.0, y: 90, z: 0.0 }}
            class="collidable"
          ></Entity>
          {/* <Entity
            id="video"
            geometry={{primitives:'a-video', height: 5, width: 7}}
            material={{src:'http://streamdemo.fusionsnow.com/research_security_01.mp4?tkn=97428437'}}
            autoplay loop="true"
            position={{ x: 5, y: 2, z: 0.0 }}
          ></Entity> */}
          
          {renderImages()}
          {renderFrames()}
          {/* 클릭된 작품의 제목을 표시하는 엔티티 */}
          {clickedIndex !== null && (
                <Entity
                  id="title-entity"
                  primitive="a-text"
                  color="black"
                  value={`Title: ${exhibitionData[clickedIndex].title}`}
                  position={{ x: clickedIndex*5-15, y: 0.5, z: -14.768 }}
                />
            
          )}
          
        </>
      ) : (
        <a-text value="Loading..." position="0 2 -3" align="center"></a-text>
      )}
    </Scene>
  );
};

export default VGalleryPage;
