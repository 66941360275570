import React, { useState, useEffect } from "react";
import { baseurl } from "../../utils/index";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import SignUpEndModal from "./components/SignUpEndModal";
import { getCookie } from "../../utils/cookies";

const EventSignUpComponent = (props) => {
  const { gotEmail, isLogged, setIsLogged } = props;
  const [openModal, setOpenModal] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .min(2, "Please enter a valid name")
      .max(50, "Please enter a valid name")
      .required("Please specify your first name"),
    lastName: yup
      .string()
      .trim()
      .min(2, "Please enter a valid name")
      .max(50, "Please enter a valid name")
      .required("Please specify your last name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),
    password: yup
      .string()
      .required("Please specify your password")
      .min(8, "The password should have at minimum length of 8"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: gotEmail,
    password: "",
  };

  useEffect(() => {
    try {
      setIsLogged(!!getCookie("@usertoken"));
    } catch (error) {}
  }, [isLogged]);

  const onSubmit = async (values, actions) => {
    setUserInfo({
      email: values.email,
      password: values.password,
    });
    signUp(values, actions);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const signUp = async (values, actions) => {
    await axios
      .post(`${baseurl}/auth/local/register`, {
        username: `${values.firstName}/${values.lastName}`,
        email: values.email,
        password: values.password,
      })
      .then(() => setOpenModal(true))
      .catch((err) => {
        if (err.response.status === 400) {
          setOpenModal(false);
          alert(`User email or username already exists`);
        } else {
          setOpenModal(false);
          alert(`Failed to sign up please try again`);
        }
      });
  };

  return (
    <SignUpWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              i
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              {" "}
              <ButtonWrapper>
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Sign in
                </Button>
              </ButtonWrapper>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"text.secondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={"/company-terms"}
                underline={"none"}
              >
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <SignUpEndModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          userInfo={userInfo}
          setIsLogged={setIsLogged}
        />
      </form>
    </SignUpWrapper>
  );
};

export default EventSignUpComponent;

const SignUpWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
