/** @format */

import React from "react";
import Box from "@mui/material/Box";
import Coart from "../../../../assets/images/partners/coart.svg";
import FreedaGallery from "../../../../assets/images/partners/freedaartgallery.svg";

const mock = [
  { image: Coart, title: "코아트" },
  { image: FreedaGallery, title: "프리다아트 갤러리" },
];

const Partners = () => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
      {mock.map((item, i) => (
        <Box
          maxWidth={{ xs: 80, sm: 90 }}
          marginTop={{ xs: 1 }}
          marginRight={{ xs: 3, sm: 6, md: 12 }}
          key={i}
        >
          <Box
            component="img"
            height={1}
            width={1}
            src={item.image}
            alt={item.title}
            sx={{
              filter: "contrast(0)",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Partners;
