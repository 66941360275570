/** @format */

import { store } from "../Store";



const USER_SAVE = "USER_SAVE";
const USER_DELETE = "USER_DELETE";
const USER_LOGIN = "USER_LOGIN";
const USER_LOGOUT = "USER_LOGOUT";
export const saveUser = (jwt, user, artist, company) => {
  store.dispatch({
    type: USER_SAVE,
    payload: {
      jwt,
      user,
      //add
      artist,
      company,
    },
  });
};

export const deleteUser = () => {
  store.dispatch({ type: USER_DELETE });
};

export const loginUser = (userinfo) => {
  store.dispatch({
    type: USER_LOGIN,
    payload: {
      jwt: userinfo.jwt,
      user: userinfo.user,
    },
  });
};

export const logoutUser = () => {
  store.dispatch({
    type: USER_LOGOUT,
    payload: {
      jwt: process.env.REACT_APP_DEFAULT_TOKEN,
      user: null,
    },
  });
};
