import {
  load,
  edit,
  save,
  dismiss,
  //   auth
} from '../../app/controllers/ArtistController';

export default class ArtistModel {
  constructor(args) {
    this.id = args.identifier;
    this.localname = args.localname;
    this.englishname = args.englishname;
    this.usercountryname = args.usercountryname;
    this.edulevel = args.edulevel;
    this.ex_career = args.ex_career;
    this.awards = args.awards;
    this.mobile = args.mobile;
    this.email = args.email;
    this.facebook = args.facebook;
    this.instagram = args.instagram;
    this.blog = args.blog;
    this.homepage = args.homepage;
    this.profileimg = args.profileimg;
    this.profileimgurl = args.profileimgurl;
    this.artworks = args.artworks;
    this.exhibitions = args.exhibitions;
    this.usertoken = args.usertoken;
  }

  async save() {
    //console.log('::::::::::::::::: onArtistDataSaving ::::::::::::::::::::');

    const id = await save(this);

    //console.log(':::::::::Artist ID after Saving ::::::::::::', id);

    // should no id be returned throw an error
    if (!id) {
      throw new Error('Artist could not be saved');
    }
    // set id and return true
    this.id = id;
    return true;
  }

  async load() {
    //console.log('::::::::::::::::: onArtistDataLoading ::::::::::::::::::::');

    //console.log(':::ArtistModel::this', this);
    const artist = await load(this);

    //console.log(':::::::::Artist after Loading ::::::::::::', artist);

    // should no id be returned throw an error
    if (!artist) {
      throw new Error('Artist could not be saved');
    }
    // set id and return true
    // this.id = id;

    return artist;
  }

  async edit() {
    if (!this.id) {
      throw new Error('Cannot edit artist before artist was saved.');
    }

    //console.log('ArtistModel_Edit!');
    const edited = await edit(this);

    //check if the edit returned false
    if (!edited) {
      throw new Error('artist info could not be edited.');
    }
    return true;
  }

  async dismiss() {
    if (!this.id) {
      throw new Error('Cannot delete artist before it was saved.');
    }
    const dismissed = await dismiss(this);

    //check if the dismiss returned false
    if (!dismissed) {
      throw new Error('Unable to logout artist.');
    }

    return true;
  }

  async auth() {
    if (!this.id) {
      throw new Error('Cannot delete artist before it was saved.');
    }
    const authenticated = await this.auth(this);

    //check if the dismiss returned false
    if (!authenticated) {
      throw new Error('Unable to logout artist.');
    }

    return true;
  }
}
