/** @format */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { baseurl } from '../../../../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadData } from '../../../../components/hooks/useFetch';
import CircularStatic from '../../../../components/CircularProgressWithLabel';

const ExhibitionCard = props => {
  const data = props.props.exhibitions;
  const intl = useIntl();
  const theme = useTheme();
  const offset = 10;
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [searchtext, setSearchText] = useState('');
  const [textfield, setTextField] = useState('');
  const [url, setURL] = useState(
    `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}&title_contains=${searchtext}`
  );

  return (
    <Box>
      <Stack direction={'row'} width="100%">
        {!data ? (
          <Box display="flex" justifyContent={'center'}>
            <CircularStatic />
          </Box>
        ) : (
          <Stack direction={'row'} width="100%">
            {data &&
              data.map((item, i) => (
                <Box
                  component={'a'}
                  href={`/exhibition-article/${item.id}`}
                  display={'block'}
                  key={item.title}
                  height={0.8}
                  width="50%"
                  sx={{
                    textDecoration: 'none',
                    transition: 'all .2s ease-in-out',
                    '&:hover': {
                      transform: `translateY(-${theme.spacing(1 / 2)})`,
                    },
                  }}
                >
                  <Box
                    component={Card}
                    width={1}
                    height={1}
                    boxShadow={4}
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{ backgroundImage: 'none' }}
                  >
                    <CardMedia
                      image={
                        item.mainimage[0] && item.mainimage[0].formats
                          ? item.mainimage[0].url
                          : ''
                      }
                      title={item.title}
                      sx={{
                        height: { xs: 300, md: 360 },
                        position: 'relative',
                      }}
                    >
                      <Box
                        component={'svg'}
                        viewBox="0 0 2880 480"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          color: theme.palette.background.paper,
                          transform: 'scale(2)',
                          height: 'auto',
                          width: 1,
                          transformOrigin: 'top center',
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                          fill="currentColor"
                        />
                      </Box>
                    </CardMedia>
                    <Box component={CardContent} position={'relative'}>
                      <Typography variant={'h6'} gutterBottom>
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Stack>
        )}
        {/* End of dynamic data */}
      </Stack>
    </Box>
  );
};

export default ExhibitionCard;
