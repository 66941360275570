/** @format */

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
// import { makeStyles } from "@mui/material/styles";

const BgDiv = styled('div')({
  backgroundColor: 'grey',
});

const CopyrightText = styled('div')({
  color: 'black',
});

// const useStyles = makeStyles({
//   bg: {
//     backgroundColor: "grey",
//   },
//   copyright: {
//     color: "black",
//   },
// });
function useStyles() {
  return (
    <div>
      <BgDiv>Background Div</BgDiv>
      <CopyrightText>© 2024 Your Company</CopyrightText>
    </div>
  );
}


const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        ></Box> */}

        {/* <Box height={0.5} width={1} /> */}
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="theFront"
          width={80}
        >
          <Box
            component={"img"}
            src={
              mode === "light"
                ? "../../../../../assets/images/logo/logo.svg"
                : "../../../../../assets/images/logo/logo-negative.svg"
            }
            height={1}
            width={1}
          />
        </Box>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="https://www.artnartech.com/ko/who-we-are/"
            target="_blank"
          >
            회사소개
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="https://www.artnartech.com/contact/"
            target="_blank"
          >
            찾아오시는 길
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="https://www.artnartech.com/terms-n-conditions/"
            target="_blank"
          >
            회원약관
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="/company-terms"
            target="_blank"
          >
            개인정보처리방침
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="https://www.artnartech.com/prohibition-of-unauthorized-collection-of-email-addresses/"
            target="_blank"
          >
            이메일무단수집거부
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="https://www.artnartech.com/contact/"
            target="_blank"
          >
            정보 API 이용요청
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="https://www.artnartech.com/contact/"
            target="_blank"
          >
            제휴문의
          </Link>
          {" | "}
          <Link
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            href="/contact-page"
          >
            고객센터
          </Link>

          <Typography
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
          >
            Copyright © 2020 Art&ArtTech Inc. All rights reserved.
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
