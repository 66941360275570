import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import configuration from '../config/config';

const SECRET_KEY = configuration.SECRET_KEY; 
//'adfsafafadac15fb2c0601f99311Da964-03b4c3cd'; // 고정된 비밀 키 사용, 환경 변수를 사용하려면 process.env.REACT_APP_SECRET_KEY 사용
//console.log("config SECRET_KEY :::",configuration.SECRET_KEY);
//console.log(".env SECRET_KEY :::",process.env.REACT_APP_SECRET_KEY);

if (!SECRET_KEY) {
  console.error("SECRET_KEY is not defined. Please set it in your environment variables.");
}

// 암호화된 쿠키를 설정하는 함수
export function setEncryptedCookie(name, value, days) {
  try {
    if (!SECRET_KEY) {
      throw new Error("SECRET_KEY is not defined");
    }
    const encryptedValue = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
    Cookies.set(name, encryptedValue, { expires: days });
//console.log(`Cookie ${name} set with encrypted value: ${encryptedValue}`);
  } catch (error) {
    console.error(`Error setting encrypted cookie ${name}:`, error);
  }
}

// 암호화된 쿠키를 읽는 함수
export function getDecryptedCookie(name) {
  const cookieValue = Cookies.get(name);
  if (!cookieValue) {
    // console.error(`Cookie with name ${name} not found`);
    return null;
  }

  try {
    if (!SECRET_KEY) {
      throw new Error("SECRET_KEY is not defined");
    }
    const bytes = CryptoJS.AES.decrypt(cookieValue, SECRET_KEY);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedValue) {
      // console.error('Decryption failed, possibly due to incorrect key or corrupt data');
      return null;
    }
    return decryptedValue;
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
}

// 쿠키를 삭제하는 함수
export function removeCookie(name) {
  try {
    Cookies.remove(name);
//console.log(`Cookie ${name} removed successfully`);
  } catch (error) {
    console.error(`Error removing cookie ${name}:`, error);
  }
}
