import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

const Newsletter = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          fontWeight={700}
          variant={"h4"}
          align={"center"}
          gutterBottom
        >
          <FormattedMessage id="EXHBITIONNEWSLETTER_SUBSCRIBE_SENTECE" />
        </Typography>
        <Typography
          variant={"h6"}
          component={"p"}
          color={"text.secondary"}
          align={"center"}
        >
          <FormattedMessage id="EXHBITIONNEWSLETTER_SUBSCRIBE2_SENTECE" />
        </Typography>
      </Box>
      <Box maxWidth={600} margin={"0 auto"}>
        <Box
          component={"form"}
          noValidate
          autoComplete="off"
          sx={{
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              bgcolor: "background.paper",
            },
          }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "center", md: "flex-start" }}
            justifyContent={{ xs: "center" }}
          >
            <Box
              disabled={true}
              flex={"1 1 auto"}
              component={TextField}
              label="Coming Soon!" //"Enter your email"
              variant="outlined"
              color="primary"
              fullWidth
              height={54}
              sx={{
                maxWidth: 422,
              }}
            />
            <Box
              disabled={true}
              component={Button}
              variant="contained"
              color="primary"
              size="large"
              height={54}
              marginTop={{ xs: 2, md: 0 }}
              marginLeft={{ md: 2 }}
            >
              Subscribe
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Newsletter;
