/** @format */

import { setCookie, getCookie, removeCookie } from "../../utils/cookies";

const defaultState = {
  payload: {
    jwt: process.env.REACT_APP_DEFAULT_TOKEN,
    user: null,
  },
  type: null,
};

const USER_SAVE = "USER_SAVE";
const USER_DELETE = "USER_DELETE";
const USER_LOGIN = "USER_LOGIN";
const USER_LOGOUT = "USER_LOGOUT";

const UserReducer = (state = defaultState, action) => {
  // console.log("reducer action::::", action);
  let newState = null;

  switch (action.type) {
    case USER_SAVE: {
      return {
        ...state,
        ...{ jwt: action.payload.jwt, user: action.payload.user },
      };
    }

    case USER_DELETE: {
      newState = Object.assign({}, state, action);
      return newState;
    }

    case USER_LOGIN: {
      newState = Object.assign({}, state, action);
      // console.log("Reducer/USER_LOGIN/newState", newState);
      return newState;
    }

    case USER_LOGOUT: {
      newState = Object.assign({}, defaultState, action);
      return newState;
    }

    default:
      return state;
  }
};

export default UserReducer;
