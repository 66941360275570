import React, { useState, useEffect, useRef } from "react";
import * as Auth from "../../components/AuthComponent";
import EventEnrollUser from "./EventEnrollUser";
import ArtistForm from "./ArtistForm";
import RegisteredArtistForm from "./RegisteredArtistForm";
import Main from "../../layouts/Main";
import styled from "styled-components";
import { getCookie } from "../../utils/cookies";

const EventEnroll = () => {
  const [isLogged, setIsLogged] = useState(!!getCookie("@usertoken"));
  const [userInfo, setUserInfo] = useState("NO INFO");
  const [artist, setArtist] = useState(localStorage.getItem("@artist"));

  const casesFunction = async () => {
    if (isLogged) {
      if (!!localStorage.getItem("@artist")) {
        const data = await Auth.loadArtist(
          localStorage.getItem("@artist"),
          getCookie("@usertoken")
        );
        setArtist({ ...data });
        setUserInfo("ARTIST");
      } else setUserInfo("USER");
    } else setUserInfo("NO INFO");
  };

  useEffect(() => {
    let componentMounted = true;
    if (componentMounted) {
      casesFunction();
    }
    return () => {
      componentMounted = false;
    };
  }, [isLogged]);

  switch (userInfo) {
    case "NO INFO":
      return (
        <Main>
          <WholeWrapper>
            {" "}
            <EventEnrollUser
              isLogged={isLogged}
              setIsLogged={setIsLogged}
            />{" "}
          </WholeWrapper>
        </Main>
      );
    case "ARTIST":
      return (
        artist && (
          <Main>
            <WholeWrapper>
              <RegisteredArtistForm artist={artist} />
            </WholeWrapper>
          </Main>
        )
      );
    case "USER":
      return (
        <Main>
          <WholeWrapper>
            <ArtistForm />
          </WholeWrapper>
        </Main>
      );
  }
};

export default EventEnroll;

const WholeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
