/** @format */

import React from "react";
import { ReactTyped } from "react-typed";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";

const Hero = () => {
  const intl = useIntl();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={"center"}
          gutterBottom
          sx={{ fontWeight: 400 }}
        >
          <FormattedMessage id="ARTISTPORTFOLIO_HERO_SENTENCE" />
        </Typography>
        <Typography
          variant="h3"
          color="text.primary"
          align={"center"}
          sx={{
            fontWeight: 700,
          }}
        >
          {/* <FormattedMessage id="HERO_SERVICE_TITLE" />{" "} */}
          <ReactTyped
            strings={[
              intl.formatMessage({
                id: "ARTISTPORTFOLIOHERO_PROVIDESERVICE_PHRASE",
              }),
              intl.formatMessage({
                id: "ARTISTPORTFOLIOHERO_PROMOTEYOURARTWORKS_PHRASE",
              }),
              intl.formatMessage({
                id: "ARTISTPORTFOLIOHERO_SERVEARTS_PHRASE",
              }),
            ]}
            typeSpeed={100}
            loop={true}
            backSpeed={50}
            backDelay={1500}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
