/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { NavItem } from "./components";
import { FormattedMessage } from "react-intl";
import { CheckSignIn } from "../../../../utils/token";

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const [signIn, setSignIn] = useState(false);
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
    artwork: artworkPages,
    artist: artistPages,
    exhibition: exhibitionPages,
    gallery: galleryPages,
    admin: adminPages,
  } = pages;

  CheckSignIn().then(async (result) => {
    await setSignIn(result);
    // console.log("result>>>", result);
    // console.log("signIn>>>", signIn);
  });
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="amongArts"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={
            mode === "light"
              ? "../../../../../assets/images/logo/logo.svg"
              : "../../../../../assets/images/logo/logo-negative.svg"
          }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component="a"
            href="/"
            color={colorInvert ? "common.white" : "text.primary"}
          >
            {<FormattedMessage id="TOPBAR_MAINMENU_HOME_WORD" />}
          </Link>
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_EXHIBITIONS_WORD" />}
            id={"exhibition"}
            items={exhibitionPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_ARTWORKS_WORD" />}
            id={"artwork"}
            items={artworkPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_ARTISTS_WORD" />}
            id={"artist"}
            items={artistPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_GALLERY_WORD" />}
            id={"gallery"}
            items={galleryPages}
            colorInvert={colorInvert}
          />
        </Box>
        {/* <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_SUPPORT_WORD" />}
            id={"secondary-pages"}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_SERVICE_WORD" />}
            id={"company-pages"}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
        {/* Blog: Later Implement */}
        {/* <Box marginLeft={4}>
          <NavItem
            title={<FormattedMessage id="TOPBAR_MAINMENU_BLOG_WORD" />}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        {signIn && (
          <Box marginLeft={4}>
            <NavItem
              title={<FormattedMessage id="TOPBAR_MAINMENU_ACCOUNT_WORD" />}
              id={"account-pages"}
              items={accountPages}
              colorInvert={colorInvert}
            />
          </Box>
        )}
        {localStorage.getItem('@role')===('Administrator') && (
          <Box marginLeft={4}>
            <NavItem
              title={<FormattedMessage id="TOPBAR_MAINMENU_ADMIN_WORD" />}
              id={"admin-pages"}
              items={adminPages}
              colorInvert={colorInvert}
            />
          </Box>
        )}
        {/* <Box marginLeft={4}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="https://mui.com/store/items/the-front-landing-page/"
            size="large"
          >
            Buy now
          </Button>
        </Box> */}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;