/** @format */

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
// import img1 from '/assets/images/pages'

const Hero = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item container xs={12} md={6} alignItems={"center"}>
        <Box data-aos={isMd ? "fade-right" : "fade-up"}>
          <Box marginBottom={2}>
            <Typography
              variant="h2"
              color="text.primary"
              sx={{ fontWeight: 700 }}
            >
              <FormattedMessage id="HERO_SERVICE_TITLE"></FormattedMessage>
              {/* Design agency that */}
              <br />
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`,
                }}
              >
                <FormattedMessage id="HERO_INSPIRE_WORD" />
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="text.secondary">
              <FormattedMessage id="HERO_SERVICE_INTRODUCE_SENTENCE"></FormattedMessage>
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "stretched", sm: "flex-start" }}
          >
            {/* <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
            >
              Case studies
            </Button>
            <Box
              component={Button}
              color="primary"
              size="large"
              marginTop={{ xs: 2, sm: 0 }}
              marginLeft={{ sm: 2 }}
              fullWidth={isMd ? false : true}
              endIcon={
                <Box
                  component={"svg"}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  width={24}
                  height={24}
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </Box>
              }
            >
              Learn more
            </Box> */}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems={"center"}
        justifyContent={"center"}
        xs={12}
        md={6}
        sx={{
          "& .lazy-load-image-background.lazy-load-image-loaded": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Box
          component={LazyLoadImage}
          height={1}
          width={1}
          src={
            "https://artkissf7658c62d55742edac15fb2c0601f993112016-master.s3.ap-northeast-2.amazonaws.com/mika_AZX_ye0_EF_9h_E_unsplash_a5366b6e24.jpeg"
          }
          alt="Photo by Mika on Unsplash"
          effect="blur"
          borderRadius={2}
          maxWidth={600}
          maxHeight={500}
          sx={{
            objectFit: "cover",
            boxShadow: "19px 20px 0px 0 rgb(140 152 164 / 13%)",
            filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
          }}
        />
        <Typography
          variant={"caption"}
          align={"right"}
          width="100%"
          color={"text.secondary"}
          sx={{href: "https://unsplash.com/@mikafinland?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"}}
        >
          Photo by Mika on Unsplash
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Hero;
