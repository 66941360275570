import React, { useEffect, useState } from 'react';

export const countries_LANGPAK_ko = 
[
  {
    "CODE": "AFG",
    "COUNTRYNAME": "아프가니스탄"
  },
  {
    "CODE": "ALA",
    "COUNTRYNAME": "올란드 제도"
  },
  {
    "CODE": "ALB",
    "COUNTRYNAME": "알바니아"
  },
  {
    "CODE": "DZA",
    "COUNTRYNAME": "알제리"
  },
  {
    "CODE": "ASM",
    "COUNTRYNAME": "아메리칸 사모아"
  },
  {
    "CODE": "AND",
    "COUNTRYNAME": "안도라"
  },
  {
    "CODE": "AGO",
    "COUNTRYNAME": "앙골라"
  },
  {
    "CODE": "AIA",
    "COUNTRYNAME": "앵귈라"
  },
  {
    "CODE": "ATA",
    "COUNTRYNAME": "남극"
  },
  {
    "CODE": "ATG",
    "COUNTRYNAME": "앤티가 바부다"
  },
  {
    "CODE": "ARG",
    "COUNTRYNAME": "아르헨티나"
  },
  {
    "CODE": "ARM",
    "COUNTRYNAME": "아르메니아"
  },
  {
    "CODE": "ABW",
    "COUNTRYNAME": "아루바"
  },
  {
    "CODE": "AUS",
    "COUNTRYNAME": "호주"
  },
  {
    "CODE": "AUT",
    "COUNTRYNAME": "오스트리아"
  },
  {
    "CODE": "AZE",
    "COUNTRYNAME": "아제르바이잔"
  },
  {
    "CODE": "BHS",
    "COUNTRYNAME": "바하마"
  },
  {
    "CODE": "BHR",
    "COUNTRYNAME": "바레인"
  },
  {
    "CODE": "BGD",
    "COUNTRYNAME": "방글라데시"
  },
  {
    "CODE": "BRB",
    "COUNTRYNAME": "바베이도스"
  },
  {
    "CODE": "BLR",
    "COUNTRYNAME": "벨라루스"
  },
  {
    "CODE": "BEL",
    "COUNTRYNAME": "벨기에"
  },
  {
    "CODE": "BLZ",
    "COUNTRYNAME": "벨리즈"
  },
  {
    "CODE": "BEN",
    "COUNTRYNAME": "베냉"
  },
  {
    "CODE": "BMU",
    "COUNTRYNAME": "버뮤다"
  },
  {
    "CODE": "BTN",
    "COUNTRYNAME": "부탄"
  },
  {
    "CODE": "BOL",
    "COUNTRYNAME": "볼리비아(다민족 국가)"
  },
  {
    "CODE": "BES",
    "COUNTRYNAME": "보네르, 신트 유스타티우스, 사바[c]"
  },
  {
    "CODE": "BIH",
    "COUNTRYNAME": "보스니아 헤르체고비나"
  },
  {
    "CODE": "BWA",
    "COUNTRYNAME": "보츠와나"
  },
  {
    "CODE": "BVT",
    "COUNTRYNAME": "부베 아일랜드"
  },
  {
    "CODE": "BRA",
    "COUNTRYNAME": "브라질"
  },
  {
    "CODE": "IOT",
    "COUNTRYNAME": "영국령 인도양 영토"
  },
  {
    "CODE": "BRN",
    "COUNTRYNAME": "브루나이 다루살람"
  },
  {
    "CODE": "BGR",
    "COUNTRYNAME": "불가리아"
  },
  {
    "CODE": "BFA",
    "COUNTRYNAME": "부키 나 파소"
  },
  {
    "CODE": "BDI",
    "COUNTRYNAME": "부룬디"
  },
  {
    "CODE": "CPV",
    "COUNTRYNAME": "카보 베르데"
  },
  {
    "CODE": "KHM",
    "COUNTRYNAME": "캄보디아"
  },
  {
    "CODE": "CMR",
    "COUNTRYNAME": "카메룬"
  },
  {
    "CODE": "CAN",
    "COUNTRYNAME": "캐나다"
  },
  {
    "CODE": "CYM",
    "COUNTRYNAME": "케이맨 제도"
  },
  {
    "CODE": "CAF",
    "COUNTRYNAME": "중앙 아프리카 공화국"
  },
  {
    "CODE": "TCD",
    "COUNTRYNAME": "차드"
  },
  {
    "CODE": "CHL",
    "COUNTRYNAME": "칠레"
  },
  {
    "CODE": "CHN",
    "COUNTRYNAME": "중국[b]"
  },
  {
    "CODE": "CXR",
    "COUNTRYNAME": "크리스마스 섬"
  },
  {
    "CODE": "CCK",
    "COUNTRYNAME": "코코스(킬링) 제도"
  },
  {
    "CODE": "COL",
    "COUNTRYNAME": "콜롬비아"
  },
  {
    "CODE": "COM",
    "COUNTRYNAME": "코모로"
  },
  {
    "CODE": "COG",
    "COUNTRYNAME": "콩고"
  },
  {
    "CODE": "COD",
    "COUNTRYNAME": "콩고민주공화국"
  },
  {
    "CODE": "COK",
    "COUNTRYNAME": "쿡 제도"
  },
  {
    "CODE": "CRI",
    "COUNTRYNAME": "코스타리카"
  },
  {
    "CODE": "CIV",
    "COUNTRYNAME": "코트디부아르"
  },
  {
    "CODE": "HRV",
    "COUNTRYNAME": "크로아티아"
  },
  {
    "CODE": "CUB",
    "COUNTRYNAME": "쿠바"
  },
  {
    "CODE": "CUW",
    "COUNTRYNAME": "퀴라소"
  },
  {
    "CODE": "CYP",
    "COUNTRYNAME": "키프로스[b]"
  },
  {
    "CODE": "CZE",
    "COUNTRYNAME": "체코"
  },
  {
    "CODE": "DNK",
    "COUNTRYNAME": "덴마크"
  },
  {
    "CODE": "DJI",
    "COUNTRYNAME": "지부티"
  },
  {
    "CODE": "DMA",
    "COUNTRYNAME": "도미니카"
  },
  {
    "CODE": "DOM",
    "COUNTRYNAME": "도미니카 공화국"
  },
  {
    "CODE": "ECU",
    "COUNTRYNAME": "에콰도르"
  },
  {
    "CODE": "EGY",
    "COUNTRYNAME": "이집트"
  },
  {
    "CODE": "SLV",
    "COUNTRYNAME": "엘살바도르"
  },
  {
    "CODE": "GNQ",
    "COUNTRYNAME": "적도 기니"
  },
  {
    "CODE": "ERI",
    "COUNTRYNAME": "에리트레아"
  },
  {
    "CODE": "EST",
    "COUNTRYNAME": "에스토니아"
  },
  {
    "CODE": "SWZ",
    "COUNTRYNAME": "에스와티니"
  },
  {
    "CODE": "ETH",
    "COUNTRYNAME": "에티오피아"
  },
  {
    "CODE": "FLK",
    "COUNTRYNAME": "포클랜드 제도(말비나스)[b]"
  },
  {
    "CODE": "FRO",
    "COUNTRYNAME": "페로 제도"
  },
  {
    "CODE": "FJI",
    "COUNTRYNAME": "피지"
  },
  {
    "CODE": "FIN",
    "COUNTRYNAME": "핀란드"
  },
  {
    "CODE": "FRA",
    "COUNTRYNAME": "프랑스"
  },
  {
    "CODE": "GUF",
    "COUNTRYNAME": "프랑스령 기아나"
  },
  {
    "CODE": "PYF",
    "COUNTRYNAME": "프랑스 령 폴리네시아의"
  },
  {
    "CODE": "ATF",
    "COUNTRYNAME": "프랑스 남부 지역"
  },
  {
    "CODE": "GAB",
    "COUNTRYNAME": "가봉"
  },
  {
    "CODE": "GMB",
    "COUNTRYNAME": "감비아"
  },
  {
    "CODE": "GEO",
    "COUNTRYNAME": "그루지야"
  },
  {
    "CODE": "DEU",
    "COUNTRYNAME": "독일"
  },
  {
    "CODE": "GHA",
    "COUNTRYNAME": "가나"
  },
  {
    "CODE": "GIB",
    "COUNTRYNAME": "지브롤터"
  },
  {
    "CODE": "GRC",
    "COUNTRYNAME": "그리스"
  },
  {
    "CODE": "GRL",
    "COUNTRYNAME": "그린란드"
  },
  {
    "CODE": "GRD",
    "COUNTRYNAME": "그레나다"
  },
  {
    "CODE": "GLP",
    "COUNTRYNAME": "과들루프"
  },
  {
    "CODE": "GUM",
    "COUNTRYNAME": "괌"
  },
  {
    "CODE": "GTM",
    "COUNTRYNAME": "과테말라"
  },
  {
    "CODE": "GGY",
    "COUNTRYNAME": "건지"
  },
  {
    "CODE": "GIN",
    "COUNTRYNAME": "기니"
  },
  {
    "CODE": "GNB",
    "COUNTRYNAME": "기니비사우"
  },
  {
    "CODE": "GUY",
    "COUNTRYNAME": "가이아나"
  },
  {
    "CODE": "HTI",
    "COUNTRYNAME": "아이티"
  },
  {
    "CODE": "HMD",
    "COUNTRYNAME": "허드 아일랜드와 맥도날드 아일랜드"
  },
  {
    "CODE": "VAT",
    "COUNTRYNAME": "교황청"
  },
  {
    "CODE": "HND",
    "COUNTRYNAME": "온두라스"
  },
  {
    "CODE": "HKG",
    "COUNTRYNAME": "홍콩"
  },
  {
    "CODE": "HUN",
    "COUNTRYNAME": "헝가리"
  },
  {
    "CODE": "ISL",
    "COUNTRYNAME": "아이슬란드"
  },
  {
    "CODE": "IND",
    "COUNTRYNAME": "인도"
  },
  {
    "CODE": "IDN",
    "COUNTRYNAME": "인도네시아"
  },
  {
    "CODE": "IRN",
    "COUNTRYNAME": "이란(이슬람 공화국)"
  },
  {
    "CODE": "IRQ",
    "COUNTRYNAME": "이라크"
  },
  {
    "CODE": "IRL",
    "COUNTRYNAME": "아일랜드"
  },
  {
    "CODE": "IMN",
    "COUNTRYNAME": "맨 섬"
  },
  {
    "CODE": "ISR",
    "COUNTRYNAME": "이스라엘"
  },
  {
    "CODE": "ITA",
    "COUNTRYNAME": "이탈리아"
  },
  {
    "CODE": "JAM",
    "COUNTRYNAME": "자메이카"
  },
  {
    "CODE": "JPN",
    "COUNTRYNAME": "일본"
  },
  {
    "CODE": "JEY",
    "COUNTRYNAME": "저지"
  },
  {
    "CODE": "JOR",
    "COUNTRYNAME": "요르단"
  },
  {
    "CODE": "KAZ",
    "COUNTRYNAME": "카자흐스탄"
  },
  {
    "CODE": "KEN",
    "COUNTRYNAME": "케냐"
  },
  {
    "CODE": "KIR",
    "COUNTRYNAME": "키리바시"
  },
  {
    "CODE": "PRK",
    "COUNTRYNAME": "조선(조선민주주의인민공화국)"
  },
  {
    "CODE": "KOR",
    "COUNTRYNAME": "대한민국"
  },
  {
    "CODE": "KWT",
    "COUNTRYNAME": "쿠웨이트"
  },
  {
    "CODE": "KGZ",
    "COUNTRYNAME": "키르기스스탄"
  },
  {
    "CODE": "LAO",
    "COUNTRYNAME": "라오스 인민 민주 공화국"
  },
  {
    "CODE": "LVA",
    "COUNTRYNAME": "라트비아"
  },
  {
    "CODE": "LBN",
    "COUNTRYNAME": "레바논"
  },
  {
    "CODE": "LSO",
    "COUNTRYNAME": "레소토"
  },
  {
    "CODE": "LBR",
    "COUNTRYNAME": "라이베리아"
  },
  {
    "CODE": "LBY",
    "COUNTRYNAME": "리비아"
  },
  {
    "CODE": "LIE",
    "COUNTRYNAME": "리히텐슈타인"
  },
  {
    "CODE": "LTU",
    "COUNTRYNAME": "리투아니아"
  },
  {
    "CODE": "LUX",
    "COUNTRYNAME": "룩셈부르크"
  },
  {
    "CODE": "MAC",
    "COUNTRYNAME": "마카오"
  },
  {
    "CODE": "MDG",
    "COUNTRYNAME": "마다가스카르"
  },
  {
    "CODE": "MWI",
    "COUNTRYNAME": "말라위"
  },
  {
    "CODE": "MYS",
    "COUNTRYNAME": "말레이시아"
  },
  {
    "CODE": "MDV",
    "COUNTRYNAME": "몰디브"
  },
  {
    "CODE": "MLI",
    "COUNTRYNAME": "말리"
  },
  {
    "CODE": "MLT",
    "COUNTRYNAME": "몰타"
  },
  {
    "CODE": "MHL",
    "COUNTRYNAME": "마셜 제도"
  },
  {
    "CODE": "MTQ",
    "COUNTRYNAME": "마르티니크"
  },
  {
    "CODE": "MRT",
    "COUNTRYNAME": "모리타니"
  },
  {
    "CODE": "MUS",
    "COUNTRYNAME": "모리셔스"
  },
  {
    "CODE": "MYT",
    "COUNTRYNAME": "마요트"
  },
  {
    "CODE": "MEX",
    "COUNTRYNAME": "멕시코"
  },
  {
    "CODE": "FSM",
    "COUNTRYNAME": "미크로네시아(연방)"
  },
  {
    "CODE": "MDA",
    "COUNTRYNAME": "몰도바, 공화국"
  },
  {
    "CODE": "MCO",
    "COUNTRYNAME": "모나코"
  },
  {
    "CODE": "MNG",
    "COUNTRYNAME": "몽골리아"
  },
  {
    "CODE": "MNE",
    "COUNTRYNAME": "몬테네그로"
  },
  {
    "CODE": "MSR",
    "COUNTRYNAME": "몬세라트"
  },
  {
    "CODE": "MAR",
    "COUNTRYNAME": "모로코"
  },
  {
    "CODE": "MOZ",
    "COUNTRYNAME": "모잠비크"
  },
  {
    "CODE": "MMR",
    "COUNTRYNAME": "미얀마"
  },
  {
    "CODE": "NAM",
    "COUNTRYNAME": "나미비아"
  },
  {
    "CODE": "NRU",
    "COUNTRYNAME": "나우루"
  },
  {
    "CODE": "NPL",
    "COUNTRYNAME": "네팔"
  },
  {
    "CODE": "NLD",
    "COUNTRYNAME": "네덜란드"
  },
  {
    "CODE": "NCL",
    "COUNTRYNAME": "뉴 칼레도니아"
  },
  {
    "CODE": "NZL",
    "COUNTRYNAME": "뉴질랜드"
  },
  {
    "CODE": "NIC",
    "COUNTRYNAME": "니카라과"
  },
  {
    "CODE": "NER",
    "COUNTRYNAME": "니제르"
  },
  {
    "CODE": "NGA",
    "COUNTRYNAME": "나이지리아"
  },
  {
    "CODE": "NIU",
    "COUNTRYNAME": "니우에"
  },
  {
    "CODE": "NFK",
    "COUNTRYNAME": "노퍽 섬"
  },
  {
    "CODE": "MKD",
    "COUNTRYNAME": "북 마케도니아"
  },
  {
    "CODE": "MNP",
    "COUNTRYNAME": "북마리아나 제도"
  },
  {
    "CODE": "NOR",
    "COUNTRYNAME": "노르웨이"
  },
  {
    "CODE": "OMN",
    "COUNTRYNAME": "오만"
  },
  {
    "CODE": "PAK",
    "COUNTRYNAME": "파키스탄"
  },
  {
    "CODE": "PLW",
    "COUNTRYNAME": "팔라우"
  },
  {
    "CODE": "PSE",
    "COUNTRYNAME": "팔레스타인, [b]"
  },
  {
    "CODE": "PAN",
    "COUNTRYNAME": "파나마"
  },
  {
    "CODE": "PNG",
    "COUNTRYNAME": "파푸아 뉴기니"
  },
  {
    "CODE": "PRY",
    "COUNTRYNAME": "파라과이"
  },
  {
    "CODE": "PER",
    "COUNTRYNAME": "페루"
  },
  {
    "CODE": "PHL",
    "COUNTRYNAME": "필리핀 제도"
  },
  {
    "CODE": "PCN",
    "COUNTRYNAME": "핏케언"
  },
  {
    "CODE": "POL",
    "COUNTRYNAME": "폴란드"
  },
  {
    "CODE": "PRT",
    "COUNTRYNAME": "포르투갈"
  },
  {
    "CODE": "PRI",
    "COUNTRYNAME": "푸에르토 리코"
  },
  {
    "CODE": "QAT",
    "COUNTRYNAME": "카타르"
  },
  {
    "CODE": "REU",
    "COUNTRYNAME": "재결합"
  },
  {
    "CODE": "ROU",
    "COUNTRYNAME": "루마니아"
  },
  {
    "CODE": "RUS",
    "COUNTRYNAME": "러시아 연방"
  },
  {
    "CODE": "RWA",
    "COUNTRYNAME": "르완다"
  },
  {
    "CODE": "BLM",
    "COUNTRYNAME": "생 바르텔레미"
  },
  {
    "CODE": "SHN",
    "COUNTRYNAME": "세인트 헬레나, 승천 및 Tristan da Cunha[d]"
  },
  {
    "CODE": "KNA",
    "COUNTRYNAME": "세인트키츠네비스"
  },
  {
    "CODE": "LCA",
    "COUNTRYNAME": "세인트루시아"
  },
  {
    "CODE": "MAF",
    "COUNTRYNAME": "Saint Martin(프랑스어 부분)"
  },
  {
    "CODE": "SPM",
    "COUNTRYNAME": "생 피에르와 미클롱"
  },
  {
    "CODE": "VCT",
    "COUNTRYNAME": "세인트 빈센트 그레나딘"
  },
  {
    "CODE": "WSM",
    "COUNTRYNAME": "사모아"
  },
  {
    "CODE": "SMR",
    "COUNTRYNAME": "산 마리노"
  },
  {
    "CODE": "STP",
    "COUNTRYNAME": "상투메프린시페"
  },
  {
    "CODE": "SAU",
    "COUNTRYNAME": "사우디 아라비아"
  },
  {
    "CODE": "SEN",
    "COUNTRYNAME": "세네갈"
  },
  {
    "CODE": "SRB",
    "COUNTRYNAME": "세르비아"
  },
  {
    "CODE": "SYC",
    "COUNTRYNAME": "세이셸"
  },
  {
    "CODE": "SLE",
    "COUNTRYNAME": "시에라리온"
  },
  {
    "CODE": "SGP",
    "COUNTRYNAME": "싱가포르"
  },
  {
    "CODE": "SXM",
    "COUNTRYNAME": "신트 마르텐(네덜란드 부분)"
  },
  {
    "CODE": "SVK",
    "COUNTRYNAME": "슬로바키아"
  },
  {
    "CODE": "SVN",
    "COUNTRYNAME": "슬로베니아"
  },
  {
    "CODE": "SLB",
    "COUNTRYNAME": "솔로몬 제도"
  },
  {
    "CODE": "SOM",
    "COUNTRYNAME": "소말리아"
  },
  {
    "CODE": "ZAF",
    "COUNTRYNAME": "남아프리카"
  },
  {
    "CODE": "SGS",
    "COUNTRYNAME": "사우스 조지아 및 사우스 샌드위치 제도"
  },
  {
    "CODE": "SSD",
    "COUNTRYNAME": "남 수단"
  },
  {
    "CODE": "ESP",
    "COUNTRYNAME": "스페인"
  },
  {
    "CODE": "LKA",
    "COUNTRYNAME": "스리랑카"
  },
  {
    "CODE": "SDN",
    "COUNTRYNAME": "수단"
  },
  {
    "CODE": "SUR",
    "COUNTRYNAME": "수리남"
  },
  {
    "CODE": "SJM",
    "COUNTRYNAME": "스발바르와 얀 마옌[e]"
  },
  {
    "CODE": "SWE",
    "COUNTRYNAME": "스웨덴"
  },
  {
    "CODE": "CHE",
    "COUNTRYNAME": "스위스"
  },
  {
    "CODE": "SYR",
    "COUNTRYNAME": "시리아 아랍 공화국"
  },
  {
    "CODE": "TWN",
    "COUNTRYNAME": "대만, 중국[b]"
  },
  {
    "CODE": "TJK",
    "COUNTRYNAME": "타지키스탄"
  },
  {
    "CODE": "TZA",
    "COUNTRYNAME": "탄자니아, 연합 공화국"
  },
  {
    "CODE": "THA",
    "COUNTRYNAME": "태국"
  },
  {
    "CODE": "TLS",
    "COUNTRYNAME": "동티모르"
  },
  {
    "CODE": "TGO",
    "COUNTRYNAME": "토고"
  },
  {
    "CODE": "TKL",
    "COUNTRYNAME": "토켈라우"
  },
  {
    "CODE": "TON",
    "COUNTRYNAME": "통가"
  },
  {
    "CODE": "TTO",
    "COUNTRYNAME": "트리니다드토바고"
  },
  {
    "CODE": "TUN",
    "COUNTRYNAME": "튀니지"
  },
  {
    "CODE": "TUR",
    "COUNTRYNAME": "칠면조"
  },
  {
    "CODE": "TKM",
    "COUNTRYNAME": "투르크메니스탄"
  },
  {
    "CODE": "TCA",
    "COUNTRYNAME": "터크스케이커스 제도"
  },
  {
    "CODE": "TUV",
    "COUNTRYNAME": "투발루"
  },
  {
    "CODE": "UGA",
    "COUNTRYNAME": "우간다"
  },
  {
    "CODE": "UKR",
    "COUNTRYNAME": "우크라이나"
  },
  {
    "CODE": "ARE",
    "COUNTRYNAME": "아랍 에미리트"
  },
  {
    "CODE": "GBR",
    "COUNTRYNAME": "그레이트브리튼 및 북아일랜드 연합왕국"
  },
  {
    "CODE": "USA",
    "COUNTRYNAME": "미국"
  },
  {
    "CODE": "UMI",
    "COUNTRYNAME": "미국 작은 외딴 섬"
  },
  {
    "CODE": "URY",
    "COUNTRYNAME": "우루과이"
  },
  {
    "CODE": "UZB",
    "COUNTRYNAME": "우즈베키스탄"
  },
  {
    "CODE": "VUT",
    "COUNTRYNAME": "바누아투"
  },
  {
    "CODE": "VEN",
    "COUNTRYNAME": "베네수엘라(볼리바리아 공화국)"
  },
  {
    "CODE": "VNM",
    "COUNTRYNAME": "베트남"
  },
  {
    "CODE": "VGB",
    "COUNTRYNAME": "버진 아일랜드(영국)"
  },
  {
    "CODE": "VIR",
    "COUNTRYNAME": "버진 아일랜드(미국)"
  },
  {
    "CODE": "WLF",
    "COUNTRYNAME": "월리스와 푸투나"
  },
  {
    "CODE": "ESH",
    "COUNTRYNAME": "서부 사하라"
  },
  {
    "CODE": "YEM",
    "COUNTRYNAME": "예멘"
  },
  {
    "CODE": "ZMB",
    "COUNTRYNAME": "잠비아"
  },
  {
    "CODE": "ZWE",
    "COUNTRYNAME": "짐바브웨"
  }
];


export const countries_LANGPAK_en = 
[
  {
    "CODE": "AFG",
    "COUNTRYNAME": "Afghanistan"
  },
  {
    "CODE": "ALA",
    "COUNTRYNAME": "ÅlandIslands"
  },
  {
    "CODE": "ALB",
    "COUNTRYNAME": "Albania"
  },
  {
    "CODE": "DZA",
    "COUNTRYNAME": "Algeria"
  },
  {
    "CODE": "ASM",
    "COUNTRYNAME": "AmericanSamoa"
  },
  {
    "CODE": "AND",
    "COUNTRYNAME": "Andorra"
  },
  {
    "CODE": "AGO",
    "COUNTRYNAME": "Angola"
  },
  {
    "CODE": "AIA",
    "COUNTRYNAME": "Anguilla"
  },
  {
    "CODE": "ATA",
    "COUNTRYNAME": "Antarctica"
  },
  {
    "CODE": "ATG",
    "COUNTRYNAME": "AntiguaandBarbuda"
  },
  {
    "CODE": "ARG",
    "COUNTRYNAME": "Argentina"
  },
  {
    "CODE": "ARM",
    "COUNTRYNAME": "Armenia"
  },
  {
    "CODE": "ABW",
    "COUNTRYNAME": "Aruba"
  },
  {
    "CODE": "AUS",
    "COUNTRYNAME": "Australia"
  },
  {
    "CODE": "AUT",
    "COUNTRYNAME": "Austria"
  },
  {
    "CODE": "AZE",
    "COUNTRYNAME": "Azerbaijan"
  },
  {
    "CODE": "BHS",
    "COUNTRYNAME": "Bahamas"
  },
  {
    "CODE": "BHR",
    "COUNTRYNAME": "Bahrain"
  },
  {
    "CODE": "BGD",
    "COUNTRYNAME": "Bangladesh"
  },
  {
    "CODE": "BRB",
    "COUNTRYNAME": "Barbados"
  },
  {
    "CODE": "BLR",
    "COUNTRYNAME": "Belarus"
  },
  {
    "CODE": "BEL",
    "COUNTRYNAME": "Belgium"
  },
  {
    "CODE": "BLZ",
    "COUNTRYNAME": "Belize"
  },
  {
    "CODE": "BEN",
    "COUNTRYNAME": "Benin"
  },
  {
    "CODE": "BMU",
    "COUNTRYNAME": "Bermuda"
  },
  {
    "CODE": "BTN",
    "COUNTRYNAME": "Bhutan"
  },
  {
    "CODE": "BOL",
    "COUNTRYNAME": "Bolivia(PlurinationalStateof)"
  },
  {
    "CODE": "BES",
    "COUNTRYNAME": "Bonaire,SintEustatiusandSaba[c]"
  },
  {
    "CODE": "BIH",
    "COUNTRYNAME": "BosniaandHerzegovina"
  },
  {
    "CODE": "BWA",
    "COUNTRYNAME": "Botswana"
  },
  {
    "CODE": "BVT",
    "COUNTRYNAME": "BouvetIsland"
  },
  {
    "CODE": "BRA",
    "COUNTRYNAME": "Brazil"
  },
  {
    "CODE": "IOT",
    "COUNTRYNAME": "BritishIndianOceanTerritory"
  },
  {
    "CODE": "BRN",
    "COUNTRYNAME": "BruneiDarussalam"
  },
  {
    "CODE": "BGR",
    "COUNTRYNAME": "Bulgaria"
  },
  {
    "CODE": "BFA",
    "COUNTRYNAME": "BurkinaFaso"
  },
  {
    "CODE": "BDI",
    "COUNTRYNAME": "Burundi"
  },
  {
    "CODE": "CPV",
    "COUNTRYNAME": "CaboVerde"
  },
  {
    "CODE": "KHM",
    "COUNTRYNAME": "Cambodia"
  },
  {
    "CODE": "CMR",
    "COUNTRYNAME": "Cameroon"
  },
  {
    "CODE": "CAN",
    "COUNTRYNAME": "Canada"
  },
  {
    "CODE": "CYM",
    "COUNTRYNAME": "CaymanIslands"
  },
  {
    "CODE": "CAF",
    "COUNTRYNAME": "CentralAfricanRepublic"
  },
  {
    "CODE": "TCD",
    "COUNTRYNAME": "Chad"
  },
  {
    "CODE": "CHL",
    "COUNTRYNAME": "Chile"
  },
  {
    "CODE": "CHN",
    "COUNTRYNAME": "China[b]"
  },
  {
    "CODE": "CXR",
    "COUNTRYNAME": "ChristmasIsland"
  },
  {
    "CODE": "CCK",
    "COUNTRYNAME": "Cocos(Keeling)Islands"
  },
  {
    "CODE": "COL",
    "COUNTRYNAME": "Colombia"
  },
  {
    "CODE": "COM",
    "COUNTRYNAME": "Comoros"
  },
  {
    "CODE": "COG",
    "COUNTRYNAME": "Congo"
  },
  {
    "CODE": "COD",
    "COUNTRYNAME": "Congo,DemocraticRepublicofthe"
  },
  {
    "CODE": "COK",
    "COUNTRYNAME": "CookIslands"
  },
  {
    "CODE": "CRI",
    "COUNTRYNAME": "CostaRica"
  },
  {
    "CODE": "CIV",
    "COUNTRYNAME": "Côted'Ivoire"
  },
  {
    "CODE": "HRV",
    "COUNTRYNAME": "Croatia"
  },
  {
    "CODE": "CUB",
    "COUNTRYNAME": "Cuba"
  },
  {
    "CODE": "CUW",
    "COUNTRYNAME": "Curaçao"
  },
  {
    "CODE": "CYP",
    "COUNTRYNAME": "Cyprus[b]"
  },
  {
    "CODE": "CZE",
    "COUNTRYNAME": "Czechia"
  },
  {
    "CODE": "DNK",
    "COUNTRYNAME": "Denmark"
  },
  {
    "CODE": "DJI",
    "COUNTRYNAME": "Djibouti"
  },
  {
    "CODE": "DMA",
    "COUNTRYNAME": "Dominica"
  },
  {
    "CODE": "DOM",
    "COUNTRYNAME": "DominicanRepublic"
  },
  {
    "CODE": "ECU",
    "COUNTRYNAME": "Ecuador"
  },
  {
    "CODE": "EGY",
    "COUNTRYNAME": "Egypt"
  },
  {
    "CODE": "SLV",
    "COUNTRYNAME": "ElSalvador"
  },
  {
    "CODE": "GNQ",
    "COUNTRYNAME": "EquatorialGuinea"
  },
  {
    "CODE": "ERI",
    "COUNTRYNAME": "Eritrea"
  },
  {
    "CODE": "EST",
    "COUNTRYNAME": "Estonia"
  },
  {
    "CODE": "SWZ",
    "COUNTRYNAME": "Eswatini"
  },
  {
    "CODE": "ETH",
    "COUNTRYNAME": "Ethiopia"
  },
  {
    "CODE": "FLK",
    "COUNTRYNAME": "FalklandIslands(Malvinas)[b]"
  },
  {
    "CODE": "FRO",
    "COUNTRYNAME": "FaroeIslands"
  },
  {
    "CODE": "FJI",
    "COUNTRYNAME": "Fiji"
  },
  {
    "CODE": "FIN",
    "COUNTRYNAME": "Finland"
  },
  {
    "CODE": "FRA",
    "COUNTRYNAME": "France"
  },
  {
    "CODE": "GUF",
    "COUNTRYNAME": "FrenchGuiana"
  },
  {
    "CODE": "PYF",
    "COUNTRYNAME": "FrenchPolynesia"
  },
  {
    "CODE": "ATF",
    "COUNTRYNAME": "FrenchSouthernTerritories"
  },
  {
    "CODE": "GAB",
    "COUNTRYNAME": "Gabon"
  },
  {
    "CODE": "GMB",
    "COUNTRYNAME": "Gambia"
  },
  {
    "CODE": "GEO",
    "COUNTRYNAME": "Georgia"
  },
  {
    "CODE": "DEU",
    "COUNTRYNAME": "Germany"
  },
  {
    "CODE": "GHA",
    "COUNTRYNAME": "Ghana"
  },
  {
    "CODE": "GIB",
    "COUNTRYNAME": "Gibraltar"
  },
  {
    "CODE": "GRC",
    "COUNTRYNAME": "Greece"
  },
  {
    "CODE": "GRL",
    "COUNTRYNAME": "Greenland"
  },
  {
    "CODE": "GRD",
    "COUNTRYNAME": "Grenada"
  },
  {
    "CODE": "GLP",
    "COUNTRYNAME": "Guadeloupe"
  },
  {
    "CODE": "GUM",
    "COUNTRYNAME": "Guam"
  },
  {
    "CODE": "GTM",
    "COUNTRYNAME": "Guatemala"
  },
  {
    "CODE": "GGY",
    "COUNTRYNAME": "Guernsey"
  },
  {
    "CODE": "GIN",
    "COUNTRYNAME": "Guinea"
  },
  {
    "CODE": "GNB",
    "COUNTRYNAME": "Guinea-Bissau"
  },
  {
    "CODE": "GUY",
    "COUNTRYNAME": "Guyana"
  },
  {
    "CODE": "HTI",
    "COUNTRYNAME": "Haiti"
  },
  {
    "CODE": "HMD",
    "COUNTRYNAME": "HeardIslandandMcDonaldIslands"
  },
  {
    "CODE": "VAT",
    "COUNTRYNAME": "HolySee"
  },
  {
    "CODE": "HND",
    "COUNTRYNAME": "Honduras"
  },
  {
    "CODE": "HKG",
    "COUNTRYNAME": "HongKong"
  },
  {
    "CODE": "HUN",
    "COUNTRYNAME": "Hungary"
  },
  {
    "CODE": "ISL",
    "COUNTRYNAME": "Iceland"
  },
  {
    "CODE": "IND",
    "COUNTRYNAME": "India"
  },
  {
    "CODE": "IDN",
    "COUNTRYNAME": "Indonesia"
  },
  {
    "CODE": "IRN",
    "COUNTRYNAME": "Iran(IslamicRepublicof)"
  },
  {
    "CODE": "IRQ",
    "COUNTRYNAME": "Iraq"
  },
  {
    "CODE": "IRL",
    "COUNTRYNAME": "Ireland"
  },
  {
    "CODE": "IMN",
    "COUNTRYNAME": "IsleofMan"
  },
  {
    "CODE": "ISR",
    "COUNTRYNAME": "Israel"
  },
  {
    "CODE": "ITA",
    "COUNTRYNAME": "Italy"
  },
  {
    "CODE": "JAM",
    "COUNTRYNAME": "Jamaica"
  },
  {
    "CODE": "JPN",
    "COUNTRYNAME": "Japan"
  },
  {
    "CODE": "JEY",
    "COUNTRYNAME": "Jersey"
  },
  {
    "CODE": "JOR",
    "COUNTRYNAME": "Jordan"
  },
  {
    "CODE": "KAZ",
    "COUNTRYNAME": "Kazakhstan"
  },
  {
    "CODE": "KEN",
    "COUNTRYNAME": "Kenya"
  },
  {
    "CODE": "KIR",
    "COUNTRYNAME": "Kiribati"
  },
  {
    "CODE": "PRK",
    "COUNTRYNAME": "Korea(DemocraticPeople'sRepublicof)"
  },
  {
    "CODE": "KOR",
    "COUNTRYNAME": "Korea,Republicof"
  },
  {
    "CODE": "KWT",
    "COUNTRYNAME": "Kuwait"
  },
  {
    "CODE": "KGZ",
    "COUNTRYNAME": "Kyrgyzstan"
  },
  {
    "CODE": "LAO",
    "COUNTRYNAME": "LaoPeople'sDemocraticRepublic"
  },
  {
    "CODE": "LVA",
    "COUNTRYNAME": "Latvia"
  },
  {
    "CODE": "LBN",
    "COUNTRYNAME": "Lebanon"
  },
  {
    "CODE": "LSO",
    "COUNTRYNAME": "Lesotho"
  },
  {
    "CODE": "LBR",
    "COUNTRYNAME": "Liberia"
  },
  {
    "CODE": "LBY",
    "COUNTRYNAME": "Libya"
  },
  {
    "CODE": "LIE",
    "COUNTRYNAME": "Liechtenstein"
  },
  {
    "CODE": "LTU",
    "COUNTRYNAME": "Lithuania"
  },
  {
    "CODE": "LUX",
    "COUNTRYNAME": "Luxembourg"
  },
  {
    "CODE": "MAC",
    "COUNTRYNAME": "Macao"
  },
  {
    "CODE": "MDG",
    "COUNTRYNAME": "Madagascar"
  },
  {
    "CODE": "MWI",
    "COUNTRYNAME": "Malawi"
  },
  {
    "CODE": "MYS",
    "COUNTRYNAME": "Malaysia"
  },
  {
    "CODE": "MDV",
    "COUNTRYNAME": "Maldives"
  },
  {
    "CODE": "MLI",
    "COUNTRYNAME": "Mali"
  },
  {
    "CODE": "MLT",
    "COUNTRYNAME": "Malta"
  },
  {
    "CODE": "MHL",
    "COUNTRYNAME": "MarshallIslands"
  },
  {
    "CODE": "MTQ",
    "COUNTRYNAME": "Martinique"
  },
  {
    "CODE": "MRT",
    "COUNTRYNAME": "Mauritania"
  },
  {
    "CODE": "MUS",
    "COUNTRYNAME": "Mauritius"
  },
  {
    "CODE": "MYT",
    "COUNTRYNAME": "Mayotte"
  },
  {
    "CODE": "MEX",
    "COUNTRYNAME": "Mexico"
  },
  {
    "CODE": "FSM",
    "COUNTRYNAME": "Micronesia(FederatedStatesof)"
  },
  {
    "CODE": "MDA",
    "COUNTRYNAME": "Moldova,Republicof"
  },
  {
    "CODE": "MCO",
    "COUNTRYNAME": "Monaco"
  },
  {
    "CODE": "MNG",
    "COUNTRYNAME": "Mongolia"
  },
  {
    "CODE": "MNE",
    "COUNTRYNAME": "Montenegro"
  },
  {
    "CODE": "MSR",
    "COUNTRYNAME": "Montserrat"
  },
  {
    "CODE": "MAR",
    "COUNTRYNAME": "Morocco"
  },
  {
    "CODE": "MOZ",
    "COUNTRYNAME": "Mozambique"
  },
  {
    "CODE": "MMR",
    "COUNTRYNAME": "Myanmar"
  },
  {
    "CODE": "NAM",
    "COUNTRYNAME": "Namibia"
  },
  {
    "CODE": "NRU",
    "COUNTRYNAME": "Nauru"
  },
  {
    "CODE": "NPL",
    "COUNTRYNAME": "Nepal"
  },
  {
    "CODE": "NLD",
    "COUNTRYNAME": "Netherlands"
  },
  {
    "CODE": "NCL",
    "COUNTRYNAME": "NewCaledonia"
  },
  {
    "CODE": "NZL",
    "COUNTRYNAME": "NewZealand"
  },
  {
    "CODE": "NIC",
    "COUNTRYNAME": "Nicaragua"
  },
  {
    "CODE": "NER",
    "COUNTRYNAME": "Niger"
  },
  {
    "CODE": "NGA",
    "COUNTRYNAME": "Nigeria"
  },
  {
    "CODE": "NIU",
    "COUNTRYNAME": "Niue"
  },
  {
    "CODE": "NFK",
    "COUNTRYNAME": "NorfolkIsland"
  },
  {
    "CODE": "MKD",
    "COUNTRYNAME": "NorthMacedonia"
  },
  {
    "CODE": "MNP",
    "COUNTRYNAME": "NorthernMarianaIslands"
  },
  {
    "CODE": "NOR",
    "COUNTRYNAME": "Norway"
  },
  {
    "CODE": "OMN",
    "COUNTRYNAME": "Oman"
  },
  {
    "CODE": "PAK",
    "COUNTRYNAME": "Pakistan"
  },
  {
    "CODE": "PLW",
    "COUNTRYNAME": "Palau"
  },
  {
    "CODE": "PSE",
    "COUNTRYNAME": "Palestine,Stateof[b]"
  },
  {
    "CODE": "PAN",
    "COUNTRYNAME": "Panama"
  },
  {
    "CODE": "PNG",
    "COUNTRYNAME": "PapuaNewGuinea"
  },
  {
    "CODE": "PRY",
    "COUNTRYNAME": "Paraguay"
  },
  {
    "CODE": "PER",
    "COUNTRYNAME": "Peru"
  },
  {
    "CODE": "PHL",
    "COUNTRYNAME": "Philippines"
  },
  {
    "CODE": "PCN",
    "COUNTRYNAME": "Pitcairn"
  },
  {
    "CODE": "POL",
    "COUNTRYNAME": "Poland"
  },
  {
    "CODE": "PRT",
    "COUNTRYNAME": "Portugal"
  },
  {
    "CODE": "PRI",
    "COUNTRYNAME": "PuertoRico"
  },
  {
    "CODE": "QAT",
    "COUNTRYNAME": "Qatar"
  },
  {
    "CODE": "REU",
    "COUNTRYNAME": "Réunion"
  },
  {
    "CODE": "ROU",
    "COUNTRYNAME": "Romania"
  },
  {
    "CODE": "RUS",
    "COUNTRYNAME": "RussianFederation"
  },
  {
    "CODE": "RWA",
    "COUNTRYNAME": "Rwanda"
  },
  {
    "CODE": "BLM",
    "COUNTRYNAME": "SaintBarthélemy"
  },
  {
    "CODE": "SHN",
    "COUNTRYNAME": "SaintHelena,AscensionandTristandaCunha[d]"
  },
  {
    "CODE": "KNA",
    "COUNTRYNAME": "SaintKittsandNevis"
  },
  {
    "CODE": "LCA",
    "COUNTRYNAME": "SaintLucia"
  },
  {
    "CODE": "MAF",
    "COUNTRYNAME": "SaintMartin(Frenchpart)"
  },
  {
    "CODE": "SPM",
    "COUNTRYNAME": "SaintPierreandMiquelon"
  },
  {
    "CODE": "VCT",
    "COUNTRYNAME": "SaintVincentandtheGrenadines"
  },
  {
    "CODE": "WSM",
    "COUNTRYNAME": "Samoa"
  },
  {
    "CODE": "SMR",
    "COUNTRYNAME": "SanMarino"
  },
  {
    "CODE": "STP",
    "COUNTRYNAME": "SaoTomeandPrincipe"
  },
  {
    "CODE": "SAU",
    "COUNTRYNAME": "SaudiArabia"
  },
  {
    "CODE": "SEN",
    "COUNTRYNAME": "Senegal"
  },
  {
    "CODE": "SRB",
    "COUNTRYNAME": "Serbia"
  },
  {
    "CODE": "SYC",
    "COUNTRYNAME": "Seychelles"
  },
  {
    "CODE": "SLE",
    "COUNTRYNAME": "SierraLeone"
  },
  {
    "CODE": "SGP",
    "COUNTRYNAME": "Singapore"
  },
  {
    "CODE": "SXM",
    "COUNTRYNAME": "SintMaarten(Dutchpart)"
  },
  {
    "CODE": "SVK",
    "COUNTRYNAME": "Slovakia"
  },
  {
    "CODE": "SVN",
    "COUNTRYNAME": "Slovenia"
  },
  {
    "CODE": "SLB",
    "COUNTRYNAME": "SolomonIslands"
  },
  {
    "CODE": "SOM",
    "COUNTRYNAME": "Somalia"
  },
  {
    "CODE": "ZAF",
    "COUNTRYNAME": "SouthAfrica"
  },
  {
    "CODE": "SGS",
    "COUNTRYNAME": "SouthGeorgiaandtheSouthSandwichIslands"
  },
  {
    "CODE": "SSD",
    "COUNTRYNAME": "SouthSudan"
  },
  {
    "CODE": "ESP",
    "COUNTRYNAME": "Spain"
  },
  {
    "CODE": "LKA",
    "COUNTRYNAME": "SriLanka"
  },
  {
    "CODE": "SDN",
    "COUNTRYNAME": "Sudan"
  },
  {
    "CODE": "SUR",
    "COUNTRYNAME": "Suriname"
  },
  {
    "CODE": "SJM",
    "COUNTRYNAME": "SvalbardandJanMayen[e]"
  },
  {
    "CODE": "SWE",
    "COUNTRYNAME": "Sweden"
  },
  {
    "CODE": "CHE",
    "COUNTRYNAME": "Switzerland"
  },
  {
    "CODE": "SYR",
    "COUNTRYNAME": "SyrianArabRepublic"
  },
  {
    "CODE": "TWN",
    "COUNTRYNAME": "Taiwan,ProvinceofChina[b]"
  },
  {
    "CODE": "TJK",
    "COUNTRYNAME": "Tajikistan"
  },
  {
    "CODE": "TZA",
    "COUNTRYNAME": "Tanzania,UnitedRepublicof"
  },
  {
    "CODE": "THA",
    "COUNTRYNAME": "Thailand"
  },
  {
    "CODE": "TLS",
    "COUNTRYNAME": "Timor-Leste"
  },
  {
    "CODE": "TGO",
    "COUNTRYNAME": "Togo"
  },
  {
    "CODE": "TKL",
    "COUNTRYNAME": "Tokelau"
  },
  {
    "CODE": "TON",
    "COUNTRYNAME": "Tonga"
  },
  {
    "CODE": "TTO",
    "COUNTRYNAME": "TrinidadandTobago"
  },
  {
    "CODE": "TUN",
    "COUNTRYNAME": "Tunisia"
  },
  {
    "CODE": "TUR",
    "COUNTRYNAME": "Turkey"
  },
  {
    "CODE": "TKM",
    "COUNTRYNAME": "Turkmenistan"
  },
  {
    "CODE": "TCA",
    "COUNTRYNAME": "TurksandCaicosIslands"
  },
  {
    "CODE": "TUV",
    "COUNTRYNAME": "Tuvalu"
  },
  {
    "CODE": "UGA",
    "COUNTRYNAME": "Uganda"
  },
  {
    "CODE": "UKR",
    "COUNTRYNAME": "Ukraine"
  },
  {
    "CODE": "ARE",
    "COUNTRYNAME": "UnitedArabEmirates"
  },
  {
    "CODE": "GBR",
    "COUNTRYNAME": "UnitedKingdomofGreatBritainandNorthernIreland"
  },
  {
    "CODE": "USA",
    "COUNTRYNAME": "UnitedStatesofAmerica"
  },
  {
    "CODE": "UMI",
    "COUNTRYNAME": "UnitedStatesMinorOutlyingIslands"
  },
  {
    "CODE": "URY",
    "COUNTRYNAME": "Uruguay"
  },
  {
    "CODE": "UZB",
    "COUNTRYNAME": "Uzbekistan"
  },
  {
    "CODE": "VUT",
    "COUNTRYNAME": "Vanuatu"
  },
  {
    "CODE": "VEN",
    "COUNTRYNAME": "Venezuela(BolivarianRepublicof)"
  },
  {
    "CODE": "VNM",
    "COUNTRYNAME": "VietNam"
  },
  {
    "CODE": "VGB",
    "COUNTRYNAME": "VirginIslands(British)"
  },
  {
    "CODE": "VIR",
    "COUNTRYNAME": "VirginIslands(U.S.)"
  },
  {
    "CODE": "WLF",
    "COUNTRYNAME": "WallisandFutuna"
  },
  {
    "CODE": "ESH",
    "COUNTRYNAME": "WesternSahara"
  },
  {
    "CODE": "YEM",
    "COUNTRYNAME": "Yemen"
  },
  {
    "CODE": "ZMB",
    "COUNTRYNAME": "Zambia"
  },
  {
    "CODE": "ZWE",
    "COUNTRYNAME": "Zimbabwe"
  }
]
