import {useMemo} from 'react';
import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import shadows from './shadows';
import { light, dark } from './palette';

const getTheme = (mode, themeToggler, locale) =>
    responsiveFontSizes(
      createTheme(
          {
          palette: mode === 'light' ? light : dark,
          shadows: shadows(mode),
          typography: {
            fontFamily: '"Inter", sans-serif',
            button: {
              textTransform: 'none',
              fontWeight: 'medium',
            },
          },
          zIndex: {
            appBar: 1200,
            drawer: 1300,
          },
          components: {
            MuiButton: {
              styleOverrides: {
                root: {
                  fontWeight: 400,
                  borderRadius: 5,
                  paddingTop: 10,
                  paddingBottom: 10,
                },
                containedSecondary: mode === 'light' ? { color: 'white' } : {},
              },
            },
            MuiInputBase: {
              styleOverrides: {
                root: {
                  borderRadius: 5,
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  borderRadius: 5,
                },
                input: {
                  borderRadius: 5,
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  borderRadius: 8,
                },
              },
            },
          },
          themeToggler,
        }, locales[locale],
      ),
    );

export default getTheme;
