import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReactMarkdown from 'react-markdown';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Result from '../Result';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const Content = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const data = props.data;

  const onClickFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://v2-master.amongarts.com${window.location.pathname}`
    );
  };

  return (
    data && (
      <Box>
        {/* 작가정보 */}
        <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
          <Typography variant={'subtitle1'}>
            <ReactMarkdown>{data.highlighted_description}</ReactMarkdown>
          </Typography>
          <Box
            width={1}
            height={1}
            marginY={4}
            display={'block'}
            textAlign={'center'}
          >
            {data.artworks.length > 0 &&
              data.artworks[0].hasOwnProperty('image') && (
                <LazyLoadImage
                  height={'100%'}
                  width={'100%'}
                  src={data.artworks[0].image.url}
                  alt='Remote working'
                  effect='blur'
                  style={{
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                    objectFit: 'contain',
                    borderRadius: 8,
                    width: '100%',
                    height: '100%',
                    maxHeight: 600,
                  }}
                />
              )}
            {data.artworks.length > 0 && (
              <Typography
                width={'100%'}
                variant={'caption'}
                color={'text.secondary'}
              >{`${data.artworks[0].title} ${data.artworks[0].size} ${data.artworks[0].material} ${data.artworks[0].year}`}</Typography>
            )}
          </Box>
          <Typography
            variant={'h4'}
            color={'primary'}
            align={'center'}
            fontWeight={'normal'}
          >
            {data.highlightedtext && (
              <Box>
                <FormatQuoteIcon />
                {data.highlightedtext}
                <FormatQuoteIcon />
              </Box>
            )}
          </Typography>
          <Box marginY={4}>
            <Typography variant={'h5'} gutterBottom>
              작가 프로필
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              개인전
            </Typography>
            <ReactMarkdown>{data.ex_career}</ReactMarkdown>
            <Typography variant={'h6'} gutterBottom>
              그룹전
            </Typography>
            <ReactMarkdown>{data.ex_group_career}</ReactMarkdown>
            <Typography variant={'h6'} gutterBottom>
              수상이력
            </Typography>
            <ReactMarkdown>{data.awards}</ReactMarkdown>
          </Box>
        </Box>

        {/* 작품 목록 */}
        <ImageList variant='masonry' cols={3} gap={8}>
          <Typography variant={'h5'} gutterBottom>
            작품 목록
          </Typography>
          {data.artworks.map(
            (item) =>
              item.hasOwnProperty('image') && (
                <ImageListItem key={item.id} cols={1}>
                  <img
                    src={`${item.image.url}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading='lazy'
                    style={{
                      objectFit: 'cover',
                      cursor: 'pointer',
                      borderRadius: 8,
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.7)'
                          : 'none',
                    }}
                  />
                  <ImageListItemBar
                    title={`${item.title}`}
                    subtitle={`${item.size} ${item.material} ${item.year}`}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                        component='a'
                        href={`/artwork-details/${item.id}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              )
          )}
        </ImageList>

        <Box paddingY={4}>
          <Divider />
        </Box>

        {/* Share 버튼 */}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
        >
          <Box display={'flex'} alignItems={'center'}></Box>
          <Box display={'flex'} alignItems={'center'}>
            <Typography color={'text.secondary'}>Share:</Typography>
            <Box marginLeft={0.5}>
              <IconButton
                aria-label='Facebook'
                onClick={onClickFacebook}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton aria-label='Instagram'>
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label='Twitter'>
                <TwitterIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Content;
