import React, { useState, useEffect } from 'react';
import { baseurl } from '../../utils/index';
import { Formik, useField } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import styled from 'styled-components';
import EventLoginComponent from './EventLoginComponent';
import EventSignUpComponent from './EventSignUpComponent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';

const EventEnrollUser = props => {
  const { isLogged, setIsLogged } = props;

  const [essentials, setEssentials] = useState({
    box1: false,
    box2: false,
    email: '',
  });
  const [buttonEnable, setButtonEnable] = useState(false);
  const [isUser, setIsUser] = useState(false); //유저인지 체크해서 맞다면 로그인, 아니라면 회원가입
  const [loginOrSignUp, setLoginOrSignUp] = useState(false); //이메일 입력을 완료했을때 로그인/회원가입 렌더링
  const [users, setUsers] = useState([]);

  const validateEmail = yup.object({
    email: yup.string().email('Email is invalid').required('Required'),
  });

  useEffect(() => {
    if (
      essentials.box1 &&
      essentials.box2 &&
      essentials.email.includes('@') &&
      essentials.email.includes('.') &&
      essentials.email.lastIndexOf('.') !== essentials.email.length - 1
    )
      setButtonEnable(true);
    else setButtonEnable(false);
  }, [essentials]);

  const checkUser = () => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === essentials.email) {
        setLoginOrSignUp(true);
        setIsUser(true);
        break;
      } else setLoginOrSignUp(true);
      setIsUser(false);
    }
  };

  useEffect(async () => {
    await axios.get(`${baseurl}/users`).then(data => setUsers(data.data));
  }, []);

  return (
    <>
      <ContentWrapper>
        <Title>
          {loginOrSignUp
            ? isUser
              ? 'Login'
              : 'Create New Account'
            : `Terms & Agreement`}
        </Title>
        <hr />
        <PolicyWrapper>
          <FormattedMessage id="EVENTENROLLUSER_POLICY1"></FormattedMessage>
        </PolicyWrapper>
        <PolicyWrapper>
          <FormattedMessage id="EVENTENROLLUSER_POLICY2"></FormattedMessage>
        </PolicyWrapper>
        <CheckBoxWrapper>
          <FormattedMessage id="EVENTENROLLUSER_CHECKBOX1"></FormattedMessage>
          <label for="cb1">
            <input
              type="checkbox"
              name="cb1"
              value="checked"
              onChange={() =>
                setEssentials({ ...essentials, box1: !essentials.box1 })
              }
            ></input>
          </label>
        </CheckBoxWrapper>
        <CheckBoxWrapper>
          <FormattedMessage id="EVENTENROLLUSER_CHECKBOX2"></FormattedMessage>
          <label for="cb2">
            <input
              type="checkbox"
              name="cb2"
              value="checked"
              onChange={() =>
                setEssentials({ ...essentials, box2: !essentials.box2 })
              }
            ></input>
          </label>
        </CheckBoxWrapper>
        {loginOrSignUp ? null : (
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={validateEmail}
            id="emailForm"
            onSubmit={checkUser}
          >
            <EmailWrapper>
              {essentials.box1 && essentials.box2 ? (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                      Enter your email
                    </Typography>
                    <TextField
                      label="Email *"
                      variant="outlined"
                      name={'email'}
                      fullWidth
                      onChange={e =>
                        setEssentials({ ...essentials, email: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}
            </EmailWrapper>
          </Formik>
        )}
        {buttonEnable && !loginOrSignUp ? (
          <ButtonWrapper>
            <Button
              size={'large'}
              variant={'contained'}
              type={'submit'}
              onClick={checkUser}
            >
              Check
            </Button>
          </ButtonWrapper>
        ) : null}
        <ComponentWrapper>
          {loginOrSignUp ? (
            isUser ? (
              <EventLoginComponent
                gotEmail={essentials.email}
                isLogged={isLogged}
                setIsLogged={setIsLogged}
              />
            ) : (
              <EventSignUpComponent
                gotEmail={essentials.email}
                isLogged={isLogged}
                setIsLogged={setIsLogged}
              />
            )
          ) : null}
        </ComponentWrapper>
      </ContentWrapper>
    </>
  );
};

export default EventEnrollUser;

const ContentWrapper = styled.section`
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  @media (max-width: 690px) {
    width: 100%;
  }
  // 아이패드 (모바일 버전)
  @media (min-width: 691px) and (max-width: 890px) {
    width: 70%;
  }
  // 모니터
  @media (min-width: 891px) {
    width: 70%;
  }
  .MuiTextField-root {
    width: 100%;
  }
`;
const Title = styled.p`
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`;

const PolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 20px;
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 498.867px;
  @media (max-width: 498.867px) {
    width: 100%;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CheckBoxWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-direction: row;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px;
`;
