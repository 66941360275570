import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from "react-intl";

const SidebarArticles = (props) => {
  const theme = useTheme();
  const data = props.data;

  return (
    data && (
      <Box component={Card} variant={"outlined"} padding={2}>
        <Typography
          variant="h6"
          data-aos={"fade-up"}
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          <FormattedMessage id="EXHIBITIONARTICLECONTENT_OTHEREXHIBITION_PHRASE" />
        </Typography>
        <Grid container spacing={2}>
          {data.exhibitions && data.exhibitions.map((item, i) => (
            <Grid key={i} item xs={12}>
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={0}
                borderRadius={0}
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                sx={{ backgroundImage: "none", bgcolor: "transparent" }}
              >
                <Box
                  sx={{
                    width: { xs: 1, md: "50%" },
                    "& .lazy-load-image-loaded": {
                      height: 1,
                      display: "flex !important",
                    },
                  }}
                >
                  {item.mainimage && item.mainimage[0] && (
                    <LazyLoadImage
                      component="img"
                      height={1}
                      width={1}
                      src={item.mainimage[0].formats.thumbnail.url}
                      alt="..."
                      effect="blur"
                      sx={{
                        objectFit: "cover",
                        maxHeight: 120,
                        borderRadius: 2,
                        filter:
                          theme.palette.mode === "dark"
                            ? "brightness(0.7)"
                            : "none",
                      }}
                    />
                  )}
                </Box>
                <CardContent
                  sx={{ padding: 1, "&:last-child": { paddingBottom: 1 } }}
                >
                  <Typography fontWeight={700}>{item.title}</Typography>
                  <Box marginY={1 / 4}>
                    <Typography
                      variant={"caption"}
                      color={"text.secondary"}
                      component={"i"}
                    >
                      {item.period}
                    </Typography>
                  </Box>
                  <Button
                    component={"a"}
                    href={`/exhibition-article/${item.id}`}
                    color="primary"
                    sx={{ textDecoration: "none" }}
                    size="small"
                  >
                    <FormattedMessage id="READ_MORE" defaultMessage="Read More" />
                  </Button>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default SidebarArticles;