/** @format */

import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { baseurl } from "../../../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { loadData } from "../../../../components/hooks/useFetch";

const SimilarStories = () => {
  const theme = useTheme();
  const intl = useIntl();
  const [data, setData] = useState(null);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [url, setURL] = useState(
    `${baseurl}/exhibitions?is_exposed=true&_start=${start}&_limit=${limit}&_sort=createdAt:DESC`
  );
  useEffect(() => {
    setTimeout(async () => {
      const reqdata = await loadData(url);
      // console.log("useEffect > reqdata >>>", reqdata);
      setData(reqdata);
      setCount(reqdata ? reqdata.length : 0);
    }, 1000);
  }, []);
  // console.log("Exhibition Data >>>>", data);
  return (
    data && (
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          marginBottom={4}
        >
          <Box>
            <Typography component="h6" variant="h6">
              <FormattedMessage id="EXHIBITIONCONTENT_SIMILAR_PHRASE" />
            </Typography>
            <Typography color={"text.secondary"}>
              <FormattedMessage id="EXHIBITIONCONTENT_SIMILAR_SENTENCE" />
            </Typography>
          </Box>
          <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
            <Box
              component={Button}
              variant="outlined"
              color="primary"
              size="large"
              marginLeft={2}
              href="/exhibition-search"
            >
              <FormattedMessage id="COMMON_VIEWALL_PHRASE" />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={4}>
          {data.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                component={"a"}
                href={`/exhibition-article/${item.id}`}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  boxShadow={4}
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{ backgroundImage: "none" }}
                >
                  <CardMedia
                    image={
                      item.mainimage[0] && item.mainimage[0].formats
                        ? item.mainimage[0].url
                        : ""
                    }
                    title={item.title}
                    sx={{
                      height: { xs: 300, md: 360 },
                      position: "relative",
                    }}
                  >
                    <Box
                      component={"svg"}
                      viewBox="0 0 2880 480"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        color: theme.palette.background.paper,
                        transform: "scale(2)",
                        height: "auto",
                        width: 1,
                        transformOrigin: "top center",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                        fill="currentColor"
                      />
                    </Box>
                  </CardMedia>
                  <Box component={CardContent} position={"relative"}>
                    <Typography variant={"h6"} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {`${
                        item.galleries[0] ? item.galleries[0].name : "No Name"
                      }`}
                    </Typography>
                    <Typography color="text.secondary">
                      {`${item.period}`}
                    </Typography>
                    <Typography color="text.secondary">{item.year}</Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box padding={2} display={"flex"} flexDirection={"column"}>
                    <Box marginBottom={2}>
                      <Divider />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Avatar
                          src={
                            item.artists[0] && item.artists[0].profileimg
                              ? item.artists[0].profileimg.url
                              : ""
                          }
                          sx={{ marginRight: 1 }}
                        />
                        <Link
                          href="/exhibition-article"
                          id={item.id}
                          color={"text.secondary"}
                          sx={{ textDecoration: "none" }}
                        >
                          {item.artists[0]
                            ? `${item.artists[0].localname}(${item.artists[0].englishname})`
                            : intl.formatMessage({
                                id: "CONTENTPAGE_ARTISTNOTREGISTERED_SENTENCE",
                              })}
                        </Link>
                      </Box>
                      <Typography color={"text.secondary"}>
                        {item.date}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default SimilarStories;
