import { saveUser, deleteUser } from "../../store/actions/UserActions";
// import { removeCookie } from "../../utils/cookies";
import { getDecryptedCookie, setEncryptedCookie, removeCookie } from "../../utils/cookieUtils";

/**
 * if you have an instance of Strapi running on your local
 * machine:
 *
 * 1. Run `adb reverse tcp:8163 tcp:8163` (only on android)
 *
 * 2. You have to change the access IP from localhost
 * to the IP of the machine Strapi is running on.
 */
const url = process.env.REACT_APP_baseurl;

/**
 * @param {UserModel} user
 */
export const login = async (user) => {
  const requestConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      identifier: user.identifier,
      password: user.password,
    }),
  };

  try {
    //console.log('login controller', `${url}/auth/local`);
    const response = await fetch(`${url}/auth/local`, requestConfig);
    const json = await response.json();
//console.log('login controller json', json);
    if (json.hasOwnProperty("statusCode") && json.statusCode !== 200) {
      //console.log(json.message[0].messages[0].message);
      alert(json.message[0].messages[0].message);
      return;
    } else if (json.hasOwnProperty("message")) {
      //console.log(json.message[0].messages[0].message);
      alert(json.message[0].messages[0].message);
      return;
    }
    //console.log('login controller json', json);

    if (json.jwt) {
      // //console.log('login controller json.user._id', json.user._id);

      if (json.hasOwnProperty("jwt")) {
        try {
          await setEncryptedCookie("@usertoken", json.jwt);
        } catch (e) {
          return e;
        }
      }

      if (json.hasOwnProperty("user")) {
        try {
          await localStorage.setItem("@user", json.user._id);
          await localStorage.setItem("@username", json.user.username);
          await localStorage.setItem("@role", json.user.role.name);
          await localStorage.setItem("@roletype", json.user.role.type);
          await localStorage.setItem("@email", json.user.email);
        } catch (e) {
          return e;
        }
      }

      if (json.user.artist !== null) {
        //console.log('json.user.artist', json.user.artist);
        try {
          await localStorage.setItem("@artist", json.user.artist._id);
        } catch (e) {
          return e;
        }
      }

      if (
        json.user.hasOwnProperty("companies") &&
        json.user.companies.length > 0 &&
        json.user.companies[0].hasOwnProperty("_id")
      ) {
        try {
          await localStorage.setItem("@company", json.user.companies[0]._id);
        } catch (e) {
          return e;
        }
      }

      if (
        json.user.hasOwnProperty("contracts") &&
        json.user.contracts.length > 0 &&
        json.user.contracts[0].hasOwnProperty("_id")
      ) {
        //console.log('login user contracts');
        try {
          await localStorage.setItem(
            "@contracts",
            JSON.stringify(json.user.contracts)
          );
        } catch (e) {
          return e;
        }
      } else {
        try {
          await localStorage.removeItem("@contracts");
        } catch (e) {
          return e;
        }
      }

      try {
        const usertoken = await getDecryptedCookie("@usertoken");
        //console.log('this jwt', usertoken);
      } catch (e) {
        return e;
      }
      try {
        const user = await localStorage.getItem("@user");
        //console.log('this user', user);
      } catch (e) {
        return e;
      }
      try {
        const artist = await localStorage.getItem("@artist");
        //console.log('this artist', artist);
      } catch (e) {
        return e;
      }
      try {
        const company = await localStorage.getItem("@company");
        //console.log('this company', company);
      } catch (e) {
        return e;
      }
      try {
        const contracts = await localStorage.getItem("@contracts");
        //console.log('this contracts', contracts.length);
      } catch (e) {
        return e;
      }
      try {
        const contracts = await localStorage.getItem("@role");
        //console.log('this role', role);
      } catch (e) {
        return e;
      }
      try {
        const contracts = await localStorage.getItem("@roletype");
        //console.log('this roletype', roletype);
      } catch (e) {
        return e;
      }
      //console.log('End of setItem');
      //for checking user _id artist _id
      try {
        const user = await localStorage.getItem("@user");
        const artist = await localStorage.getItem("@artist");
        const company = await localStorage.getItem("@company");
        const contracts = await localStorage.getItem("@contracts");
        const role = await localStorage.getItem("@role");
        const roletype = await localStorage.getItem("@roletype");

        if (user !== null) {
          // value previously stored
          //console.log('user', user);
        }
        if (artist !== null) {
          // value previously stored
          //console.log('artist', artist);
        }
        if (company !== null) {
          // value previously stored
          //console.log('company', company);
        }
        if (contracts !== null) {
          // value previously stored
          //console.log('contracts', contractcookieUtils';h);
        }
        if (role !== null) {
          // value previously stored
          //console.log('role', role);
        }
        if (roletype !== null) {
          // value previously stored
          //console.log('roletype', roletype);
        }
      } catch (e) {
        // error reading value
        return e.message;
      }
      // } catch (e) {
      //     // saving error
      //     //console.log('error', e.message);
      //     return e.message;
      // }
    }
    //end if

    if (json.error) {
      return json;
    }

    json.user.hasOwnProperty("artist") &&
    json.user.artist !== undefined &&
    json.user.artist !== null
      ? saveUser(json.jwt, json.user, json.user.artist._id)
      : saveUser(json.jwt, json.user);

    return json;
  } catch (err) {
    alert(err);
    return err;
  }
};

/**
 * @param {UserModel} user
 */
export const logout = async (user) => {
  const result = deleteUser();

  //console.log('UserController#logout#result', result);
  //console.log('localStorage.getItem', localStorage.getItem('@usertoken'));

  try {
    removeCookie("token");
    removeCookie("@usertoken");
    localStorage.removeItem("@role");
    localStorage.removeItem("@company");
    localStorage.removeItem("@username");
    localStorage.removeItem("@contracts");
    localStorage.removeItem("@user");
    localStorage.removeItem("@email");
    localStorage.removeItem("@roletype");
    localStorage.removeItem("@artist");
    result && window.localStorage.clear();
  } catch (err) {
    //Alert.alert(err);
    return false;
  }
  return result;
};