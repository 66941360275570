import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { baseurl } from "../../../utils/index";
// import { Button, StyledEngineProvider } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { StyledEngineProvider } from '@mui/material/styles'; //'@mui/styles';
import { getCookie } from "../../../utils/cookies";

function SubmitButton({ artworkArr, artworks }) {
  let dataArr = [];

  const postArtworks = () => {
    if (artworkArr.length < 1) return alert("작품을 먼저 저장해주세요!");

    // api 데이터 형태로 가공
    for (let i in artworks) {
      dataArr.push(artworks[i].data);
      // console.log("dataArr:", dataArr);
    }

    // status 500 뜨는 오류 있음(strapi에 저장은 됨)
    dataArr.map((obj) => {
      axios({
        method: "post",
        url: `${baseurl}/art-works`,
        headers: {
          Authorization: `Bearer ${getCookie("@usertoken")}`,
          "Content-Type": "application/json",
        },
        data: obj,
      }).then((res) => {
        alert("이벤트 등록이 완료되었습니다.");
        // console.log(res);
      });
    });
  };
  return (
    <BtnWrapper>
      <StyledEngineProvider injectFirst>
        <Btn
          fullWidth
          variant="outlined"
          color="primary"
          onClick={postArtworks}
        >
          이벤트 등록하기 (submit)
        </Btn>
      </StyledEngineProvider>
    </BtnWrapper>
  );
}

const BtnWrapper = styled.div`
  margin-top: 100px;
  text-align: right;
  @media (min-width: 768px) {
    padding: 1.5rem 10rem;
  }
  @media (min-width: 1024px) {
    padding: 1.5rem 15rem;
  }
  @media (min-width: 1620px) {
    padding: 1.5rem 15rem;
  }
`;

const Btn = styled(Button)`
  margin-bottom: 3rem;
  padding: 1.5rem;
  border-radius: 35px;
  box-shadow: 2px 3px 3px lightgray;
`;

export default SubmitButton;
