/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserModel from "../app/models/UserModel";
import ArtistModel from "../app/models/ArtistModel";
import CompanyModel from "../app/models/CompanyModel";
import { setNavParamArtistid } from "../store/actions/NavigateAction";
import GetToken from "../utils/token";
import { getCookie } from "../utils/cookies";
import { setEncryptedCookie, getDecryptedCookie } from "../utils/cookieUtils"; // 변경된 위치와 이름으로 import
import {CheckSignIn} from "../utils/token"; 

const baseurl = process.env.REACT_APP_baseurl;
const authurl = `${baseurl}/auth/local`;

function validateInput(identifier, password) {
  let errors = false;

  if (!identifier || identifier.length === 0) {
    errors = true;
  }

  if (!password || password.length === 0) {
    errors = true;
  }
  return !errors;
}

export default async function CheckUser(identifier,password) {

  // const [isLogged, setIsLogged] = useState(!!getDecryptedCookie("@usertoken")); // 수정된 부분: getDecryptedCookie 사용
  // const [jwt, setToken] = useState(null);
  // const [usertoken, setUserToken] = useState(null);
  // const [user, setUser] = useState(undefined);
  // const [username, setUserName] = useState(undefined);
  // const [artist, setArtist] = useState(undefined);
  // const [company, setCompany] = useState(undefined);
  // const [contracts, setContracts] = useState(undefined);

  // var config = {
  //   method: "get",
  //   url: `${baseurl}/users/me`, // 수정된 부분: getDecryptedCookie 사용
  //   headers: {
  //     Authorization: `Bearer ${await getDecryptedCookie("@usertoken")}`, // 수정된 부분: getDecryptedCookie 사용
  //   },
  // };
//console.log("identifier / password: ", identifier.toLowerCase().trim(), password);
  const user = new UserModel(identifier.toLowerCase().trim(), password);
  const res = await user.login();
//console.log("res: " + JSON.stringify(res));

  const signInResult = !!res.jwt; //CheckSignIn();

  if (signInResult && getDecryptedCookie('@usertoken')) {
      // console.log("identifier / password: ", identifier, password);
      
//console.log("Result: " + signInResult.toString());
      // setIsLogged(true);
      return signInResult;


          // try {
          //   // const response = await axios(config);
          //   if (
          //     response.data.hasOwnProperty("companies") &&
          //     response.data.companies[0].hasOwnProperty("_id")
          //   ) {
          //     try {
          //       const val = await setEncryptedCookie(
          //         "@company",
          //         response.data.companies[0]._id
          //       ); // 수정된 부분: setEncryptedCookie 사용
          //     } catch (e) {
          //       return e;
          //     }
          //   }

          //   if (
          //     response.data.hasOwnProperty("contracts") &&
          //     response.data.contracts[0].hasOwnProperty("_id")
          //   ) {
          //     try {
          //       await setEncryptedCookie(
          //         "@contracts",
          //         JSON.stringify(response.data.contracts)
          //       ); // 수정된 부분: setEncryptedCookie 사용
          //     } catch (e) {
          //       return e;
          //     }
          //   }
          // } catch (e) {
          //   console.info(e.message);
          // }

          // try {
          //   const thistoken = await getDecryptedCookie("@usertoken"); // 수정된 부분: getDecryptedCookie 사용
          //   setToken(thistoken);
          //   setUserToken(thistoken);
          // } catch (e) {
          //   return e;
          // }
          // try {
          //   const thisuser = await getDecryptedCookie("@user"); // 수정된 부분: getDecryptedCookie 사용
          //   setUser(thisuser);
          // } catch (e) {
          //   return e;
          // }
          // try {
          //   const thisartist = await getDecryptedCookie("@artist"); // 수정된 부분: getDecryptedCookie 사용
          //   setArtist(thisartist);
          //   setNavParamArtistid(thisartist);
          // } catch (e) {
          //   return e;
          // }
          // try {
          //   const thiscompany = await getDecryptedCookie("@company"); // 수정된 부분: getDecryptedCookie 사용

          //   setCompany(thiscompany);
          // } catch (e) {
          //   return e;
          // }
          // try {
          //   const contractslist = await getDecryptedCookie("@contracts"); // 수정된 부분: getDecryptedCookie 사용
          //   setContracts(contractslist);
          // } catch (e) {
          //   return e;
          // }
          // return true;
      } else {
        // 로그인 실패 시 처리 (옵션)
        alert("Login failed. Please check your credentials.");
      }
}

export async function authenticateUser(identifier, password) {
  if (validateInput(identifier, password)) {
    // const user = new UserModel(identifier.toLowerCase().trim(), password);

    try {
//console.log("props.identifier, props.password", identifier, password);
      const authuser = CheckUser(identifier, password);
      // const res = await user.login();
      // console.log("res: " + JSON.stringify(res));

      // const signInResult = await CheckSignIn();
      // console.log("Result: " + signInResult.toString());

      // if (signInResult) {

      // }
      return authuser;
    } catch (err) {
      console.info(err.message);
      return false;
    }
  } else {
    alert("Please fill out all required fields");
  }
}


export async function unauthenticateUser() {
  const user = new UserModel(localStorage.getItem("@username"));
  user.logout();
}

export async function signupUser(values, actions) {
  GetToken().then(async () => {
    const response = await axios
      .post(`${baseurl}/auth/local/register`, {
        username: values.newidentifier.toLowerCase(),
        email: values.newemail.toLowerCase(),
        password: values.newpassword,
        confirmed: true,
      })
      .then(() => {
        // Handle success.
        return {
          result: true,
        };
      })
      .catch(function (error) {
        // Handle error.
        if (
          error.response.status > 200 &&
          error.response.status < 504
          //error.response.status === 400
        ) {
          alert(error.response.data.message[0].messages[0].message);
          actions.setSubmitting(false);
          return {
            result: false,
            message: error.response.data.message[0].messages[0].message,
          };
        } else {
          return {
            result: true,
          };
        }
      });
    if (response.result) {
      //Check User's Existence Due to the 504 Error
      const createuserresult = getCookie("@usertoken").then(async (result) => {
        try {
          const settings = {
            headers: {
              Authorization: `Bearer ${result}`,
            },
          };
          const res = await axios.get(
            `${baseurl}/users?email=${values.newemail.toLowerCase()}`,
            settings
          );
          console.info("User Existence Check Response", res.data[0]._id);
          if (res.data[0]._id !== null) {
            //when artist requested
            if (values.checkasartist) {
              //Create Artist
              var data = JSON.stringify({
                email: values.newemail.toLowerCase(),
                localname: "Update Your Name",
              });

              var config = {
                method: "post",
                url: `${baseurl}/artists`,
                headers: {
                  Authorization: `Bearer ${result}`,
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then((re) => {
                  const artistid = re.data._id;
                  if (artistid) {
                    const artistdata = JSON.stringify({
                      artist: artistid,
                    });
                    const artistconfig = {
                      method: "put",
                      url: `${baseurl}/users/${res.data[0]._id}`,

                      headers: {
                        Authorization: `Bearer ${result}`,
                        "Content-Type": "application/json",
                      },
                      data: artistdata,
                    };

                    axios(artistconfig)
                      .then(function (response) {
                        if (response.data._id) {
                          alert("User & Artist Created");
                        }
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                  }
                })
                .catch(function (error) {
                  console.info(error.message);
                });
              return {
                result: true,
                message: "User & Artist Created",
              };
              //Create Artist
            } else {
              return {
                result: true,
                message: "User Created",
              };
            }
          } else {
            alert("User Creation Failed");
            return {
              result: false,
              message: "User Creation Failed",
            };
          }
        } catch (error) {
          //Handle error.
          console.info(error.response);
          return {
            result: "false",
            message: error.response,
          };
        }
      });
      console.info("crerateuserresult", (await createuserresult).message);
      actions.setSubmitting(false);
    }
  });
}

export async function loadArtist(identifier, usertoken) {
  try {
    const artist = new ArtistModel({
      identifier: identifier.toLowerCase().trim(),
      usertoken: usertoken,
    });
    const res = await artist.load();
    return res;
  } catch (err) {
    return err.message;
  }
}

export async function editArtist(artist, usertoken) {
  try {
    const artistInfo = new ArtistModel({
      ...artist,
      identifier: artist._id.toLowerCase().trim(),
    });
    const res = await artistInfo.edit();
    return res;
  } catch (err) {}
}

export async function loadCompany(identifier, usertoken) {
  try {
    const company = new CompanyModel({
      identifier: identifier.toLowerCase().trim(),
      usertoken: usertoken,
    });
    return await company.load();
  } catch (err) {
    return err.message;
  }
}