/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import * as locales from "@mui/material/locale";
import LangPaks from "./i18n/LangPaks";
import koLang from "./locale/ko.json";
import enLang from "./locale/en.json";
import viLang from "./locale/vi.json";
import deLang from "./locale/de.json";
import jpLang from "./locale/jp.json";
// import * as Three from 'three';

// localStorage.setItem("locale","ko-KR"); //for debugging
const locale = localStorage.getItem("locale") ?? "ko-KR";
const message = {
  "en-US": enLang,
  "ko-KR": koLang,
  "de-DE": deLang,
  "ja-JP": jpLang,
  "vi-VN": viLang,
}[locale];
console.info("Now Selected Locale is ", locale);

// const Ammo = require("ammo.js/builds/ammo.wasm.js");
// const AmmoWasm = require("ammo.js/builds/ammo.wasm.wasm");
// window.Ammo = Ammo.bind(undefined, {
//   locateFile(path) {
//     if (path.endsWith(".wasm")) {
//       return AmmoWasm;
//     }
//     return path;
//   }
// });
// require("aframe-physics-system"); //note this require must happen after the above

ReactDOM.render(
  <IntlProvider locale={locale} messages={message}>
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();