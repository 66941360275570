import React, { useEffect } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Container from "../../../../components/Container";

const Hero = (props) => {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  }, []);

  const { data } = props;

  if (!data) {
    return <div>Loading...</div>;
  }

  // 안전한 접근을 위해 논리 연산자 사용
  const mainImageUrl = data.mainimage[0]?.formats?.thumbnail?.url || '';
  const artistProfileImage = data.artists?.[0]?.profileimg?.url || '';
  const artistName = data.artists?.[0]?.localname || 'Unknown Artist';

  return (
    <Box
      className={"jarallax"}
      data-jarallax
      data-speed="0.2"
      position={"relative"}
      minHeight={{ xs: 400, sm: 500, md: 600 }}
      display={"flex"}
      marginTop={-13}
      paddingTop={13}
      alignItems={"center"}
      id="agency__portfolio-item--js-scroll"
    >
      {mainImageUrl && (
        <Box
          className={"jarallax-img"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url(${mainImageUrl})`,
          }}
        />
      )}

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha("#161c2d", 0.6),
          zIndex: 1,
        }}
      />
      <Container position={"relative"} zIndex={2}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 400,
              color: "common.white",
              marginBottom: 2,
            }}
          >
            {data.title || 'Exhibition Title'}
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Avatar
              sx={{ width: 60, height: 60, marginRight: 2 }}
              src={artistProfileImage}
            />
            <ListItemText
              sx={{ margin: 0 }}
              primary={artistName}
              primaryTypographyProps={{
                variant: "h6",
                sx: { color: "common.white" },
              }}
              secondaryTypographyProps={{
                sx: { color: alpha("#ffffff", 0.8) },
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
