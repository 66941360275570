/**
 * /* eslint-disable react/no-unescaped-entities
 *
 * @format
 */

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import img3 from "../../../../assets/images/pages/img3.jpeg";

const CaseStudy3 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={theme.palette.mode === "light" ? colors.amber[50] : "#DEB22F"}
      data-aos={"fade-up"}
    >
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
              sx={{
                "& .lazy-load-image-background.lazy-load-image-loaded": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src="https://artkissf7658c62d55742edac15fb2c0601f993112016-master.s3.ap-northeast-2.amazonaws.com/img3_0dad36851c.jpeg"
                alt="..."
                effect="blur"
                borderRadius={2}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: "cover",
                }}
              />
              <Typography
                variant="caption"
                display="block"
                textAlign={"right"}
                color={
                  theme.palette.mode === "light"
                    ? "text.secondary"
                    : "text.primary"
                }
              >
                사랑, 완숙으로 물들다 Acrylic Paint 33.4x24.2 이미라
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  width: "40%",
                  height: "5%",
                }}
              />
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Exhibitions Near by
              </Typography>
              <Typography component={"p"}>
                <FormattedMessage id="MAINCASESTUDY3_SECTION1_SENTENCE" />
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={6}
            >
              <Box
                sx={{
                  width: "40%",
                  height: "30%",
                }}
              />

              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                href="/exhibition-search"
                sx={{
                  width: "70%",
                  height: 60,
                }}
              >
                <FormattedMessage id="COMMON_LEARNMORE_PHRASE" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            height={1}
          >
            <Box>
              <Typography
                variant={"h4"}
                color={"GoldenRod"}
                fontWeight={700}
                gutterBottom
              >
                Exhibitions & Galleries
              </Typography>
              <Typography
                color={
                  theme.palette.mode === "light"
                    ? "text.secondary"
                    : "text.primary"
                }
              >
                <FormattedMessage id="MAINCASESTUDY3_SUBTITLE_SENTENCE" />
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 0 }}
              boxShadow={0}
              borderRadius={2}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={"/assets/images/logo/logo.svg"}
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(0.7)"
                        : "none",
                  }}
                />
                <Typography component={"p"}>
                  <FormattedMessage id="MAINCASESTUDY3_SECTION2_SENTENCE" />
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={"subtitle1"} sx={{ fontWeight: 700 }}>
                    Soomyoung, Yi
                  </Typography>
                  <Typography color="text.secondary">CEO</Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy3;
