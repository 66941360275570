import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const Image = data => {
  const artworkData = data.data;

  return (
    <Box>
      {artworkData && (
        <Box
          sx={{
            marginBottom: 2,
            width: 1,
            height: 'auto',
            '& img': {
              width: 1,
              height: 1,
              objectFit: 'cover',
              borderRadius: 2,
            },
          }}
        >
          <img src={artworkData.image.url} alt={artworkData.title} />
        </Box>
      )}
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        flexWrap={'wrap'}
      ></Stack>
    </Box>
  );
};

export default Image;
