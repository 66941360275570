import React from "react";
import { useDropzone } from "react-dropzone";
import styled, { css } from "styled-components";
import { AiFillPicture } from "react-icons/ai";

function FileUploader({
  maxFiles,
  onDrop,
  fileUrl,
  artist,
  blankWidth,
  thumbLaptopWidth,
  thumbDesktopWidth,
  profileBlank,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles,
    accept: "image/*",
    onDrop,
  });

  return (
    <FileContainerSection>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {fileUrl ? (
          <Thumbnail
            src={fileUrl}
            artist={artist}
            thumbLaptopWidth={thumbLaptopWidth}
            thumbDesktopWidth={thumbDesktopWidth}
          />
        ) : (
          <BlankImage artist={artist} blankWidth={blankWidth}>
            <AiFillPicture />
            <UploadGuide artist={artist}>
              {profileBlank
                ? "Drag and Drop a file here, or click to select a file."
                : !artist
                ? "Drag and Drop a file here, or click to select a file."
                : "Select File"}
            </UploadGuide>
          </BlankImage>
        )}
      </div>
    </FileContainerSection>
  );
}

export default FileUploader;

const FileContainerSection = styled.section`
  width: 100%;
  margin-top: 15px;
`;
const BlankImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ artist }) =>
    artist &&
    css`
      width: 200px;
      height: 200px;
    `}
  padding: ${({ artist }) => !artist && "160px 110px"};
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid lightgray;
  text-align: center;
  font-size: 3rem;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;

const UploadGuide = styled.p`
  padding: ${({ artist }) => artist && "0px 5px"};
  font-size: 0.825rem;
  color: #444444;
  line-height: 1;
`;

const Thumbnail = styled.img`
  ${({ artist }) =>
    artist &&
    css`
      width: 200px;
      height: 200px;
    `}
  display: block;
  max-width: ${({ artist }) => !artist && "518px"};
  border-radius: 8px;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;
