/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import * as Auth from "../../components/AuthComponent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { getCookie } from "../../utils/cookies";

function EventLoginComponent(props) {
  const { gotEmail, isLogged, setIsLogged } = props;
  const [userInfo, setUserInfo] = useState({
    email: gotEmail,
    password: "",
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Email is required."),
    password: yup
      .string()
      .required("Please specify your password")
      .min(8, "The password should have at minimum length of 8"),
  });

  const initialValues = {
    email: gotEmail,
    password: "",
  };

  const onSubmit = (values) => {
   Auth.authenticateUser(values.email, values.password);
    setTimeout(function () {
      //Start the timer
      setIsLogged(!!getCookie("@usertoken"));
    }, 1000);
    // return values;
  };

  useEffect(() => {
    try {
      setIsLogged(!!getCookie("@usertoken"));
    } catch (error) {}
  }, [isLogged]);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <LoginComponentWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={0} width={"100%"} maxWidth={1}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"email"}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <ButtonWrapper>
                <Button size={"large"} variant={"contained"} type={"submit"}>
                  Sign in
                </Button>
              </ButtonWrapper>
            </Box>
          </Grid>
        </Grid>
      </form>
    </LoginComponentWrapper>
  );
}

export default EventLoginComponent;

const LoginComponentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .MuiGrid-root {
    width: 100%;
  }
  .MuiTextField-root {
    width: 100%;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
