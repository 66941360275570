/** @format */
import { combineReducers } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import {thunk} from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import UserReducer from "./reducers/UserReducer";
import artistReducer from "./reducers/ArtistReducer";

const rootPersistConfig = {
  key: "root",
  storage,
};

const artistPersistConfig = {
  key: "artist",
  storage,
};

const navParamPersistConfig = {
  key: "navparam",
  storage,
};

const rootReducer = combineReducers({
  root: persistReducer(rootPersistConfig, UserReducer),
  artist: persistReducer(artistPersistConfig, artistReducer),
});

// const devTools =
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
//const store = createStore(rootReducer, devTools);ㄴ

const createdStore = createStore(
  rootReducer,
  // composeWithDevTools(applyMiddleware(thunk))
);
const createdPersistor = persistStore(createdStore);

export const store = createdStore;
export const persistor = createdPersistor;
