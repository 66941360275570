import { store } from "../Store";

const ARTIST_SAVE = "ARTIST_SAVE";
const ARTIST_LOAD = "ARTIST_LOAD";
const ARTIST_DELETE = "ARTIST_DELETE";

export const saveArtist = (artist, jwt) => {
  store.dispatch({
    type: ARTIST_SAVE,
    payload: {
      artist,
      jwt,
    },
  });
};

export const loadArtist = (artist, jwt) => {
  store.dispatch({
    type: ARTIST_LOAD,
  });
};

export const deleteArtist = () => {
  store.dispatch({
    type: ARTIST_DELETE,
  });
};
