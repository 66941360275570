import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EventArtworkModal from "./EventArtworkModal";
import axios from "axios";
import { baseurl } from "../../../../utils/index";
import { getCookie } from "../../../../utils/cookies";

export default function EventArtwork({ artwork, removeForm, id }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const imageId = localStorage.getItem("@artworkImageId");

  useEffect(() => {
    imageId &&
      axios({
        method: "get",
        url: `${baseurl}/upload/files/${imageId}`,
        headers: {
          Authorization: `Bearer ${getCookie("@usertoken")}`,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setImageUrl(res.data.url);
      });
  }, []);

  const openArtWorkModal = () => {
    setIsOpenModal(true);
  };

  const closeArtWorkModal = () => {
    setIsOpenModal(false);
  };
  return (
    <>
      <Image
        alt={artwork.data.title}
        src={imageUrl}
        onClick={openArtWorkModal}
      />
      <EventArtworkModal
        closeArtWorkModal={closeArtWorkModal}
        isOpenModal={isOpenModal}
        artwork={artwork}
        removeForm={removeForm}
        id={id}
      />
    </>
  );
}

const Image = styled.img`
  display: block;
  width: 126px;
  height: 126px;
  margin: 0 auto 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 5px;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;
