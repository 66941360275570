/**
 * User model as defined in Strapi
 */
// import AsyncStorage from '@react-native-community/async-storage';
import { login, logout } from '../controllers/UserController';

class UserModel {
    constructor(identifier, password) {
        this.identifier = identifier;
        this.password = password;

        // //console.log('User id', identifier);
        // //console.log('User pw', password);
    }

    async login() {
        //console.log('login');
        const result = await login(this);
        //console.log('login result', result);
        if (!result) {
            throw new Error('Unable to login user.');
        }
        //console.log('login return result', result);
        return result;
    }

    async logout() {
        //console.log('this user', this);
        const result = await logout(this);
        //console.log('logout result', result);
        if (!!result) {
            throw new Error('Unable to logout user.');
        }

        return true;
    }
}

export default UserModel;